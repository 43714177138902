<template>
  <div class="bg-grayCust-110 p-6">
    <div class="mb-1.5 flex items-center justify-between text-xl font-bold text-black">
      <div class="flex items-center">
        <span>{{ title }}</span>
        <img
          :src="cdn(`images/UpgradeUserModal/${ iconName }`)"
          alt=""
          class="ml-1"
        >
      </div>
      <div>
        <a
          href="https://instawp.com/legal/refund-policy/"
          target="_blank"
          class="hover-text relative flex cursor-pointer items-center text-sm font-bold text-grayCust-620 underline underline-offset-4"
        >
          <span class="mr-1">{{ $t('refund_policy') }}</span>
          <LinkIcon class="size-4" />
        </a>
      </div>
    </div>
    <div class="text-sm font-normal text-grayCust-620">
      <slot name="subTitle" />
    </div>
  </div>
</template>

<script>

import LinkIcon from "@/components/UpdatedDesignVersion/ImageComponents/LinkIcon.vue";

export default {
  
  name:"Header",
  components:{
      LinkIcon
  },
  props:{
    title:{
      type: String,
      required: false,
    },
    subTitle:{
      type: String,
      required: false,
    },
    iconName:{
      type: String,
      required: false,
    }
  }
}
</script>