import * as SolidIcons from "@heroicons/vue/solid";
import * as OutlineIcons from "@heroicons/vue/outline";

export default {
  install(app) {
    // Solid Icons ne globally register karo
    Object.entries(SolidIcons).forEach(([name, component]) => {
      app.component(`Solid${name}`, component);
    });

    // Outline Icons ne globally register karo
    Object.entries(OutlineIcons).forEach(([name, component]) => {
      app.component(`Outline${name}`, component);
    });
  },
};
