<template>
  <div class="custom-scroll mt-3 flex max-h-[600px] min-h-[230px] items-start overflow-auto sm2:mt-6 sm2:items-center lg:max-h-[285px]">
    <div class="w-full">
      <div class="space-y-5">
        <div
          v-if="!!question.note"
          class="mt-[5px] text-center text-sm"
        >
          {{ question.note }}
        </div>
        <div class="flex w-full items-center justify-center">
          <div class="flex w-[600px] flex-wrap items-center justify-center gap-6">
            <Box
              v-for="option in question.options"
              :key="option.id"
              :title="option.title"
              :extra-class="selectedOptionIds.includes(option.id) ? 'bg-primary-275 border-primary-900 text-primary-900' : 'bg-white border-grayCust-180 text-grayCust-620'"
              @click="onHandleSelectBox(option.id)"
            />
          </div>
        </div>
      </div>
      <Reward class="!mt-5" />
    </div>
  </div>
</template>

<script>

import Box from '@/Common/SurveyModal/Inputs/Checkbox/Box.vue';
import Reward from '@/Common/SurveyModal/Reward.vue';
import { useAppStore } from '@/store/index';
import { useSurveyStore } from '@/store/survey';
import { mapStores, mapActions } from 'pinia';

export default {
    
    name: 'SurveyCheckboxInputType',
    components: {
        Box,
        Reward
    },
    props: {
        question: {
            type: Object,
            required: true,
        },
        response: {
            required: true,
        },
    },
    data() {
        return {
            isOthersOptionSelected: false,
            selectedOptionIds: this.response?.survey_question_option_ids ? this.response?.survey_question_option_ids : []
        };
    },
    mounted() {
        this.surveyStore.updateSelectedOptionIds(this.response?.survey_question_option_ids ? this.response?.survey_question_option_ids : []);
    },
    unmounted() {
        this.surveyStore.updateSelectedOptionIds([]);
    },
    computed: {
        ...mapStores(useAppStore, useSurveyStore),
    },
    methods:{
        ...mapActions(useSurveyStore, ["updateSelectedOptionIds"]),
        async onHandleSelectBox(id){
            if (this.selectedOptionIds.includes(id)) {
                this.selectedOptionIds = this.selectedOptionIds.filter((optionId) => optionId !== id)
            } else {
                this.selectedOptionIds = [...this.selectedOptionIds, id]
            }

            // Response will be saved from the SurveyModal compone when Continue is clicked
            this.updateSelectedOptionIds(this.selectedOptionIds);
        }
    }
}
</script>
