<template>
  <div
    v-if="question.variant === 'large'"
    class="business-type custom-scroll mt-3 flex max-h-[600px] min-h-[230px] items-start overflow-auto sm2:mt-6 lg:max-h-[285px]"
  >
    <div class="w-full">
      <div class="space-y-3">
        <div
          v-if="!!question.note"
          class="mt-[5px] text-center text-sm"
        >
          {{ question.note }}
        </div>
        <div class="flex flex-wrap items-center justify-center gap-4">
          <Card
            v-for="option in question.options"
            :key="option.id"
            :title="option.title"
            :extra-class="selectedOptionId == option.id ? 'bg-primary-275 border-primary-900 text-primary-900' : 'bg-white border-grayCust-770 text-grayCust-620'"
            :image-path="option.image_path"
            @click="onOptionSelect(option.id)"
          />
        </div>
      </div>
      <Reward />
    </div>
  </div>
  <div v-if="question.variant === 'small'">
    <div
      v-if="question.is_image"
      class="know-about custom-scroll mt-3 flex max-h-[600px] min-h-[230px] overflow-auto sm2:mt-6 lg:max-h-[285px]"
    >
      <div>
        <div :class="[!!question.note ? 'space-y-5' : '', 'w-full']">
          <div
            v-if="!!question.note"
            class="mt-[5px] text-center text-sm"
          >
            {{ question.note }}
          </div>
          <div class="flex items-center justify-center px-3 sm2:px-0">
            <div class="flex flex-wrap items-center justify-center gap-5">
              <Box
                v-for="option in question.options"
                :key="option.id"
                :title="option.title"
                :image-path="option.image_path"
                :is-checked="selectedOptionId === option.id"
                :extra-class="selectedOptionId === option.id ? 'bg-primary-275 border-primary-900 text-primary-900 py-1.5' : 'bg-white border-grayCust-180 text-grayCust-620 py-1.5'"
                :bg-extra-class="selectedOptionId === option.id ? 'background: linear-gradient(300deg, rgba(21, 184, 129, 0.00) 0.36%, rgba(21, 184, 129, 0.30) 51.93%, rgba(21, 184, 129, 0.00) 101.4%); height: 28px;' : 'background: linear-gradient(300deg, rgba(234, 236, 240, 0.00) 0.36%, #EAECF0 51.93%, rgba(234, 236, 240, 0.00) 101.4%); height: 28px;'"
                @click="onOptionSelect(option.id)"
              />
              <input
                v-if="isOthersOptionSelected"
                v-model="otherText"
                type="text"
                class="mb-2 w-full rounded-lg border-none bg-grayCust-280 px-3 py-2 text-sm placeholder:text-grayCust-470 focus:border-primary-900 focus:outline-none focus:ring-2 focus:ring-primary-900 sm2:w-auto lg:mb-0"
                placeholder="Please provide details"
                @input="onOtherTextInput"
              >
            </div>
          </div>
        </div>
        <Reward class="!mt-5" />
      </div>
    </div>
    <div
      v-else
      class="custom-scroll mt-3 flex max-h-[600px] min-h-[230px] items-start overflow-auto sm:items-center sm2:mt-6 lg:max-h-[285px]"
    >
      <div class="w-full">
        <div class="space-y-5">
          <div
            v-if="!!question.note"
            class="mt-[5px] text-center text-sm"
          >
            {{ question.note }}
          </div>
          <div class="flex flex-col justify-between">
            <div class="flex w-full items-center justify-center px-3 sm2:px-0">
              <div class="flex w-[600px] flex-wrap items-center justify-center gap-6">
                <Box
                  v-for="option in question.options"
                  :key="option.id"
                  :title="option.title"
                  :image-path="option.image_path"
                  :is-checked="selectedOptionId === option.id"
                  :extra-class="selectedOptionId === option.id ? 'bg-primary-275 border-primary-900 text-primary-900 py-2' : 'bg-white border-grayCust-180 text-grayCust-620 py-2'"
                  :bg-extra-class="selectedOptionId === option.id ? 'background: linear-gradient(300deg, rgba(21, 184, 129, 0.00) 0.36%, rgba(21, 184, 129, 0.30) 51.93%, rgba(21, 184, 129, 0.00) 101.4%); height: 17px;' : 'background: linear-gradient(300deg, rgba(234, 236, 240, 0.00) 0.36%, #EAECF0 51.93%, rgba(234, 236, 240, 0.00) 101.4%); height: 17px;'"
                  @click="onOptionSelect(option.id)"
                />
              </div>
            </div>
          </div>
        </div>
        <Reward />
      </div>
    </div>
  </div>
</template>

<script>

import Box from '@/Common/SurveyModal/Inputs/Radio/Box.vue';
import Card from '@/Common/SurveyModal/Inputs/Radio/Card.vue';
import Reward from '@/Common/SurveyModal/Reward.vue';
import { useAppStore } from '@/store/index';
import { useSurveyStore } from '@/store/survey';
import { mapActions, mapStores } from 'pinia';

export default {
    name: 'SurveyRadioInputType',
    
    components: {
        Box,
        Card,
        Reward
    },
    props: {
        question: {
            type: Object,
            required: true,
        },
        response: {
            required: true,
        },
    },
    data() {
        return {
            isOthersOptionSelected: !!this.response?.response_text,
            selectedOptionId: this.response?.survey_question_option_id || null,
            otherText: this.response?.response_text || ''
        };
    },
    computed: {
        ...mapStores(useAppStore, useSurveyStore),
        othersOption() {
            return this.question.options.find(option => option.title.match(/^(other|Other|others|Others|OTHER|OTHERS)$/));
        },
    },
    mounted() {
        this.surveyStore.updateResponseText(this.response?.response_text);
        this.surveyStore.updateSelectedOptionId(this.response?.survey_question_option_id);
    },
    unmounted() {
        // this.surveyStore.updateResponseText('');
        this.surveyStore.updateSelectedOptionId(null);
    },
    methods: {
        ...mapActions(useSurveyStore, ["updateResponseText", "updateSelectedOptionId"]),
        onOtherTextInput(event) {
            this.updateResponseText(event.target.value);
        },
        isOthersOption(selectedOptionId) {
            return selectedOptionId === this.othersOption?.id;
        },
        async onOptionSelect(id) {
            this.selectedOptionId = id;
            this.isOthersOptionSelected = this.isOthersOption(id);

            this.updateSelectedOptionId(id);

            if (this.isOthersOptionSelected) {
                // Response will be saved from the SurveyModal compone when Continue is clicked
                return;
            }

            this.updateResponseText('');
            this.otherText = '';

            try {
                this.surveyStore.setIsSubmitting(true);
                const requestResponse = await this.surveyStore.runSaveSurveyResponseApi(
                    this.surveyStore.userSurvey.id,
                    {
                        survey_question_id: this.question.id,
                        next_question_id: this.surveyStore.getNextQuestion?.id,
                        response_text: '',
                        question_type: this.question.question_type,
                        survey_question_option_id: id,
                    });

                const responseData = requestResponse.data
                this.surveyStore.updateSurveyResponses(responseData.data);
                this.$emit('nextStep');
                this.surveyStore.setIsSubmitting(false);
            } catch (error) {
                console.error("Error while saving survey response.", error);
            }
        }
    }
}
</script>
<style scoped>
    @media (max-width: 716px) {
        .know-about{
            align-items: start !important;
        }
    }

    @media (max-width: 783px) {
        .business-type {
            align-items: start !important;
        }
    }
</style>
