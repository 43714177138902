<template>
  <div>
    <svg
      :width="width"
      :height="height"
      viewBox="0 0 21 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.333 6V7C19.8853 7 20.333 6.55228 20.333 6H19.333ZM19.333 10H20.333C20.333 9.44771 19.8853 9 19.333 9V10ZM1.33301 10V9C0.780723 9 0.333008 9.44771 0.333008 10H1.33301ZM1.33301 6H0.333008C0.333008 6.55228 0.780723 7 1.33301 7L1.33301 6ZM14.333 1C14.333 0.447715 13.8853 0 13.333 0C12.7807 0 12.333 0.447715 12.333 1H14.333ZM12.333 3C12.333 3.55228 12.7807 4 13.333 4C13.8853 4 14.333 3.55228 14.333 3H12.333ZM14.333 7C14.333 6.44772 13.8853 6 13.333 6C12.7807 6 12.333 6.44772 12.333 7H14.333ZM12.333 9C12.333 9.55229 12.7807 10 13.333 10C13.8853 10 14.333 9.55229 14.333 9H12.333ZM14.333 13C14.333 12.4477 13.8853 12 13.333 12C12.7807 12 12.333 12.4477 12.333 13H14.333ZM12.333 15C12.333 15.5523 12.7807 16 13.333 16C13.8853 16 14.333 15.5523 14.333 15H12.333ZM3.33301 0C1.67615 0 0.333008 1.34315 0.333008 3H2.33301C2.33301 2.44772 2.78072 2 3.33301 2V0ZM17.333 0H3.33301V2H17.333V0ZM20.333 3C20.333 1.34315 18.9899 0 17.333 0V2C17.8853 2 18.333 2.44772 18.333 3H20.333ZM20.333 6V3H18.333V6H20.333ZM18.333 8C18.333 7.44771 18.7807 7 19.333 7V5C17.6762 5 16.333 6.34315 16.333 8H18.333ZM19.333 9C18.7807 9 18.333 8.55229 18.333 8H16.333C16.333 9.65685 17.6762 11 19.333 11V9ZM20.333 13V10H18.333V13H20.333ZM17.333 16C18.9899 16 20.333 14.6569 20.333 13H18.333C18.333 13.5523 17.8853 14 17.333 14V16ZM3.33301 16H17.333V14H3.33301V16ZM0.333008 13C0.333008 14.6569 1.67615 16 3.33301 16V14C2.78072 14 2.33301 13.5523 2.33301 13H0.333008ZM0.333008 10V13H2.33301V10H0.333008ZM2.33301 8C2.33301 8.55228 1.88529 9 1.33301 9V11C2.98986 11 4.33301 9.65685 4.33301 8H2.33301ZM1.33301 7C1.88529 7 2.33301 7.44772 2.33301 8H4.33301C4.33301 6.34315 2.98986 5 1.33301 5V7ZM0.333008 3V6H2.33301V3H0.333008ZM12.333 1V3H14.333V1H12.333ZM12.333 7V9H14.333V7H12.333ZM12.333 13V15H14.333V13H12.333Z"
        :fill="fill"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "MonthlyPlanIcon",
  props: {
    height: {
        default: 16
    },
    width: {
        default: 21
    },
    fill: {
        default: '#6B7280'
    }
  }
};
</script>