<template>
  <div class="w-full justify-center items-center">
    <modal-hosting-header
      :heading="selectedAccountDetails.name"
      btn-text="New Site"
      :has-plus="true"
      extra-class="disabled:opacity-40 bg-primary-900 hover:bg-primary-200 text-white text-base leading-6 font-medium py-2 pr-3.5 pl-2 rounded inline-flex items-center"
      @handle-btn-click="createNewSite"
      @btn-close-modal="closeModalPopup"
    />

    <div
      v-if="isLoading"
      class="flex items-center justify-center"
      style="height: 628px"
    >
      <theme-installing-icon style="width: 30px; height: 30px" />
      <p class="text-primary-900 text-base ml-2 font-medium">
        {{ $t('loading') }}
      </p>
    </div>

    <button
      class="
        disabled:opacity-40
        bg-primary-900
        hover:bg-primary-200
        text-white text-base
        leading-6
        font-medium
        py-2
        pr-3.5
        pl-2
        rounded
        inline-flex
        items-center
      "
      style="margin-left: 2rem; margin-top: 1rem"
      @click="handleRefreshBtnClick"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
          clip-rule="evenodd"
        />
      </svg>
      <span> {{ $t('refresh') }}</span>
    </button>

    <div
      v-if="!isLoading"
      class="mx-4 sm:mx-6 lg:mx-8 mb-8"
    >
      <div class="mt-8 flex flex-col">
        <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8"
          >
            <div
              class="
                hosting-site-url
                overflow-hidden
                ring-1 ring-black ring-opacity-5
                md:rounded-lg
              "
            >
              <table class="min-w-full divide-y divide-gray-300">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="
                        py-4
                        uppercase
                        pl-6
                        text-left text-xs
                        font-medium
                        text-grayCust-600
                        sm:pl-6
                      "
                    >
                      {{ $t('site_name') }}
                    </th>
                    <th
                      scope="col"
                      class="
                        px-3
                        py-4
                        uppercase
                        text-left text-xs
                        font-medium
                        text-grayCust-600
                      "
                      style="width: 234px"
                    >
                      {{ $t('last_push') }}
                    </th>
                    <th
                      scope="col"
                      class="
                        px-3
                        py-4
                        uppercase
                        text-left text-xs
                        font-medium
                        text-grayCust-600
                      "
                    >
                      {{ $t('synced_with') }}
                    </th>
                    <th
                      scope="col"
                      class="
                        px-3
                        py-4
                        uppercase
                        text-left text-xs
                        font-medium
                        text-grayCust-600
                      "
                    >
                      {{ $t('actions') }}
                    </th>
                  </tr>
                </thead>
                <tbody
                  v-if="sites.data.length > 0"
                  class="divide-y divide-gray-200 bg-white"
                >
                  <tr
                    v-for="item in sites.data"
                    :key="item.url"
                  >
                    <td
                      class="
                        whitespace-nowrap
                        py-6
                        pl-6
                        text-sm
                        font-medium
                        text-grayCust-800
                        sm:pl-6
                      "
                    >
                      {{ item.name }}
                    </td>
                    <td
                      class="
                        whitespace-nowrap
                        px-3
                        text-center
                        py-6
                        text-sm text-grayCust-800
                      "
                      style="width: 234px"
                    >
                      2 {{ $t('hour_ago') }}
                    </td>
                    <td
                      class="
                        whitespace-nowrap
                        px-3
                        py-6
                        text-sm text-grayCust-800
                      "
                    >
                      https://clientsite.instawp.xyz
                    </td>
                    <td
                      class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                    >
                      <div
                        class="actions flex items-center rounded-md bg-white"
                        style="width: 75px"
                      >
                        <div
                          class="border-r border-grayCust-900 py-2.5 px-3"
                          style="cursor: pointer"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="2"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                            />
                          </svg>

                          <!-- <hosting-actions-edit /> -->
                        </div>
                        <div
                          class="py-2.5 px-3"
                          style="cursor: pointer"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z"
                              clip-rule="evenodd"
                            />
                          </svg>
                          <!-- <hosting-actions-delete /> -->
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody
                  v-else
                  class="divide-y divide-gray-200 bg-white"
                >
                  <tr>
                    <td
                      colspan="4"
                      class="
                        text-center
                        whitespace-nowrap
                        py-6
                        pl-6
                        text-sm
                        font-medium
                        text-grayCust-800
                        sm:pl-6
                      "
                    >
                      {{ $t('no_data_found') }}!
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="sites.data.length > 0 && sites.last_page != 1"
      class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8"
    >
      <Pagination
        :current-page="sites.current_page"
        :last-page="sites.last_page"
        :total="sites.total"
        @refresh-table="updateData"
      />
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination.vue";
import ModalHostingHeader from "@/components/UpdatedDesignVersion/HostingManager/ModalHostingHeader.vue";
import HostingActionsDelete from "@/components/UpdatedDesignVersion/ImageComponents/HostingManager/HostingActionsDelete.vue";
import HostingActionsEdit from "@/components/UpdatedDesignVersion/ImageComponents/HostingManager/HostingActionsEdit.vue";
import {
    ArrowNarrowLeftIcon,
    ArrowNarrowRightIcon,
} from "@heroicons/vue/solid";
// import PaginationLeftArrow from '@/components/UpdatedDesignVersion/ProductManager/PaginationLeftArrow.vue'
import ThemeInstallingIcon from "@/Common/Icons/ThemeInstallingIcon.vue";

export default {
  name: "HostingManagerTable",
  components: {
    Pagination,
    ArrowNarrowLeftIcon,
    ArrowNarrowRightIcon,
    HostingActionsEdit,
    HostingActionsDelete,
    ModalHostingHeader,
    ThemeInstallingIcon,
    // PaginationLeftArrow
  },
  props: {
    isLoading: {
      type: Boolean,
    },
    sites: {
      type: Object,
    },
    selectedAccountDetails: {
      type: Object,
    },
  },
  data() {
    return {
      process: false,  
      hostingTableData: [
        {
          url: "https://bakuzo.us.instawp.xyz",
          lastPush: "5 hours ago",
          synced: "https://aaa.instawp.xyz",
        },
        {
          url: "https://bakuzo.us.instawp.xyz",
          lastPush: "5 hours ago",
          synced: "https://aaa.instawp.xyz",
        },
        {
          url: "https://bakuzo.us.instawp.xyz",
          lastPush: "5 hours ago",
          synced: "https://aaa.instawp.xyz",
        },
        {
          url: "https://bakuzo.us.instawp.xyz",
          lastPush: "5 hours ago",
          synced: "https://aaa.instawp.xyz",
        },
        {
          url: "https://bakuzo.us.instawp.xyz",
          lastPush: "5 hours ago",
          synced: "https://aaa.instawp.xyz",
        },
      ],
    };
  },
  methods: {
    getSites() {
      
    },
    updateData(page) {
      this.$emit("update_sites_data", this.selectedAccountDetails.id, page);
    },
    updateSiteData(data) {
      this.$emit("update_sites", data);
    },
    createNewSite() {
      this.$emit("newSiteForm");
    },
    closeModalPopup(page) {
      this.$emit("closeModal");
    },
    handleRefreshBtnClick() {
      let that = this;
      that.process = true;
      axios
        .get("/api/v1/sync-sites/" + this.selectedAccountDetails.id)
        .then((response) => {
            
          if (response.data.status) {
            that.updateSiteData(response.data.data.sites);
          } else {
            
          }
        })
        .catch((error) => {
          
        })
        .finally(() => {
          that.process = false;
        });
    },
  },
};
</script>

<style scoped>
.hosting-site-url {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
}
</style>
