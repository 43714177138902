<template>
  <div>
    <h2 class="mb-1.5 text-sm font-medium text-grayCust-1740">
      Would you like to tell us more?
    </h2>
    <Textarea
      placeholder="Enter a description..."
      rows="5"
      cols="30"
      @input="onInput"
    />
    <div
      v-if="showShareReviewToTP"
      class="mt-3 flex items-center gap-2"
    >
      <Checkbox
        id="review"
        v-model:checked="shareReviewToTP"
      />
      <label
        for="review"
        class="cursor-pointer text-sm font-medium text-grayCust-1740"
      >
        Share this review on TrustPilot
        <span>(hint: We have already copied the review in your clipboard)</span>
      </label>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue';

import Textarea from '@/components/New/Blocks/Textareas/Textarea.vue';
import Checkbox from '@/components/New/Blocks/Checkboxes/Checkbox.vue';
import { useSurveyStore } from '@/store/survey';
import { mapStores, mapActions } from 'pinia';

export default {
    name: 'FeedbackForm',
    
    components: {
        Textarea,
        Checkbox
    },
    data() {
        return {
            shareReviewToTP: true
        };
    },
    computed: {
        ...mapStores(useSurveyStore),
        showShareReviewToTP() {
            const rating = this.surveyStore.feedbackRating;
            return rating && rating > 3;
        }
    },
    watch: {
        shareReviewToTP(newVal) {
            this.surveyStore.updateShareReviewToTP(newVal);
        }
    },
    methods: {
        ...mapActions(useSurveyStore, ["updateResponseText"]),
        onInput(event) {
            this.updateResponseText(event.target.value);
        }
    }
};
</script>
