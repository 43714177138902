<template>
  <header class="flex justify-between items-center w-full bg-primary-900 py-2 px-4">
    <div class="h-[34px]">
      <InertiaLink :href="route('staging')">
        <img
          v-lazy="cdn('images/Header/HeaderLogo.svg')"
          alt="InstaWP Logo"
          class="h-full"
        >
      </InertiaLink>
    </div>
    <div class=" items-center gap-3 flex">
      <!-- <DropdownList
        button-text="New"
        :items="items"
        @select="handleSelection"
      /> -->
      <IconButton
        v-if="isTenantTypeInstaWP"
        btn-type="gray-outline-btn"
        icon-color="text-grayCust-70"
        icon-name="OutlineSpeakerphoneIcon"
        extra-class="frill-container border border-white/20 hover:!bg-grayCust-50 focus:shadow-none focus:bg-transparent !text-white hover:!text-black"
      />
      <!-- Modified menu button to emit toggle event -->
      <IconButton
        class="md:hidden flex"
        btn-type="gray-outline-btn"
        icon-color="text-grayCust-70"
        icon-name="OutlineMenuIcon"
        extra-class="border border-white/20 hover:!bg-grayCust-50 focus:shadow-none focus:bg-transparent !text-white hover:!text-black"
        @click="$emit('toggle-mobile-sidebar')"
      />
      <AgencyPlan
        :allow="teamAllow"
        :can="teamCan"
        :team-used="teamUsed"
      >
        <template #agencyPlan>
          <IconButton
            btn-type="gray-outline-btn"
            icon-color="text-grayCust-70"
            icon-name="OutlinePresentationChartBarIcon"
            extra-class="border border-white/20 hover:!bg-grayCust-50 focus:shadow-none focus:bg-transparent !text-white hover:!text-black"
          />
        </template>
      </AgencyPlan>

      <div class="h-[24px] w-[1px] bg-white/20" />

      <MyTeamList
        avatar-image="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
        :my-team="myTeam"
        :selected-option="currentSelectedOption"
      />

      <div class="h-[24px] w-[1px] bg-white/20" />

      <AccountDropdown :items="accountDropdownItems" />
    </div>
  </header>
</template>

<script>
import IconButton from '@/app/Common/CommonIconButton.vue';
import AccountDropdown from '@/app/Header/AccountDropdown.vue';
import AgencyPlan from '@/app/Header/AgenecyPlan.vue';
import DropdownList from "@/app/Header/DropdownList.vue";
import MyTeamList from "@/app/Header/MyTeamList.vue";
import { useAppStore } from '@/store/index';
import { mapState, mapStores } from 'pinia';

export default {
  name: "CommonHeader",
  components: {

    DropdownList,
    IconButton,
    MyTeamList,
    AgencyPlan,
    AccountDropdown
  },
  // Add emits definition
  emits: ['toggle-mobile-sidebar'],
  data() {

    return {
      isOpen: false,
      items: [
        {
          id: 1,
          name: 'Site',
          href: '#',
          iconName: 'OutlineCubeIcon',
        },
        {
          id: 2,
          name: 'Domain',
          href: '#',
          iconName: 'OutlineGlobeIcon',
        },
        {
          id: 3,
          name: 'Snepshot',
          href: '#',
          iconName: 'OutlineViewGridIcon',
        },
        {
          id: 4,
          name: 'Migrations',
          href: '#',
          iconName: 'OutlineCloudIcon',
        },
        {
          id: 5,
          name: 'Manage',
          href: '#',
          iconName: 'SiteMapIcon',
        },
        {
          id: 6,
          name: 'Templates',
          href: '#',
          iconName: 'TemplateIcon',
        },
      ],
      myTeamList: [
        {
          id: 1,
          name: 'Vikas’s Team 1',
          href: '#',
          image: 'images/UpgradeUserModal/profile.png',
        },
        {
          id: 2,
          name: 'Vikas’s Team 2',
          href: '#',
          image: 'images/UpgradeUserModal/profile.png',
        },
        {
          id: 3,
          name: 'Vikas’s Team 3',
          href: '#',
          image: 'images/UpgradeUserModal/profile.png',
        },

      ],
      myTeam: {

        heading: "My Teams",
        teams: [
          {
            id: 1,
            name: "Vikas’s Team 1",
            href: "#",
            image: "images/UpgradeUserModal/profile.png",
          },
          {
            id: 2,
            name: "Vikas’s Team 2",
            href: "#",
            image: "images/UpgradeUserModal/profile.png",
          },
          {
            id: 3,
            name: "Vikas’s Team 3",
            href: "#",
            image: "images/UpgradeUserModal/profile.png",
          },
        ],
        sharedWithMe: {
          heading: 'Shared with me',
          teams: [
            {
              id: 4,
              name: "Vikas’s Team 4",
              href: "#",
              image: "images/UpgradeUserModal/profile.png",
            },
            {
              id: 5,
              name: "Vikas’s Team 5",
              href: "#",
              image: "images/UpgradeUserModal/profile.png",
            },
            {
              id: 6,
              name: "Vikas’s Team 6",
              href: "#",
              image: "images/UpgradeUserModal/profile.png",
            },
          ],
        }
      },
      accountDropdownItems: [
        {
          id: 1,
          name: 'Account',
          href: route('profile.show'),
          iconName: 'OutlineUserIcon',
        },
        {
          id: 2,
          name: 'Subscription',
          href: route('subscriptions.index'),
          iconName: 'OutlineNewspaperIcon',
        },
        {
          id: 3,
          name: 'Apps',
          href: route('apps'),
          iconName: 'OutlineViewGridIcon',
        },
        {
          id: 5,
          name: 'Migrate Websites',
          href: route("migrate"),
          iconName: 'OutlineSwitchHorizontalIcon',
        },
        {
          id: 4,
          name: 'Logout',
          href: route('logout'),
          iconName: 'OutlineLogoutIcon',
        },
      ],
      //currentSelectedOption: null
    };
  },
  computed: {
    ...mapStores(useAppStore),
    ...mapState(useAppStore, [
      "user",
      "isTenantTypeInstaWP"
    ]),
    currentSelectedOption() {
      return this.user.current_team;
    }
  },
  // mounted() {
  //   this.currentSelectedOption = this.user.current_team;
  // },
  created() {
    if (typeof window.Frill === 'undefined') {
      window.Frill_Config = window.Frill_Config || [];
      window.Frill_Config.push({
        key: import.meta.env.VITE_FRILL_KEY,
        callbacks: {
          onReady: (widget) => {
            if (this.$page.props.auth.user) {
              widget.identify({
                email: this.$page.props.auth.user.email,
                name: this.$page.props.auth.user.name
              });
            }
          }
        }
      });

      const script = document.createElement('script');
      script.src = 'https://widget.frill.co/v2/widget.js';
      script.async = true;
      document.head.appendChild(script);
    }
  },
  // methods: {
  //   teamsSelection(option) {
  //     this.currentSelectedOption = option;
  //   },
  // },
}
</script>

<style></style>