<script setup>
import { onMounted, ref, watch } from 'vue';

const props = defineProps({
  imagePath: {
    type: String,
    default: '',
  },
  alt: {
    type: String,
    default: 'User avatar',
  },
  iconName: {
    type: String,
    default: '',
  },
  size: {
    type: String,
    default: 'avatar-sm',
    validator: (value) =>
      ['avatar-xs', 'avatar-sm', 'avatar-md', 'avatar-lg', 'avatar-xl', 'avatar-xxl'].includes(value),
  },
  name: {
    type: String,
    default: '',
  },
  bgColor: {
    type: String,
    default: '', // Indigo-600
  },
  badge: {
    type: Boolean,
    default: false,
  },
  badgeColor: {
    type: String,
    default: '', // Green-500
  },
  badgeImage: {
    type: String,
    default: '',
  },
  badgeImageAlt: {
    type: String,
    default: 'Badge',
  },
});

// const imageError = ref(false);
const circleSize = ref('');
const badgeSize = ref('');
const badgeImageSize = ref('');
const iconSize = ref('');

// const textSizeClasses = {
//   'avatar-xs': 'text-xs',
//   'avatar-sm': 'text-sm',
//   'avatar-md': 'text-base',
//   'avatar-lg': 'text-lg',
//   'avatar-xl': 'text-xl',
//   'avatar-xxl': 'text-2xl',
// };

const updateSizes = () => {
  switch (props.size) {
    case 'avatar-xs':
      circleSize.value = 'h-6 w-6';
      badgeSize.value = 'w-1.5 h-1.5';
      badgeImageSize.value = 'w-2.5 h-2.5';
      iconSize.value = 'w-3.5 h-3.5';
      break;
    case 'avatar-sm':
      circleSize.value = 'h-8 w-8';
      badgeSize.value = 'w-2 h-2';
      badgeImageSize.value = 'w-3 h-3';
      iconSize.value = 'w-[18px] h-[18px]';
      break;
    case 'avatar-md':
      circleSize.value = 'h-10 w-10';
      badgeSize.value = 'w-2.5 h-2.5';
      badgeImageSize.value = 'w-3.5 h-3.5';
      iconSize.value = 'w-6 h-6';
      break;
    case 'avatar-lg':
      circleSize.value = 'h-12 w-12';
      badgeSize.value = 'w-3 h-3';
      badgeImageSize.value = 'w-4 h-4';
      iconSize.value = 'w-7 h-7';
      break;
    case 'avatar-xl':
      circleSize.value = 'h-14 w-14';
      badgeSize.value = 'w-3.5 h-3.5';
      badgeImageSize.value = 'w-[18px] h-[18px]';
      iconSize.value = 'w-8 h-8';
      break;
    case 'avatar-xxl':
      circleSize.value = 'h-16 w-16';
      badgeSize.value = 'w-4 h-4';
      badgeImageSize.value = 'w-5 h-5';
      iconSize.value = 'w-8 h-8';
      break;
  }
};

// Watch for changes in the `size` prop
watch(() => props.size, updateSizes);

onMounted(updateSizes);
</script>
<template>
  <div class="relative inline-flex">
    <div
      :class="[
        'inline-flex items-center justify-center rounded-md overflow-hidden',
        circleSize,
      ]"
      :style="{ backgroundColor: bgColor }"
    >
      <img
        v-if="imagePath"
        v-lazy="cdn(imagePath)"
        :alt="alt"
        class="w-full h-full object-cover"
      >

      <component
        :is="iconName"
        v-else-if="iconName"
        class="w-full h-full object-cover"
      />
    </div>

    <span
      v-if="badge"
      :class="[
        'absolute bottom-0 right-0 block rounded-full ring-2 ring-white',
        badgeSize,
      ]"
      :style="{ backgroundColor: badgeColor }"
    />

    <img
      v-if="badgeImage"
      :src="badgeImage"
      :alt="badgeImageAlt"
      :class="[
        'absolute bottom-0 right-0 block rounded-full ring-2 ring-white',
        badgeImageSize,
      ]"
    >
  </div>
</template>