<template>
  <div
    class="
      hosting-connected
      w-full
      bg-white
      flex
      items-center
      justify-between
      px-8
    "
    style="height: 92px"
  >
    <p class="font-semibold text-2xl text-grayCust-800">
      {{ heading }}
    </p>
    <div class="flex items-center">
      <button
        :class="extraClass"
        @click="handleBtnClick"
      >
        <svg
          v-if="hasPlus"
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
          /></svg><span>{{ btnText }}</span>
      </button>
      <p
        class="
          text-sm
          close-icon
          font-extrabold
          text-grayCust-600
          hover:text-grayCust-700
          cursor-pointer
          ml-4
          hover:scale-125
        "
        @click="close()"
      >
        X
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["heading", "btnText", "hasPlus", "extraClass"],
  methods: {
    handleBtnClick() {
      this.$emit("handleBtnClick");
    },
    close() {
        this.$emit('btnCloseModal')
    },
  },
};
</script>


<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");
.hosting-connected {
  box-shadow: 5px 1px 3px rgba(0, 0, 0, 0.1), 5px 1px 2px rgba(0, 0, 0, 0.06);
}
.close-icon {
  font-family: "Varela Round", sans-serif;
}
</style>
