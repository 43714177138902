<template>
  <div class="relative h-screen overflow-hidden">
    <Notifications />
    <FreeToPaid v-if="teamOwnerActivePlan && teamOwnerActivePlan.name == 'Free'" />
    <CommonHeader
      class="relative z-[9]"
      @toggle-mobile-sidebar="toggleMobileSidebar"
    />
    <div
      class="flex overflow-hidden"
      :class="[showBanner ? 'h-[calc(100vh-94px)]' : 'h-[calc(100vh-50px)]']"
    >
      <!-- Mobile sidebar overlay -->
      <div
        v-if="isMobileSidebarOpen"
        class="fixed inset-0 bg-black bg-opacity-50 z-[4] md:hidden"
        @click="toggleMobileSidebar"
      />

      <!-- Sidebar with responsive behavior -->
      <CommonSidebar
        :menu-items="menuItems"
        :is-collapsed="isCollapsed"
        :class="[
          isMobileSidebarOpen ? 'translate-x-0' : '-translate-x-full',
          'md:translate-x-0 fixed md:relative z-[5] h-full transition-transform duration-300 ease-in-out'
        ]"
      />
      <div class="w-full bg-white flex-1 overflow-hidden">
        <div class="bg-white relative h-full ml-auto transition-all duration-300 ease-in-out">
          <div
            class="md:flex hidden fixed transition-all ease-in-out cursor-pointer z-[5]"
            :class="[isCollapsed ? 'left-[79px]' : 'left-[264px]', showBanner ? ' top-[113px]' : ' top-[69px]']"
            @click="toggleSidebar"
          >
            <img
              v-lazy="cdn('/images/collapse.svg')"
              alt="logo"
              class="w-5 h-5 transition-all duration-300 ease-in-out"
              :class="[isCollapsed ? 'rotate-180' : '']"
            >
          </div>
          <div class="h-[calc(100vh-50px)]">
            <slot />
          </div>
        </div>
      </div>
    </div>
    <GlobalModals />
  </div>
</template>

<script setup>
import Notifications from "@/Common/Notifications.vue";
import CommonSidebar from "@/app/Common/Sidebar/CommonSidebar.vue";
import GlobalModals from "@/app/Components/GlobalModals.vue";
import FreeToPaid from "@/Common/FreeToPaid.vue";
import CommonHeader from "@/app/Header/Header.vue";
import SiteMapIcon from "@/app/Icons/SiteMapIcon.vue";
import { useAppStore } from '@/store/index';
import { wTrans } from 'laravel-vue-i18n';
import { onMounted, reactive, ref } from 'vue';

let isCollapsed = ref(false);
let showBanner = ref(false);
// New state for mobile sidebar
let isMobileSidebarOpen = ref(false);
const menuItems = reactive([
    {
        title: wTrans('sites'),
        image: '/images/staging.svg',
        path: "staging",
        description: wTrans("menu_staging_description"),
        routename: [
          "staging",
          "sites.edit.staging.dashboard",
          "sites.edit.php.config",
          "sites.edit.install.content",
          "sites.edit.run.commands",
          "sites.edit.staging.sites",
          "sites.edit.map.domain",
          "sites.edit.git.deployment",
          "sites.edit.sftp.ssh",
          "sites.edit.performance",
          "sites.edit.page.in.sight",
          "sites.edit.active.integration",
          "sites.edit.security",
          "sites.edit.slow.plugin.detector",
          "sites.edit.remote.scanner",
          //hosting
          "hosting.manager",
          "hosting-sites.dashboard",
          "hosting-sites.map-domain",
          "hosting-sites.run-commands",
          "hosting-sites.sftp-ssh",
          "hosting-sites.backup",
          "hosting-sites.delete-site",
          "hosting-sites.setting",
          "hosting-sites.statistic",
          "hosting-sites.change-plan",
        ],
    },
    {
        title: wTrans('templates'),
        icon: 'OutlineViewGridIcon',
        path: 'templates',
        description: wTrans("menu_template_description"),
        routename: ["templates", "template.edit", "template.graph", "template.sites", "template.edit.setup", "template.edit.gallery", "template.edit.branding", "template.edit.email.collection", "template.edit.security", "template.edit.integrations", "template.edit.advance", "template.monetize", "template.sales", "template.store.page"],
    },
    {
        title: wTrans('manage'),
        image: '/images/manage.svg',
        path: 'connects',
        description: wTrans("menu_connects_description"),
        routename: [
          "connects",
          "connects.edit.dashboard",
          "connects.edit.health",
          "connects.edit.plugin",
          "connects.edit.theme",
          "connects.edit.user",
          "connects.edit.config.manager",
          "connects.edit.sites",
          "connects.edit.reports",
          "connects.edit.scanner",
          "connects.edit.performance",
          "connects.edit.disconnect",
          "connects.edit.uptime",
          "connects.edit.alert-rules",
          "connects.edit.view-logs",
          "connects.edit.bulk",
        ],
    },
    {
        title: wTrans('waas'),
        image: '/images/waas.svg',
        path: 'waas',
        description: wTrans("sell_templates_with_hosting"),
        routename: ["waas"],
    },
    {
        title: wTrans('settings'),
        icon: 'OutlineCogIcon',
        children: [
            {
                title: wTrans('configurations'),
                path: 'configurations',
                routename: ['configurations'],
            },
            {
                title: wTrans('deployments'),
                path: 'deployments',
                routename: ['deployments'],
            },
            {
                title: wTrans('commands'),
                path: 'commands',
                routename: ['commands'],
            }
        ],
    },
    {
      title: wTrans('integrations'),
      icon: 'OutlinePuzzleIcon',
      path: 'integrations',
      routename: ['integrations'],
    }
]);

function toggleSidebar() {
  isCollapsed.value = !isCollapsed.value;
}

// New function to toggle mobile sidebar
function toggleMobileSidebar() {
  isMobileSidebarOpen.value = !isMobileSidebarOpen.value;
}

function toggleBanner() {
  showBanner.value = !showBanner.value;
}

const appStore = useAppStore();

const teamOwnerActivePlan = appStore.teamOwnerActivePlan;

onMounted(async () => {
  await appStore.initialData();
  appStore.subscribeAppVersionReleaseEvent();

  // Close mobile sidebar when window is resized to desktop size
  window.addEventListener('resize', () => {
    if (window.innerWidth >= 768 && isMobileSidebarOpen.value) {
      isMobileSidebarOpen.value = false;
    }
  });
});


</script>
