<template>
  <div class="custom-scroll mt-6 flex max-h-[600px] min-h-[230px] flex-col justify-between gap-3 overflow-auto px-1 lg:max-h-[230px]">
    <div class="space-y-3">
      <div class="text-sm">
        {{ question.note }}
      </div>
      <div class="w-full">
        <div class="flex flex-wrap items-center justify-center gap-4">
          <input
            v-if="question.question_type === 'text'"
            v-model="responseText"
            type="text"
            class="mb-2 w-full rounded-lg border-none bg-grayCust-280 px-3 py-2 text-sm placeholder:text-grayCust-470 focus:border-primary-900 focus:outline-none focus:ring-2 focus:ring-primary-900 lg:mb-0"
            placeholder="Please provide details"
            @input="onInput"
          >
          <textarea
            v-if="question.question_type === 'textarea'"
            v-model="responseText"
            rows="5"
            class="mb-2 w-full rounded-lg border-none bg-grayCust-280 px-3 py-2 text-sm placeholder:text-grayCust-470 focus:border-primary-900 focus:outline-none focus:ring-2 focus:ring-primary-900 lg:mb-0"
            placeholder="Please provide details"
            @input="onInput"
          />
        </div>
      </div>
    </div>
    <Reward />
  </div>
</template>

<script>

import Reward from '@/Common/SurveyModal/Reward.vue';
import { useSurveyStore } from '@/store/survey';
import { mapActions } from 'pinia';

export default {
    name: 'SurveyTextInputType',
    
    components: {
        Reward
    },
    props: {
        question: {
            type: Object,
            required: true,
        },
        response: {
            required: true,
        },
    },
    data() {
        return {
            responseText: this.response?.response_text || ''
        };
    },
    mounted() {
        this.updateResponseText(this.responseText);
    },
    methods: {
        ...mapActions(useSurveyStore, ["updateResponseText"]),
        onInput(event) {
            this.updateResponseText(event.target.value);
        }
    }
}
</script>
