import { createApp, h } from "vue";
import { createInertiaApp, router } from "@inertiajs/vue3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { ZiggyVue } from "ziggy-js";
import * as Sentry from "@sentry/vue";
import VueLazyLoad from 'vue3-lazyload'

import NProgress from "nprogress";
import {
    cdn
} from "@/helpers";
// import AppNotifications from "@/Common/Notifications.vue";
import Notifications from "@kyvg/vue3-notification";

import tooltip from "@/app/Common/Directives/tooltip.js";
import heroicons from "@/app/Common/Directives/heroicons.js";

import MainLayout from "@/Layouts/MainLayout.vue";

import { VueReCaptcha } from "vue-recaptcha-v3";

import { createPinia } from "pinia";
import { PiniaSharedState } from "pinia-shared-state";
const pinia = createPinia();
import { i18nVue  } from 'laravel-vue-i18n';
import { useAppStore } from '@/store/index'
import usermavenPlugin from "@/plugins/usermaven";
// import googleAnalyticsPlugin from "@/plugins/google_analytics";
import customerIOPlugin from "@/plugins/customerio";


export default function instaWpCreateInertiaApp() {
    createInertiaApp({
        progress: { color: "#15b881" },
        resolve: async (name) => {
            const page = await resolvePageComponent(
                `./Pages/${name}.vue`,
                import.meta.glob("./Pages/**/*.vue"),
            );
            if (page.default.layout === undefined) {
                page.default.layout = MainLayout;
            }
            return page;
        },
        async setup({ el, App, props, plugin }) {
            
            const application = createApp({
                render: () => h(App, props),
            });

            application
                .mixin(Sentry.createTracingMixins({ trackComponents: true }))
                .use(plugin)
                .use(ZiggyVue)
                .use(Notifications)
                .use(VueLazyLoad)
                .use(heroicons);

            // Initialize i18n plugin
            const i18nPlugin = {
                resolve: async lang => {
                    const langs = import.meta.glob('../../../lang/*.json');
                    const langPath = `../../../lang/${lang}.json`;
                    const result = await langs[langPath]();
                    return result;
                }
            };
            application.use(i18nVue, i18nPlugin);
            
            // application.use(store);
            pinia.use(
                PiniaSharedState({
                    // Enables the plugin for all stores. Defaults to true.
                    enable: true,
                    // If set to true this tab tries to immediately recover the shared state from another tab. Defaults to true.
                    initialize: false,
                    // Enforce a type. One of native, idb, localstorage or node. Defaults to native.
                    type: "localstorage",
                }),
            );
            application.use(pinia);
            const useAppStoreObj = useAppStore();

            const { auth, tenant, teamData } = props.initialPage.props;
            let user = auth?.user
            if (user?.id) {
                useAppStoreObj.setAuthUser(user);
            }
            if (teamData) {
                useAppStoreObj.setTeamData(teamData);
            }
            if (tenant?.id) {
                useAppStoreObj.setTenant(tenant);
            }

            router.on('success', (event) => {
                if(event?.detail?.page?.props?.auth?.user){
                    useAppStoreObj.setAuthUser(event.detail.page.props.auth.user);
                }
                if(event?.detail?.page?.props?.teamData){
                    useAppStoreObj.setTeamData(event.detail.page.props.teamData);
                }
            });
            application.use(VueReCaptcha, {
                siteKey: import.meta.env.VITE_RECAPTCHA_SITE_KEY,
                loaderOptions: {
                    autoHideBadge: true,
                },
            });

            application.config.globalProperties.cdn = cdn;
            // application.use(createMultiTabState);
            // Register components globally
            // application.directive("tooltip", tooltip);
            
            application.directive("tooltip", tooltip);
            application.directive("heroicons", heroicons);
            // 
            if (props.initialPage.props.TESTING_ENV) {
                application.config.devtools = true;
            }
            //Sentry configuration
            if (import.meta.env.VITE_APP_ENV != "local") {
                Sentry.init({
                    app: application,
                    dsn: import.meta.env.VITE_SENTRY_VUE_DSN,
                    // Set tracesSampleRate to 1.0 to capture 100%
                    // of transactions for performance monitoring.
                    // We recommend adjusting this value in production
                    tracesSampleRate: import.meta.env
                        .VITE_SENTRY_TRACES_SAMPLE_RATE,
                    environment: import.meta.env.VITE_APP_ENV,
                    trackComponents: true,
                    logErrors: true,
                });
                Sentry.attachErrorHandler(application, { logErrors: true });
                if (user?.id) {
                    Sentry.setUser({
                        id: user.id,
                        username: user.name,
                        email: user.email,
                    });
                } else {
                    Sentry.setUser(null);
                }
            }

            if (tenant && tenant.type == "instawp") {
                // usermaven
                application.use(usermavenPlugin, {
                    user: user,
                });
                // // googleAnalytics
                // application.use(googleAnalyticsPlugin, {
                //     ga_client_id: window.ga_client_id,
                //     user: user,
                // });

                // customer.io
                application.use(customerIOPlugin, {
                    _cio: window._cio,
                    user: user,
                });
            }

            // Add global error handler
            // application.config.errorHandler = (error) => {
            //     if (error.message.includes('Failed to fetch dynamically imported module') ||
            //         error.message.includes("Importing a module script failed")) {
            //         window.location.reload()
            //     }
            // }

            return application.mount(el);
        },
    });
}

window.axios.defaults.transformRequest.push(function (data) {
    if (this.url != "/api/v2/track-paywall-event") {
        NProgress.start();
    }
    return data;
});

window.axios.defaults.transformResponse.push(function (data) {
    NProgress.done();
    return data;
});
