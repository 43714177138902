<template>
  <div class="custom-gradient flex flex-col items-center justify-center space-y-8 p-6">
    <div class="flex w-full justify-end">
      <XCircleIcon
        class="size-6 cursor-pointer text-grayCust-470"
        @click="closeModal"
      />
    </div>
    <div>
      <SubscriptionImage class="size-full" />
    </div>
    <div class="space-y-2">
      <h2 class="text-center text-2xl font-semibold text-grayCust-430">
        🎉  Congrats! You’ve been credited ${{ creditedAmount }}.
      </h2>
      <p class="text-center text-sm text-grayCust-910">
        We have added the credits on your wallet.
      </p>
    </div>
  </div>
</template>


<script>
import SubscriptionImage from '@/components/UpdatedDesignVersion/CancelSubscription/SubscriptionImage.vue';

import { XCircleIcon } from "@heroicons/vue/outline";

export default {
    name: 'Success',
    
    components: {
        SubscriptionImage,
        XCircleIcon,
    },
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        creditedAmount: {
            type: Number,
            default: 0,
            required: false,
        }
    },
    methods: {
        closeModal() {
            this.$emit('closeModal');
        },
        closeSubscription(){
            this.$emit('closeSubscription');
        }
    }
}
</script>

<style scoped>
.custom-gradient {
    background: linear-gradient(179deg, rgba(231, 255, 247, 1) 0%, rgba(253, 251, 238, 1) 100%);
    -webkit-text-fill-color: initial;
}

@media (max-height: 600px) {
    :deep(.site-model) {
        align-items: start !important;
    }
}
</style>