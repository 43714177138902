import '../bootstrap';

// Import modules...

import instaWpCreateInertiaApp from '@/app/create-app';

import { useAppStore } from '@/store/index'

const axios = window.axios;
axios.interceptors.response.use(
    function (response) {
        if (response.headers && "user-auth" in response.headers) {
            let userAuth = JSON.parse(response.headers["user-auth"]);
            const useAppStoreObj = useAppStore()
            useAppStoreObj.user_authorization = userAuth
        }
        return response;
    },
    function (error) {
        if (error?.response?.config?.url) {
            if ((error.response.config.url.replace(/^.*\/\/[^\/]+/, '') == '/subscription/plans' || error.response.config.url.replace(/^.*\/\/[^\/]+/, '') == '/api/v2/check-user') && error.response.status == 422) {
                return Promise.reject(error);
            }
        }

        return Promise.reject(error);
    }
);
const el = document.getElementById("app");
const createInstance = () => {
    instaWpCreateInertiaApp()
};
// on initial fetch the php and wp versions.
(async () => {
    try {
        createInstance();
    } catch (e) {
        
        // Deal with the fact the chain failed
    }
})();
