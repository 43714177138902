<template>
  <button :disabled="disabled" :type="type"
    class="inline-flex items-center justify-center rounded-lg border focus:outline-none disabled:cursor-not-allowed disabled:opacity-50"
    :class="[buttonType, buttonSize, iconColor, extraClass]">
    <img v-if="imagePath" :src="cdn(imagePath)" :class="[iconSize ? iconSize : buttonImageSize]" alt="">
    <component :is="iconComponent(iconType, iconName)" v-if="iconName" :class="iconSize ? iconSize : buttonImageSize" />
  </button>
</template>

<script>

import * as OutlineIcons from "@heroicons/vue/outline";
import * as SolidIcons from "@heroicons/vue/solid";

export default {
  name: 'IconButton',

  props: {
    extraClass: {
      type: String,
      default: '',
    },
    iconSize: String,
    btnType: {
      type: String,
      default: 'secondary',
    },
    type: {
      type: String,
      default: 'button',
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    size: {
      type: String,
      default: 'btn-sm',
      required: false
    },
    iconName: {
      type: String,
      required: false,
      default: ''
    },
    iconType: {
      type: String,
      required: true,
      default: 'outline'
    },
    iconColor: {
      type: String,
      required: true,
      default: ''
    },
    imagePath: {
      type: String,
      required: true,
      default: ''
    },
  },
  created() {
    switch (this.btnType) {
      case 'primary':
        this.buttonType = 'bg-primary-900 border-transparent text-white ';
        break;
      case 'secondary':
        this.buttonType = 'bg-secondary-800 transition-colors duration-200 ease-linear hover:bg-primary-310 text-white ';
        break;
      case 'danger':
        this.buttonType = 'bg-redCust-730 transition-colors duration-200 ease-linear hover:bg-pinkCust-150 text-white ';
        break;
      case 'gray-outline-btn':
        this.buttonType = 'text-grayCust-980 border-grayCust-160 hover:bg-grayCust-50 hover:text-grayCust-1740 transition-colors duration-200 ease-linear focus:bg-grayCust-50';
        break;
      case 'danger-outline-btn':
        this.buttonType = 'text-redCust-730 border-redCust-220 hover:bg-redCust-110 hover:text-pinkCust-150 transition-colors duration-200 ease-linear focus:bg-redCust-110';
        break;
    }
    switch (this.size) {
      case 'btn-sm':
        this.buttonSize = 'p-[7px]';
        this.buttonImageSize = 'w-5 h-5';
        break;
      case 'btn-md':
        this.buttonSize = 'p-2.5';
        this.buttonImageSize = 'w-5 h-5';
        break;
      case 'btn-lg':
        this.buttonSize = 'p-3';
        this.buttonImageSize = 'w-5 h-5';
        break;
      case 'btn-xl':
        this.buttonSize = 'p-3.5';
        this.buttonImageSize = 'w-5 h-5';
        break;
      case 'btn-xxl':
        this.buttonSize = 'p-4';
        this.buttonImageSize = 'w-6 h-6';
        break;
    }
  },
  methods: {
    iconComponent(type, iconName) {
      switch (type) {
        case "solid":
          if (Object.prototype.hasOwnProperty.call(SolidIcons, iconName)) {
            return SolidIcons[iconName];
          } else {
            return null;
          }
        case "outline":
          if (Object.prototype.hasOwnProperty.call(OutlineIcons, iconName)) {
            return OutlineIcons[iconName];
          } else {
            return null;
          }
        default:
          return null;
      }
    },
  },
};
</script>