<template>
  <div
    class="border-r sidebar-width border-grayCust-160 transition-all duration-300 ease-in-out overflow-y-auto custom-scrollbar-v2"
    :class="[isCollapsed ? 'min-w-[55px] w-[55px]' : 'min-w-60 w-60', bgColor]"
  >
    <nav class="px-2 py-4">
      <ul class="space-y-1">
        <template
          v-for="(item, index) in sidebarItems"
          :key="index"
        >
          <li v-if="!item?.hide">
            <SidebarMenuItemContent
              v-if="item.icon || item.image"
              :item="item"
              :href="route(item.path, item.params || {} )"
              :is-collapsed="isCollapsed"
              :is-selected="item.is_active"
              :bg-color="bgColor"
              @click="onHandleClick(item, index)"
            />
            <div
              v-else
              class="text-xs uppercase text-grayCust-500 font-semibold py-3"
            >
              {{ item.title }}
            </div>
            
            <transition 
              name="accordion"
              @before-enter="beforeEnter" 
              @enter="enter" 
              @leave="leave"
            >
              <div
                v-if="item.children && openItems.has(index)"
                ref="accordionRefs"
                class="mt-1 space-y-1 overflow-hidden"
              >
                <div
                  v-for="(child, childIndex) in item.children"
                  :key="childIndex"
                >
                  <SidebarMenuItemContent
                    :item="child"
                    :href="route(child.path, child.params || {} )"
                    :is-selected="child.is_active"
                    :is-collapsed="isCollapsed"
                    :bg-color="bgColor"
                    @click="onHandleClick(item, index, false)"
                  />
                </div>
              </div>
            </transition>
          </li>
        </template>
      </ul>
    </nav>
  </div>
</template>
<script setup>
import SidebarMenuItemContent from '@/app/Common/Sidebar/CommonSidebarMenuItems.vue';
import { router } from '@inertiajs/vue3';
import { computed, nextTick, ref, watchEffect } from 'vue';
import { route } from 'ziggy-js';

const props = defineProps({
    menuItems: {
      type: Array,
      required: true
    },
    isCollapsed: {
      type: Boolean,
      default: false
    },
    bgColor: {
      type: String,
      default: 'bg-grayCust-50'
    },
    routeParams: {
      type: Object,
      default: () => ({}),
    },
  });
  sessionStorage.removeItem('openItems');

  const currentRoute = ref(route().current());

router.on('navigate', () => {
  currentRoute.value = route().current();
});

const openItems = ref(new Set());

  const sidebarItems = computed(() => {
    return props.menuItems.map(item => {
      item.is_active = (item?.path == currentRoute.value) || (item?.routename?.includes(currentRoute.value));
      item.hide = item.hide || false;
      item.children?.forEach(child => {
        child.is_active = (child.path == currentRoute.value);
      });
      return item;
    });
  });

function onHandleClick(item, index, is_parent = true) {
  if (!is_parent || !item.children?.length) return;

  if (openItems.value.has(index)) {
    openItems.value.delete(index);
  } else {
    openItems.value.add(index);
  }
}

watchEffect(() => {
  openItems.value.clear(); // Reset open items on refresh
  sidebarItems.value.forEach((item, index) => {
    if (item.children?.some(child => child.is_active)) {
      openItems.value.add(index);
    }
  });
});

function beforeEnter(el) {
  el.style.maxHeight = '0px';
}
function enter(el) {
  nextTick(() => {
    el.style.maxHeight = el.scrollHeight + 'px';
    el.style.transition = 'max-height 0.3s ease-in-out';
  });
}
function leave(el) {
  el.style.maxHeight = '0px';
  el.style.transition = 'max-height 0.3s ease-in-out';
}
</script>

<style>
.accordion-enter-active, .accordion-leave-active {
  transition: max-height 0.3s ease-in-out;
}
.accordion-enter, .accordion-leave-to {
  max-height: 0;
}
</style>