<template>
  <div>
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5249 0.732959C13.6546 -0.432217 11.1902 -0.154961 9 1.22079C6.80629 -0.158471 4.3454 -0.435727 2.47513 0.732959C-0.481457 2.57548 -0.840043 7.32393 1.67358 11.3389C3.52628 14.2974 6.4231 16.0312 9 15.9996C11.5769 16.0312 14.4737 14.2974 16.3264 11.3389C18.84 7.32393 18.4815 2.57548 15.5249 0.732959ZM3.03059 10.4931C2.50325 9.64726 2.11302 8.75583 1.87045 7.84334C1.64194 6.9835 1.55757 6.1412 1.62085 5.34453C1.73334 3.85998 2.33802 2.70534 3.32238 2.09116C4.30673 1.47699 5.61452 1.43838 6.99613 1.98938C7.20355 2.07361 7.41097 2.16837 7.61487 2.27366C6.8766 2.94048 6.1981 3.74417 5.62155 4.66367C4.0958 7.10282 3.63174 9.81221 4.16259 11.9776C3.74776 11.5284 3.36808 11.03 3.03059 10.4931ZM16.1295 7.83983C15.887 8.75583 15.5003 9.64726 14.9694 10.4896C14.6319 11.03 14.2522 11.5249 13.8339 11.9741C14.3085 10.0368 13.9886 7.66084 12.8179 5.43578C12.737 5.27785 12.5472 5.22871 12.396 5.31996L8.75743 7.59065C8.61681 7.67839 8.57462 7.86089 8.66251 8.00127L9.19687 8.8541C9.28476 8.99448 9.46757 9.0366 9.60819 8.94886L11.9671 7.47835C12.0445 7.70296 12.1183 7.93108 12.1781 8.16271C12.4066 9.02256 12.4909 9.86485 12.4277 10.6615C12.3152 12.1461 11.7105 13.3007 10.7261 13.9149C10.234 14.2237 9.66092 14.3852 9.03867 14.4027C9.02461 14.4027 9.01055 14.4027 8.99648 14.4027C8.98594 14.4027 8.97188 14.4027 8.96133 14.4027C8.33908 14.3852 7.76604 14.2237 7.27386 13.9149C6.28951 13.3007 5.68483 12.1461 5.57233 10.6615C5.51257 9.86485 5.59694 9.02256 5.82194 8.16271C6.061 7.24672 6.45122 6.35529 6.98207 5.51299C7.5094 4.66718 8.1422 3.92666 8.85938 3.30898C9.53436 2.72639 10.2551 2.28419 11.0004 1.98938C12.3855 1.43838 13.6898 1.47699 14.6741 2.09116C15.6585 2.70534 16.2631 3.85998 16.3756 5.34453C16.4389 6.13769 16.3545 6.97999 16.1295 7.83983Z"
        fill="#9CA3AF"
      />
    </svg>
  </div>
</template>

<script>
export default {
    name: 'GodaddyCom'
}
</script>