<template>
  <div>
    <slot name="listBox" />

    <slot name="menuBox" />
    <slot name="popover" />
  </div>
</template>
<script>

export default {
  name: 'Dropdown',
};
</script>