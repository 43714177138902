<template>
  <div>
    <svg
      width="11"
      height="7"
      viewBox="0 0 11 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.293031 1.29299C0.480558 1.10552 0.734866 1.0002 1.00003 1.0002C1.26519 1.0002 1.5195 1.10552 1.70703 1.29299L5.00003 4.58599L8.29303 1.29299C8.38528 1.19748 8.49562 1.1213 8.61763 1.06889C8.73963 1.01648 8.87085 0.988893 9.00363 0.987739C9.13641 0.986585 9.26809 1.01189 9.39098 1.06217C9.51388 1.11245 9.62553 1.1867 9.71943 1.28059C9.81332 1.37449 9.88757 1.48614 9.93785 1.60904C9.98813 1.73193 10.0134 1.86361 10.0123 1.99639C10.0111 2.12917 9.98354 2.26039 9.93113 2.38239C9.87872 2.5044 9.80254 2.61474 9.70703 2.70699L5.70703 6.70699C5.5195 6.89446 5.26519 6.99978 5.00003 6.99978C4.73487 6.99978 4.48056 6.89446 4.29303 6.70699L0.293031 2.70699C0.10556 2.51946 0.000244141 2.26515 0.000244141 1.99999C0.000244141 1.73483 0.10556 1.48052 0.293031 1.29299V1.29299Z"
        fill="#D1D5DB"
      />
    </svg>
  </div>
</template>

<script>
export default {
    name: 'hosting arrow'
}
</script>