<template>
  <div>
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.33317 2.33312H1.99984C1.64622 2.33312 1.30708 2.47359 1.05703 2.72364C0.80698 2.97369 0.666504 3.31283 0.666504 3.66645V10.9998C0.666504 11.3534 0.80698 11.6925 1.05703 11.9426C1.30708 12.1926 1.64622 12.3331 1.99984 12.3331H9.33317C9.68679 12.3331 10.0259 12.1926 10.276 11.9426C10.526 11.6925 10.6665 11.3534 10.6665 10.9998V7.66645M9.72384 1.39045C9.84683 1.2631 9.99396 1.16153 10.1566 1.09165C10.3193 1.02177 10.4943 0.984987 10.6713 0.983449C10.8483 0.98191 11.0239 1.01565 11.1878 1.08269C11.3516 1.14973 11.5005 1.24873 11.6257 1.37392C11.7509 1.49911 11.8499 1.64798 11.9169 1.81184C11.984 1.97571 12.0177 2.15128 12.0162 2.32832C12.0146 2.50536 11.9779 2.68032 11.908 2.84299C11.8381 3.00566 11.7365 3.15279 11.6092 3.27578L5.88517 8.99978H3.99984V7.11445L9.72384 1.39045Z"
        stroke="#1F2937"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
    name: 'EditSvg'
}
</script>