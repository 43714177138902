<template>
  <div>
    <div class="border-b border-primary-1500 pb-1 mb-1 font-semibold text-xs w-full">
      Order Summary
    </div>
    <div class="flex justify-between items-center mb-1">
      <div class="text-xs font-normal">
        Subtotal
      </div>
      <div class="text-xs font-semibold">
        ${{ subTotal }}
      </div>
    </div>
    <div
      v-if="couponCode"
      class="flex justify-between items-center mb-1 pb-1 border-b border-primary-1500"
    >
      <div class="text-xs font-normal">
        Coupon code
      </div>
      <div>
        <span
          class="bg-secondary-150 font-jakarta font-bold rounded-full text-primary-900 p-0.5 mr-1"
          style="font-size: 8px;"
        >Saved ${{ couponCodeValue }}</span> <span
          class="text-grayCust-730 text-xs font-bold bg-secondary-800 border border-black border-dashed px-2"
        >{{ couponCode }}</span>
      </div>
    </div>
    <div class="flex justify-between items-center">
      <div class="text-xs font-normal">
        Total (USD)
      </div>
      <div class="text-xs font-semibold">
        ${{ total }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name:'PlansTooltip',
    props: {
        total: {
            type: Number,
            required: true
        },
        subTotal: {
            type: Number,
            required: true
        },
        couponCode: {
            type: String,
            required: true
        },
        couponCodeValue: {
            type: Number,
            required: true
        }
    }
}
</script>