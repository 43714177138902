<template>
  <CPopover
    panel-class="w-[300px]"
    popover-class="h-[32px]"
    auto-close
  >
    <template #popoverButton>
      <Avatar
        :image-path="appStore.user.profile_pic"
        :alt="appStore.user.name"
      />
    </template>
    <template
      #popoverOptions
    >
      <div
       
        class="px-1 pt-0 pb-1"
      >
        <div
          v-if="appStore.user.credit_balance && appStore.user.credit_balance > 0"
          class="border border-grayCust-900 rounded-md px-3 py-[15px] space-y-2 bg-gradient"
        >
          <span class="text-grayCust-430 text-2xl font-medium mr-1">$ {{ appStore.user.credit_balance }}</span>
          <div class="flex items-center gap-1">
            <span class="text-grayCust-600 text-xs font-medium">{{ $t('available_credits') }}</span>
            <OutlineInformationCircleIcon
              v-if="$page.props.TESTING_ENV"
              class="w-4 h-4 text-grayCust-600 cursor-pointer"
            />
          </div>
        </div>
      </div>
      <div class="border-t border-grayCust-160 py-1">
        <div
          v-for="(item, index) in items"
          :key="index"
          class="flex items-center px-4 py-2 gap-3 hover:bg-grayCust-100 cursor-pointer last:border-t last:border-grayCust-160"
          @click="selectItem(item)"
        >
          <component
            :is="item.iconName"
            v-if="item.iconName"
            class="w-4 h-4 text-grayCust-550"
          />
          <span
            class="text-sm text-grayCust-640"
          >
            {{ item.name }}
          </span>
        </div>
      </div>
    </template>
  </CPopover>
</template>

<script setup>
import Avatar from '@/app/Common/CommonAvatar.vue';
import CPopover from '@/app/Common/CommonPopover.vue';
import { useAppStore } from '@/store/index';
import { router } from '@inertiajs/vue3';

defineProps({
  items: {
    type: Array,
    required: true,
  },
});

const appStore = useAppStore();

const selectItem = (item) => {
    if (item.name === 'Logout') {
        logout()
    } else {
        router.visit(item.href)
    }
}

const logout = () => {
    if (window.$crisp) {
        window.CRISP_TOKEN_ID = null;
        $crisp.push(["safe", true]);
        $crisp.push(["do", "session:reset"]);
    }
    if (window.$usermaven) {
        window.$usermaven.reset();
    }
    if (window.$_cio) {
        window.$_cio.reset();
    }
    appStore.commands = [];
    window.localStorage.setItem('connectBulkSelection', '[]');
    window.localStorage.setItem('selectAllConnectBulkEdit', false);
    window.location = '/app/logout';
};
</script>

<style scoped>
.bg-gradient {
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(236, 253, 245, 1) 100%);
}
</style>
