<template>
  <div class="w-full">
    <modal-hosting-header
      :heading="provider.name"
      btn-text="New Site"
      :has-plus="true"
      extra-class="disabled:opacity-40 bg-primary-900 hover:bg-primary-200 text-white text-base leading-6 font-medium py-2 pr-3.5 pl-3 rounded inline-flex items-center"
      @btn-close-modal="closeModalPopup"
    />

    <div class="m-8">
      <form @submit.prevent="submit">
        <div class="hosting-url-form w-full bg-white rounded-xl p-8">
          <div
            v-show="alert == 'error'"
            class="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg  "
            role="alert"
          >
            <span class="font-medium">{{ $t('failed') }}!</span> {{ alertMessage }}
          </div>

          <div
            v-show="alert == 'success'"
            class="p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg  "
            role="alert"
          >
            <span class="font-medium">{{ $t('success') }}!</span> {{ alertMessage }}
          </div>

          <div
            v-if="provider.required_fields_for_new_site.includes('name')"
            class="mt-2.5"
          >
            <label
              for="name"
              class="block text-base font-medium text-gray-800"
            >{{ $t('name') }} <span
              v-if="msg.name"
              class="errors"
            >: {{ msg.name }}</span></label>
            <input
              id="name"
              v-model="newsite_form.name"
              type="text"
              name="name"
              class="mt-1.5 w-full shadow-sm focus:ring-primary-900 focus:border-primary-900 block sm:text-sm border-gray-300 rounded-md"
              :placeholder="$t('enter_your_name')"
              required
              maxlength="10"
            >
          </div>
          <div
            v-if="provider.required_fields_for_new_site.includes('domainName')"
            class="mt-2.5"
          >
            <label
              for="domainName"
              class="block text-base font-medium text-gray-800"
            >{{ $t('domain_name') }}</label>
            <input
              id="domainName"
              v-model="newsite_form.domainName"
              type="text"
              name="domainName"
              class="
                mt-1.5
                w-full
                shadow-sm
                focus:ring-primary-900 focus:border-primary-900
                block
                sm:text-sm
                border-gray-300
                rounded-md
              "
              :placeholder="$t('enter_your_domain_name')"
              required
            >
          </div>
          <div
            v-if="provider.required_fields_for_new_site.includes('user')"
            class="mt-2.5"
          >
            <label
              for="user"
              class="block text-base font-medium text-gray-800"
            >{{ $t('user') }}</label>
            <select
              id="user"
              v-model="newsite_form.user"
              name="user"
              class="
                  mt-1
                  block
                  w-full
                  pl-3
                  pr-10
                  py-2
                  text-base
                  border-gray-300
                  focus:outline-none focus:ring-primary-900 focus:border-primary-900
                  sm:text-sm
                  rounded-md
              "
              required
            >
              <option value="">
                -- {{ $t('select_user') }} --
              </option>
              <option
                v-for="user in users"
                :key="user.id"
                :value="user.id"
              >
                {{ user.username }}
              </option>
            </select>
          </div>
          <!--          <div v-if="provider.required_fields_for_new_site.includes('phpVersion')" class="mt-2.5">
            <label for="phpVersion" class="block text-base font-medium text-gray-800">{{ $t('choose_php_version') }}</label>
            <select id="phpVersion" name="phpVersion" v-model="newsite_form.phpVersion" class="
                    mt-1
                    block
                    w-full
                    pl-3
                    pr-10
                    py-2
                    text-base
                    border-gray-300
                    focus:outline-none focus:ring-primary-900 focus:border-primary-900
                    sm:text-sm
                    rounded-md
                "
                required>
                <option value="">&#45;&#45; {{ $t('select_PHP_version') }} &#45;&#45;</option>
                <option v-for="php in php_versions" :key="php" :value="php">
                    {{ php }}
                </option>
                </select>
          </div>-->
          <div class="mt-6">
            <button
              class="
                disabled:opacity-40
                bg-primary-900
                hover:bg-primary-200
                text-white text-base
                leading-6
                font-medium
                py-1.5
                px-3
                rounded
              "
              @click="newsite_form.is_test = false"
            >
              <svg
                v-if="btnLoading && processing"
                role="status"
                class="inline mr-3 w-4 h-4 text-white animate-spin"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#E5E7EB"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentColor"
                />
              </svg>
              {{ btnLoading && processing ? 'Creating...' : 'Create' }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import ThemeInstallingIcon from '@/Common/Icons/ThemeInstallingIcon.vue';
import ModalHostingHeader from "@/components/UpdatedDesignVersion/HostingManager/ModalHostingHeader.vue";

export default {
  name: "Newsite",
  components: { ModalHostingHeader, ThemeInstallingIcon },
  props: {
    provider: {
      type: Object,
    },
    selectedAccountDetails: {
      type: Object,
    },
    php_versions: {
        type: Object
    },
    users: {
        type: Object
    },
  },
  data() {
    return {
      alert:'',
      alertMessage:'',
      processing: false,
      btnLoading: false,
      msg:{},
      newsite_form: {
        name: "",
        domainName: "",
        user: "",
        phpVersion: "",
        provider: "",
        selectedAccountDetails: "",
        is_test: false,
      },
    };
  },
  watch: {
   'newsite_form': {
        handler: function(v) {
            this.msg['name'] = '';
            let pattern = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
                let res = v.name.match(pattern);

                if(res){
                    v.name = v.name.substr(0, v.name.length - 1);
                    this.msg['name'] = 'Special Character Not Allowed!';
                }

                if(v.name.length == 10){
                    this.msg['name'] = 'Only 10 Character Allowed!';
                }

        },
        deep: true
    }
},
  methods: {
    submit() {
      this.newsite_form.provider = this.provider;
      this.newsite_form.selectedAccountDetails = this.selectedAccountDetails;

      this.processing = true;
      this.btnLoading = true;
      let that = this;

      axios
        .post("/api/v1/save-hosting-new-site", that.newsite_form)
        .then((response) => {
          if(response.data.status){
              that.alert = 'success';
              that.alertMessage = response.data.message;
              this.resetForm();
          }
          else{
              that.alert = 'error';
              that.alertMessage = response.data.message;
          }
          this.$emit("refreshDataProviders");
          return response
        })
        .catch((response) => {
          that.alert = 'error';
          that.alertMessage = response.response.message || response.message;
        })
        .finally(() => {
          that.processing = false;
          that.btnLoading = false;
        });
    },
    resetForm(){
      this.newsite_form = {
        name: "",
        domainName: "",
        user: "",
        phpVersion: "",
        provider: "",
        selectedAccountDetails: "",
        is_test: false,
      }
    },
    closeModalPopup() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style scoped>
.hosting-url-form {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
}
.errors{
    color: red;
    font-size: small;
}
</style>
