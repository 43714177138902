<template>
  <DialogModal
    :persistent-message="!surveyCompleted && persistentMessage"
    :closeable="false"
    :show="isSurveyModalOpened"
    main-class="modal-margin"
    max-width="xl"
    extra-flex="golive-model flex justify-center items-start md:items-center"
    header-class="modal-padding"
    :extra-class="[!surveyCompleted ? 'lg:min-w-[800px]' : 'md:min-w-[620px]' ,'min-w-full rounded-xl']"
    @close="closeModal"
  >
    <template #content>
      <div
        v-if="!surveyCompleted"
        class="p-4 sm2:p-6"
      >
        <SiteNewCommonTab
          :survey-mode="true"
          :show-beta-badge="false"
          :current-index="currentStep"
          :total-tab-array="questionTitles"
          :tab-title="questionTitles[currentStep - 1]"
        />

        <div v-for="(question, index) in questions">
          <TextInput
            v-if="currentStep === (index + 1) && [questionTypes.TEXT, questionTypes.TEXTAREA].includes(question.question_type)"
            :question="question"
            :response="getUserResponse(question)"
          />

          <RadioInput
            v-if="currentStep === (index + 1) && question.question_type === questionTypes.RADIO"
            :question="question"
            :response="getUserResponse(question)"
            @next-step="nextStepOrCompleteSurvey"
          />

          <CheckboxInput
            v-if="currentStep === (index + 1) && question.question_type === questionTypes.CHECKBOX"
            :question="question"
            :response="getUserResponse(question)"
            @next-step="nextStepOrCompleteSurvey"
          />
        </div>
      </div>
      <Success
        v-else
        :credited-amount="creditAmount"
        @close-modal="closeModal"
      />
      <Footer
        :survey-completed="surveyCompleted"
        :current-step="currentStep"
        :selected-last-tab="questions.length == currentStep"
        :total-steps="questions.length"
        @pause-survey="pauseSurvey"
        @next-step="nextStep"
        @save-and-continue="saveAndContinue"
        @back-click="previousStep"
        @close-modal="closeModal"
      />
    </template>
  </DialogModal>
</template>

<script>
import { SpeakerphoneIcon } from '@heroicons/vue/solid';
import DialogModal from "@/Jetstream/DialogModal.vue";

import SiteNewCommonTab from '@/components/SiteNewDesign/SiteNewCommonTab.vue';
import TextInput from '@/Common/SurveyModal/Inputs/Text/Index.vue';
import RadioInput from '@/Common/SurveyModal/Inputs/Radio/Index.vue';
import CheckboxInput from '@/Common/SurveyModal/Inputs/Checkbox/Index.vue';
import Success from '@/Common/SurveyModal/Success.vue';
import Footer from '@/Common/SurveyModal/Footer.vue';
import Reward from '@/Common/SurveyModal/Reward.vue';
import { useAppStore } from '@/store/index';
import { useSurveyStore } from '@/store/survey';
import { mapState, mapActions, mapStores } from 'pinia';
import { QUESTION_TYPES } from "../../store/survey";

export default {
    
    name: 'SurveyModal',
    components: {
        SpeakerphoneIcon,
        DialogModal,
        SiteNewCommonTab,
        TextInput,
        RadioInput,
        CheckboxInput,
        Success,
        Footer,
        Reward
    },
    props: {
        isSurveyModalOpened: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {
            persistentMessage: "Please complete the survey or Do it later (Skip)",
            questionTypes: QUESTION_TYPES,
            surveyCompleted: false,
        };
    },
    computed: {
        ...mapStores(useSurveyStore, useAppStore),
        ...mapState(useSurveyStore, ["survey", "currentStep", "responseText", "selectedOptionId", "selectedOptionIds", "isSubmitting"]),
        userSurvey() {
            return this.surveyStore.userSurvey;
        },
        questions() {
            return this.surveyStore.questions || [];
        },
        questionTitles() {
            return this.questions.map((question) => question.title);
        },
        creditAmount() {
            return this.survey.credit_amount;
        },
    },
    methods: {
        ...mapActions(useSurveyStore, ["nextStep", "previousStep", "setIsSubmitting"]),
        closeModal() {
            this.$emit('closeModal');
        },
        async pauseSurvey() {
            this.setIsSubmitting(true);
            await this.surveyStore.runPauseSurveyApi(this.userSurvey.id);
            this.$emit('closeModal');
        },
        async nextStepOrCompleteSurvey() {
             if (this.questions.length === this.currentStep) {
                const userSurvey = await this.surveyStore.runCompleteSurveyApi(this.userSurvey.id);
                this.appStore.updateGeneralSurveys(userSurvey.data.data);
                if (this.creditAmount > 0) {
                    this.surveyCompleted = true;
                    return;
                }
                this.closeModal();
                return;
            }

            this.nextStep();
        },
        async saveAndContinue() {
            const currentQuestion = this.surveyStore.getCurrentQuestion;
            const responseValue = {};

            if ([QUESTION_TYPES.TEXT, QUESTION_TYPES.TEXTAREA, QUESTION_TYPES.RADIO].includes(currentQuestion.question_type) && !!this.responseText)
                responseValue.response_text = this.responseText;

            if (currentQuestion.question_type === QUESTION_TYPES.RADIO && !!this.selectedOptionId && !!this.responseText) {
                responseValue.survey_question_option_id = this.selectedOptionId;
                responseValue.response_text = this.responseText;
            }

            if (currentQuestion.question_type === QUESTION_TYPES.CHECKBOX && this.selectedOptionIds.length > 0)
                responseValue.survey_question_option_ids = this.selectedOptionIds;

            if (Object.keys(responseValue).length === 0) {
                this.nextStepOrCompleteSurvey();
                return;
            }

            try {
                this.setIsSubmitting(true);
                const requestResponse = await this.surveyStore.runSaveSurveyResponseApi(
                    this.userSurvey.id,
                    {
                        survey_question_id: currentQuestion.id,
                        next_question_id: this.surveyStore.getNextQuestion?.id,
                        question_type: currentQuestion.question_type,
                        ...responseValue,
                    });

                const responseData = requestResponse.data
                await this.surveyStore.updateSurveyResponses(responseData.data);
                await this.appStore.updateGeneralSurveys({ ...this.userSurvey, responses: [...this.userSurvey.responses, responseData.data] });

                this.nextStepOrCompleteSurvey();
                this.setIsSubmitting(false);
            } catch (error) {
                console.error("Error while saving text survey response.", error);
            }
        },
        getUserResponse(question) {
            return this.surveyStore.surveyResponses.find((response) => {
                return response.survey_question_id === question.id
            })
        },
    },
}
</script>

<style scoped>
@media (max-height: 500px) {
    .golive-model{
        align-items: start !important;
    }
}

.custom-gradient {
    background: linear-gradient(82deg, rgba(40, 80, 76, 1) 0%, rgba(49, 107, 102, 1) 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.bg-gradient {
    background: linear-gradient(256deg, rgba(179, 249, 226, 1) 0%, rgba(179, 249, 226, 0.2553396358543417) 68%, rgba(255, 255, 255, 0.7063200280112045) 85%, rgba(243, 233, 141, 0.5) 100%);
}
</style>
