<template>
  <div class="w-full">
    <modal-hosting-header
      :heading="connectHostingObject.name"
      btn-text="Connect"
      :has-plus="false"
      extra-class="disabled:opacity-40 bg-primary-900 hover:bg-primary-200 text-white text-base leading-6 font-medium py-2 pr-3.5 pl-3 rounded inline-flex items-center"
      @btn-close-modal="closeModalPopup"
    />

    <div class="m-8">
      <form @submit.prevent="submit">
        <div class="hosting-url-form w-full bg-white rounded-xl p-8">
          <div
            v-show="alert == 'error'"
            class="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg  "
            role="alert"
          >
            <span class="font-medium">{{ $t('failed') }}!</span> {{ alertMessage }}
          </div>

          <div
            v-show="alert == 'success'"
            class="p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg  "
            role="alert"
          >
            <span class="font-medium">{{ $t('success') }}!</span> {{ alertMessage }}
          </div>

          <div class="">
            <label
              for="alias"
              class="block text-base font-medium text-gray-800"
            >{{ $t('hosting_provider_alias') }}</label>
            <input
              id="alias"
              v-model="connector_form.alias"
              type="text"
              name="alias"
              class="
                mt-1.5
                w-full
                shadow-sm
                focus:ring-primary-900 focus:border-primary-900
                block
                sm:text-sm
                border-gray-300
                rounded-md
              "
              :placeholder="$t('enter_alias')"
              required
            >
          </div>

          <div
            v-if="connectHostingObject.required_fields_for_connection.includes('email')"
            class="mt-2.5"
          >
            <label
              for="email"
              class="block text-base font-medium text-gray-800"
            >{{ $t('email') }}</label>
            <input
              id="email"
              v-model="connector_form.email"
              type="text"
              name="email"
              class="mt-1.5 w-full shadow-sm focus:ring-primary-900 focus:border-primary-900 block sm:text-sm border-gray-300 rounded-md"
              :placeholder="$t('enter_your_email')"
              required
            >
          </div>

          <div
            v-if="connectHostingObject.required_fields_for_connection.includes('api_key')"
            class="mt-2.5"
          >
            <label
              for="api_key"
              class="block text-base font-medium text-gray-800"
            >{{ $t('API_key') }}</label>
            <input
              id="api_key"
              v-model="connector_form.api_key"
              type="text"
              name="api_key"
              class="mt-1.5 w-full shadow-sm focus:ring-primary-900 focus:border-primary-900 block sm:text-sm border-gray-300 rounded-md"
              :placeholder="$t('enter_your_api_key')"
              required
            >
          </div>
          <div
            v-if="connectHostingObject.required_fields_for_connection.includes('secret_key')"
            class="mt-2.5"
          >
            <label
              for="secret_key"
              class="block text-base font-medium text-gray-800"
            >{{ $t('secret_key') }}</label>
            <input
              id="secret_key"
              v-model="connector_form.secret_key"
              type="text"
              name="secret_key"
              class="
                mt-1.5
                w-full
                shadow-sm
                focus:ring-primary-900 focus:border-primary-900
                block
                sm:text-sm
                border-gray-300
                rounded-md
              "
              :placeholder="$t('enter_your_secret_key')"
              required
            >
          </div>
          <div
            v-if="connectHostingObject.required_fields_for_connection.includes('username')"
            class="mt-2.5"
          >
            <label
              for="username"
              class="block text-base font-medium text-gray-800"
            >{{ $t('username') }}</label>
            <input
              id="username"
              v-model="connector_form.username"
              type="text"
              name="username"
              class="mt-1.5 w-full shadow-sm focus:ring-primary-900 focus:border-primary-900 block sm:text-sm border-gray-300 rounded-md"
              :placeholder="$t('enter_your_username')"
              required
            >
          </div>
          <div
            v-if="connectHostingObject.required_fields_for_connection.includes('password')"
            class="mt-2.5"
          >
            <label
              for="password"
              class="block text-base font-medium text-gray-800"
            >{{ $t('password') }}</label>
            <input
              id="password"
              v-model="connector_form.password"
              type="text"
              name="password"
              class="mt-1.5 w-full shadow-sm focus:ring-primary-900 focus:border-primary-900 block sm:text-sm border-gray-300 rounded-md"
              :placeholder="$t('enter_your_password')"
              required
            >
          </div>
          <div
            v-if="connectHostingObject.required_fields_for_connection.includes('access_token')"
            class="mt-2.5"
          >
            <label
              for="access_token"
              class="block text-base font-medium text-gray-800"
            >{{ $t('access_token') }}</label>
            <input
              id="access_token"
              v-model="connector_form.access_token"
              type="text"
              name="access_token"
              class="mt-1.5 w-full shadow-sm focus:ring-primary-900 focus:border-primary-900 block sm:text-sm border-gray-300 rounded-md"
              :placeholder="$t('enter_your_access_token')"
              required
            >
          </div>
          <!-- <div v-if="processing" class="mt-6">
            <div>
                <theme-installing-icon style="width: 30px; height: 30px" />
            </div>
          </div> -->
          <div class="mt-6 flex items-center">
            <button
              class="
                disabled:opacity-40
                bg-primary-900
                hover:bg-primary-200
                text-white text-base
                leading-6
                font-medium
                py-1.5
                px-3
                rounded
                flex
                items-center
              "
              :class="isConnectBtnLoading && processing ? 'cursor-not-allowed' : 'cursor-pointer'"
              @click="handleConnect"
            >
              <svg
                v-if="isConnectBtnLoading && processing"
                role="status"
                class="inline mr-3 w-4 h-4 text-white animate-spin"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#E5E7EB"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentColor"
                />
              </svg>
              {{ isConnectBtnLoading && processing ? ' Connecting...' : 'Connect' }}
            </button>
            <button
              class="
                ml-3
                disabled:opacity-40
                border-2 border-primary-900
                text-primary-900 text-base
                leading-6
                font-medium
                py-1
                px-3
                rounded
                flex
                items-center
              "
              :class="isTestBtnLoading && processing ? 'cursor-not-allowed' : 'cursor-pointer'"
              @click="handleTest"
            >
              <svg
                v-if="isTestBtnLoading && processing"
                role="status"
                class="inline mr-2 w-4 h-4 text-gray-200 animate-spin "
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="#005E54"
                />
              </svg>
              {{ isTestBtnLoading && processing ? ' Testing...' : 'Test' }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import ThemeInstallingIcon from '@/Common/Icons/ThemeInstallingIcon.vue';
import ModalHostingHeader from "@/components/UpdatedDesignVersion/HostingManager/ModalHostingHeader.vue";

export default {
  name: "ConnectBtn",
  components: { ModalHostingHeader, ThemeInstallingIcon },
  props: {
    connectHostingObject: {
      type: Object,
    },
  },
  data() {
    return {
      processing: false,
      isConnectBtnLoading:false,
      isTestBtnLoading:false,
      alert:'',
      alertMessage:'',
      connector_form: {
        alias: "",
        secret_key: "",
        api_key: "",
        username: "",
        password: "",
        email: "",
        provider: "",
        is_test: false,
      },
    };
  },
  methods: {
    handleTest(){
      if(this.isTestBtnLoading ==false){
        this.connector_form.is_test = true
        this.isTestBtnLoading =true
        this.isConnectBtnLoading = false
      }
    },
    handleConnect(){
      if(this.isConnectBtnLoading == false){
        this.connector_form.is_test = false 
        this.isConnectBtnLoading =true
        this.isTestBtnLoading = false
      }
    },
    submit() {
      this.connector_form.provider = this.connectHostingObject;

      this.processing = true;
      let that = this;

      axios
        .post("/api/v1/save-hosting-provider-connection", that.connector_form)
        .then((response) => {
          if(response.data.status){
              that.alert = 'success';
              that.alertMessage = response.data.message;
              if(!this.connector_form.is_test){
                this.resetForm();
              }
          }
          else{
              that.alert = 'error';
              that.alertMessage = response.message;
          }
          this.$emit("refreshDataProviders");
          return response
        })
        .then((response) => {
            
        })
        .catch((response) => {
          that.alert = 'error';
          that.alertMessage = response.response.message || response.message;
        })
        .finally(() => {
          that.processing = false;
          this.isTestBtnLoading =false
          this.isConnectBtnLoading =false
        });
    },
    resetForm() {
      this.connector_form = {
        alias: "",
        secret_key: "",
        api_key: "",
        username: "",
        password: "",
        access_token: "",
        provider: "",
        is_test: false,
      }
    },
    closeModalPopup() {
      this.$emit("closeModal");
    }
  },
};
</script>

<style scoped>
.hosting-url-form {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
}
</style>
