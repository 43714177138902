<template>
  <button
    :type="type"
    class="inline-flex items-center px-4 py-2 bg-secondary-800 border border-secondary-800 rounded-md font-semibold text-xs text-white uppercase tracking-widest shadow-sm hover:bg-insta-primary-dark focus:outline-none active:bg-insta-primary-dark disabled:opacity-25 transition focus:ring-2 focus:ring-offset-2 focus:ring-secondary-800"
  >
    <slot />
  </button>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: 'button',
        },
    }
}
</script>
