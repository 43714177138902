// Create a new store instance.
import { defineStore } from 'pinia';

export const SURVEY_TYPES = {
    GENERAL: 'general',
    FEEDBACK: 'feedback',
}

const SURVEY_ROUTE_MAP = {
    [SURVEY_TYPES.GENERAL]: 'user-surveys',
    [SURVEY_TYPES.FEEDBACK]: 'feedback-surveys',
}

export const QUESTION_TYPES = {
    TEXT: 'text',
    TEXTAREA: 'textarea',
    RADIO: 'radio',
    CHECKBOX: 'checkbox',
}

export const useSurveyStore = defineStore('survey', {
    state: () => ({
        survey: null,
        feedbackRating: null,
        surveyResponses: [],
        responseText: '',
        selectedOptionId: null,
        selectedOptionIds: [],
        currentStep: 1,
        isFeedbackSurveyTriggeredManually: false,
        shareReviewToTP: false,
        isSubmitting: false,
        cancelSubscriptionInput: {
            reasonText: '',
            otherApplicationName: '',
            anyOtherRemarks: '',
            continueSubscription: true, // Option for user to continue subscription or not
        }
    }),
    actions: {
        updateSurvey(newSurvey) {
            this.survey = newSurvey;

            if (newSurvey.user_survey) {
                this.surveyResponses = newSurvey.user_survey.responses || [];

                if (newSurvey.user_survey.current_question_id) {
                    const currentQuestionIndex = newSurvey.questions.findIndex(
                        question => question.id === newSurvey.user_survey.current_question_id
                    );
                    this.currentStep = currentQuestionIndex + 1;
                }
            }
        },
        reset() {
            this.survey = null;
            this.feedbackRating = null;
            this.surveyResponses = [];
            this.responseText = '';
            this.selectedOptionId = null;
            this.selectedOptionIds = [];
            this.cancelSubscriptionInput = {
                reasonText: '',
                otherApplicationName: '',
                anyOtherRemarks: '',
                continueSubscription: true,
            }
        },
        updateSurveyResponses(response) {
            const existingResponseIndex = this.surveyResponses.findIndex(
                exsitingResponse => exsitingResponse.id === response.id
            );

            if (existingResponseIndex > -1) {
                this.surveyResponses[existingResponseIndex] = response;
            } else {
                this.surveyResponses = [...this.surveyResponses, response];
            }
        },
        updateResponseText(responseText) {
            this.responseText = responseText
        },
        updateFeedbackRating(rating) {
            this.feedbackRating = rating

            if (rating >= 4) {
                this.updateShareReviewToTP(true);
            }
        },
        updateShareReviewToTP(value) {
            this.shareReviewToTP = value;
        },
        updateSelectedOptionId(selectedOptionId) {
            this.selectedOptionId = selectedOptionId
        },
        updateSelectedOptionIds(selectedOptionIds) {
            this.selectedOptionIds = selectedOptionIds
        },
        triggerFeedbackSurveyManually(value = true) {
            this.isFeedbackSurveyTriggeredManually = value;
        },
        nextStep() {
            if (this.currentStep < this.questions.length) {
                this.currentStep += 1;
            }
        },
        previousStep() {
            if (this.currentStep > 1) {
                this.currentStep -= 1;
            }
        },
        setIsSubmitting(value) {
            this.isSubmitting = value;
        },
        async runStartSurveyApi(surveyId) {
            return axios.post("/api/v2/" + SURVEY_ROUTE_MAP[this.surveyType], { survey_id: surveyId });
        },
        async runCompleteSurveyApi(userSurveyId) {
            return axios.put("/api/v2/" + SURVEY_ROUTE_MAP[this.surveyType] + "/" + userSurveyId + "/complete");
        },
        async runSaveSurveyResponseApi(userSurveyId, params = {}) {
            return axios.put("/api/v2/" + SURVEY_ROUTE_MAP[this.surveyType] + "/" + userSurveyId, params);
        },
        async runPauseSurveyApi(userSurveyId) {
            return axios.put("/api/v2/" + SURVEY_ROUTE_MAP[this.surveyType] + "/" + userSurveyId + "/pause-survey");
        },
        async runResetSurveyPauseApi(userSurveyId) {
            return axios.put("/api/v2/" + SURVEY_ROUTE_MAP[this.surveyType] + "/" + userSurveyId + "/reset-survey-pause");
        },
        updateCancelSubscriptionInput(inputName, value) {
            this.cancelSubscriptionInput[inputName] = value;
        },
        async runCompleteCancelSubscriptionSurvey(params) {
            return axios.post("/api/v2/cancel-subscription-survey", params);
        },
    },
    getters: {
        surveyType() {
            return this.survey?.survey_type;
        },
        userSurvey(state) {
            return state.survey?.user_survey;
        },
        feedbackSurvey(state) {
            return state.survey?.feedback_survey;
        },
        getCurrentQuestion(state) {
            return state.survey ? state.questions[state.currentStep - 1] : null;
        },
        getNextQuestion(state) {
            const nextStep = this.currentStep + 1;
            return state.survey ? state.questions[nextStep - 1] : null;
        },
        questions() {
            return this.survey ?
                this.survey.questions.filter((question) =>
                    [QUESTION_TYPES.TEXT, QUESTION_TYPES.TEXTAREA].includes(question.question_type) ||
                    question.options.length >= 2
                )
                :
                [];
        }
    },
});
