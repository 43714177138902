<template>
  <TransitionRoot
    as="template"
    :show="show"
  >
    <Dialog
      as="div"
      class="relative z-50"
      :open="show"
      @close="closeMessage"
    >
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div
        v-if="show"
        class="fixed inset-0 z-10 overflow-y-auto"
      >
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative mx-2 overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transform transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 md:mx-0"
            >
              <div>
                <div class="text-center">
                  <DialogTitle
                    as="h3"
                    class="text-lg font-medium leading-6 text-gray-900"
                  >
                    <span
                      style="font-size: 22px;"
                      class="font-semibold text-primary-900"
                    >{{
                      $t('upgrade') }}&nbsp;</span>
                    <span
                      style="font-size: 22px;"
                      class="font-semibold text-warning-1300"
                    >{{
                      $t('to_unlock') }}</span>
                  </DialogTitle>
                  <div class="mt-2">
                    <p class="text-base text-grayCust-500">
                      {{ upgrade_warning.subHeading }}
                    </p>
                  </div>
                </div>
                <div
                  v-if="upgrade_warning.planMessage"
                  class="mt-4 flex flex-wrap items-center justify-center"
                >
                  <button class="flex items-center justify-center rounded-full border border-grayCust-570 px-4 py-1">
                    <img
                      :src="cdn('images/Command/InformationCommand.svg')"
                      alt=""
                      srcset=""
                    >
                    <span class="ml-1 text-sm font-medium text-grayCust-1600">{{ upgrade_warning.planMessage }}</span>
                    <!-- This feature is available in Pro plan and above. -->
                  </button>
                </div>
                <div class="bg-shadow relative mt-6 flex justify-center">
                  <div
                    class="rounded-b-lg"
                    style="box-shadow: 0px 33.7781px 48.7905px rgba(0, 0, 0, 0.08);"
                  >
                    <div class="relative">
                      <img
                        :src="cdn('images/Command/TopBgUpgrade.svg')"
                        alt=""
                        srcset=""
                      >
                      <div class="absolute left-3 top-2.5">
                        <img
                          :src="cdn('images/Command/DotsTopUpgrade.svg')"
                          alt=""
                          srcset=""
                        >
                      </div>
                    </div>
                    <div class="flex items-center rounded-b-lg border border-t-0 border-grayCust-900 bg-white">
                      <div class="relative w-1/2">
                        <img
                          :src="cdn('images/Command/LeftSideDesign.svg')"
                          class="mx-auto"
                          alt=""
                          srcset=""
                        >

                        <div
                          class="absolute left-4 top-24 flex size-24 items-center justify-center rounded-full  border border-grayCust-570 bg-white"
                        >
                          <img
                            :src="cdn('images/Command/UpgradeUnlock.gif')"
                            class="mx-auto size-20"
                            alt=""
                            srcset=""
                          >
                        </div>
                      </div>
                      <div class="relative w-1/2">
                        <img
                          :src="cdn('images/Command/RightSideDesign.svg')"
                          class="mx-auto"
                          alt=""
                          srcset=""
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="upgrade_warning.planMessage"
                class="mt-4 flex flex-wrap items-center justify-center"
              >
                <button
                  class="flex items-center justify-center rounded-full border border-yellow-700 bg-yellow-50 px-4 py-1"
                >
                  <span class="ml-1 text-sm font-medium text-yellow-700">{{
                    $t('you_need_to_be_on_a_paid_plan_to_buy_an_addon') }}</span>
                </button>
              </div>
              <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                <button
                  type="button"
                  class="inline-flex w-full justify-center rounded-md border border-transparent bg-secondary-900 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-secondary-900 focus:outline-none focus:ring-2 focus:ring-secondary-900 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                  @click="navigateToPlansPage"
                >
                  {{ $t('upgrade') }}
                </button>
                <button
                  ref="cancelButtonRef"
                  type="button"
                  class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-secondary-900 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                  @click="closeMessage"
                >
                  {{ $t('cancel') }}
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script>

import { features } from '@/features.js';
import { useAppStore } from '@/store/index';
import { mapState, mapStores } from 'pinia';

export default {

  computed: {
    show() {
      const obj = this.appStore.upgrade_warning;
      return obj && Object.keys(obj).length !== 0 && obj.constructor === Object;
    },
    ...mapStores(useAppStore),
    ...mapState(useAppStore, {
      "upgrade_warning": "upgrade_warning"
    }),
    ...mapState(useAppStore, [
      "userBaseActivePlan"
    ]),
    showAddOnPlanNote() {
      
      if (this.show && this.isAddonOnly) {
        return true
      }
      return false
    },
    isAddonOnly() {
      const feature = features.find(i => i.key == this.appStore.upgrade_warning.feature)
      if (feature && feature.addon_only) {
        return true
      }
      return false
    }
  },
  methods: {
    closeMessage() {
      this.appStore.togglePopupInsideModal(false);
      this.appStore.setUpgradeWarning({});
    },
    navigateToPlansPage() {
      if (this.isAddonOnly) {
        if (this.userBaseActivePlan.name == 'Free') {
          this.$inertia.visit(route('pricing', { trigger_ref: 'upgrade_warning_popup' }))
        } else {
          this.$inertia.visit(route('subscription-plan.customize', { feature: this.appStore.upgrade_warning.feature, trigger_ref: `upgrade_warning_popup_${this.appStore.upgrade_warning.feature}` }))
        }
      } else {
        this.$inertia.visit(route('pricing', { feature: this.appStore.upgrade_warning.feature, trigger_ref: `upgrade_warning_popup_${this.appStore.upgrade_warning.feature}` }))
      }
      this.closeMessage();
    }
  }
}
</script>