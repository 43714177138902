<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_29692_7496)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.2162 12.4889C9.56063 12.4889 6.60808 9.52468 6.60808 5.88086C6.60808 9.52468 3.64382 12.4889 0 12.4889C3.64382 12.4889 6.60808 15.4415 6.60808 19.0853C6.60808 15.4415 9.56063 12.4889 13.2162 12.4889Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.0001 15.8997C17.9965 15.8997 16.3797 14.2711 16.3797 12.2793C16.3797 14.2711 14.7511 15.8997 12.7593 15.8997C14.7511 15.8997 16.3797 17.5166 16.3797 19.5201C16.3797 17.5166 17.9965 15.8997 20.0001 15.8997Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.9143 4.88586C15.4773 4.88586 13.5089 2.91749 13.5089 0.480469C13.5089 2.91749 11.5405 4.88586 9.10352 4.88586C11.5405 4.88586 13.5089 6.85422 13.5089 9.29125C13.5089 6.85422 15.4773 4.88586 17.9143 4.88586Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_29692_7496">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
    name: 'StarsIcon',
};
</script>