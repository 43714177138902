<template>
  <div>
    <div
      :class="{ 'md:flex-col': surveyMode, 'flex-col gap-3 md:flex-row': !surveyMode }"
      class="flex flex-wrap md:items-center md:justify-between"
    >
      <div v-if="isStagingSite">
        <div class="grid grid-cols-2 gap-2 rounded-lg border border-grayCust-280 bg-grayCust-380 p-1 md:grid-cols-4 lg:inline-grid">
          <Tab
            v-for="item in tabList"
            :key="item.id"
            :title="item.title"
            :icon-name="item.iconName"
            :is-checked="selectedTab == item.id"
            @click="onHandleSelectTab(item.id)"
          />
        </div>
      </div>
      <h6
        v-if="isTabTitle"
        class="flex items-center font-semibold text-grayCust-430"
      >
        {{ isCreateSite ? $t('success') : tabTitle }}
      </h6>
      <div>
        <!--                <div class="flex items-center gap-4">
                    <button v-if="isSaveButton" class="text-xs font-semibold text-primary-900 px-2.5 py-1.5 shadow-sm rounded-lg border border-grayCust-180 bg-white focus:ring-2 focus:ring-primary-900 focus:ring-offset-2 hover:bg-grayCust-75">Save</button>
                    <Listbox v-if="isPluginAndThemes" as="div" v-model="selectedWordpressVersion" class="w-full">
                        <div class="relative">
                            <ListboxButton class="relative w-full cursor-pointer rounded-md bg-transparent pr-10 text-left focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm">
                                <span class="flex items-center">
                                    <img :src="cdn(`images/${selectedWordpressVersion.image}`)" alt="" class="h-6 w-6 flex-shrink-0 rounded-full" />
                                    <span class="ml-3 text-base font-normal block text-grayCust-1740 truncate">{{ selectedWordpressVersion.name }}</span>
                                </span>
                                <span class="pointer-events-none absolute inset-y-0 right-3 ml-3 flex items-center pr-2">
                                    <img :src="cdn(`images/down-icon.png`)" alt=""  />
                                </span>
                            </ListboxButton>

                            <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                                <ListboxOptions class="absolute z-40 mt-1 md:min-w-[265px] w-full border border-grayCust-350 rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm md2:right-0 max-h-80 overflow-auto custom-scroll">
                                    <div class="w-full flex items-center justify-between px-3 py-1.5">
                                        <div class="text-xs font-medium text-grayCust-910">
                                            Configuration
                                        </div>
                                        <div class="text-xs font-medium text-primary-900 cursor-pointer">
                                            Manage ->
                                        </div>
                                    </div>
                                    <ListboxOption as="template" v-for="item in selectedWordpressVersionList" :key="item.id" :value="item" v-slot="{ active }">
                                        <li :class="[active ? 'text-primary-900 bg-primary-275' : 'text-gray-900', 'relative cursor-pointer select-none py-2 pl-3 pr-3 flex items-center justify-between']">
                                            <div class="flex items-center">
                                                <img :src="cdn(`images/${item.image}`)" alt="" class="h-6 w-6 flex-shrink-0 rounded-full bg-white" />
                                                <span @click="writeNameClick(item.name)" :class="[active && item.name == 'Write name' ? 'underline' : item.name == 'Write name' ?? 'underline' , 'ml-3 block truncate']">{{ item.name }}</span>
                                            </div>
                                            <span v-if="active">
                                                <CheckIcon class="w-5 h-5 text-primary-900" />
                                            </span>
                                        </li>
                                    </ListboxOption>
                                    <div class="border-t border-grayCust-180 pt-3 mt-3">
                                        <div class="flex items-center px-3 py-2 cursor-pointer">
                                            <PlusIcon class="w-[18px] h-[18px]" />
                                            <span class="text-sm text-grayCust-800 ml-3">Add Configuration</span>
                                        </div>
                                    </div>
                                </ListboxOptions>
                            </transition>
                        </div>
                    </Listbox>
                </div>-->
      </div>
    </div>
    <div class="mt-5 flex items-center gap-2">
      <div
        v-for="(item,index) in totalTabArray"
        :key="item.id"
        :class="currentIndex >= index+1 ? 'bg-primary-900' : 'bg-grayCust-900'"
        class="h-1 w-full rounded-full"
      />
    </div>
  </div>
</template>

<script>
import Dropdown from '@/components/New/Blocks/Dropdowns/Dropdown.vue';
import ListBox from '@/components/New/Blocks/Dropdowns/ListBox/ListBox.vue';
import Tab from '@/components/SiteCreate/Tab.vue';
import {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
} from "@headlessui/vue";
import { PlusIcon } from "@heroicons/vue/outline";
import { CheckIcon, SelectorIcon } from "@heroicons/vue/solid";

export default {
    name: 'SiteNewCommonTab',

    components: {
        Listbox,
        ListboxButton,
        ListboxLabel,
        ListboxOption,
        ListboxOptions,
        CheckIcon,
        SelectorIcon,
        Tab,
        PlusIcon,
        Dropdown,
        ListBox
    },
    props:{
        tabTitle:{
            type: String,
            default: '',
        },
        totalTabArray:{
            type: Array,
            default: [],
        },
        currentIndex:{
            type: Number,
            default: 1,
        },
        isSaveButton:{
            type: Boolean,
            default: false,
        },
        isHostingAccount:{
            type: Boolean,
            default: false,
        },
        isPluginAndThemes:{
            type: Boolean,
            default: false,
        },
        isCreateSite: {
          type: Boolean,
          default: false,
        },
        isStagingSite: {
          type: Boolean,
          default: false,
        },
        isTabTitle: {
          type: Boolean,
          default: true,
        },
        tabList: {
          type: Array,
          default: [],
        },
        surveyMode: {
          type: Boolean,
          default: false,
        },
    },
    data() {
        return {
            selectedTab: 1,
            selectedWordpressVersion: '',
            selectedWordpressVersionList:[
                {
                    id: 1,
                    name: 'Blog Editor',
                    image:'wordpress-logo.svg',
                },
                {
                    id: 2,
                    name: 'Visual Composer',
                    image:'SiteNewDesign/visual-composer.svg',
                },
                {
                    id: 3,
                    name: 'Default',
                    image: 'SiteNewDesign/wordpress-black.svg'
                },
                {
                    id: 4,
                    name: 'Beaver Builder',
                    image: 'SiteNewDesign/builder.png'
                },
                {
                    id: 5,
                    name: 'Write name',
                    image: 'SiteNewDesign/builder.png'
                },
            ],
            statusList: [
                {
                    id: 1,
                    title: 'All'
                },
                {
                    id: 2,
                    title: 'Cloud'
                },
                {
                    id: 3,
                    title: 'Shared'
                },
                {
                    id: 4,
                    title: 'Managed'
                },
                {
                    id: 5,
                    title: 'Control Panels'
                },
            ]
        }
    },
    created() {
      this.selectedTab = this.currentIndex
      this.selectedWordpressVersion = this.selectedWordpressVersionList[0]
      this.selectedStatus = this.statusList[0];
    },
    methods: {
        writeNameClick(name){
            if (name == 'Write name') {

            }
        },
        onHandleSelectTab(id){
            this.selectedTab = id
            this.$emit('onHandleSelectTab', id)
        }
    },
}
</script>

<style></style>
