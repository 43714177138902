<template>
  <div class="relative space-y-1.5">
    <textarea
      :id="id" 
                
      :class="['flex w-full flex-auto items-center rounded-lg border border-grayCust-160 bg-white px-3 py-2 text-base text-grayCust-1740 transition-shadow placeholder:text-grayCust-630 focus:outline-none focus:ring-4 disabled:cursor-not-allowed disabled:bg-grayCust-50', 
               errorMessage ? 'focus:border-redCust-240 focus:ring-redCust-195' : 'focus:border-secondary-800 focus:ring-primary-275']" 
      :value="modelValue"
      :name="name"
      :disabled="isDisabled"
      :placeholder="placeholder"
      :rows="rows" 
      :cols="cols"
      @input="$emit('update:modelValue', $event.target.value)"
      @focus="hintShow=!hintShow"
      @blur="hintShow=!hintShow"
    />

    <transition
      leave-active-class="transition ease-in duration-100"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <span
        v-if="!errorMessage && hintShow && isHintText"
        class="absolute left-0 text-sm text-gray-400"
      >{{ hintText }}</span>
    </transition>
    <transition
      leave-active-class="transition ease-in duration-100"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <span
        v-if="errorMessage"
        class="absolute left-0 text-sm text-redCust-430"
      >{{ errorMessage }}</span>
    </transition>
  </div>
</template>
<script>

import Pill from '@/components/New/Blocks/Pills/Pill.vue';

export default {
    components: {
      Pill
    },
    
    props: {
        modelValue: {
          type: String,
          required: false
        },
        placeholder: {
          type: String,
          required: false,
          default: ''
        },
        name: {
          type: String,
          required: false,
          default: ''
        },
        id: {
          type: [Number, String],
          required: false,
          default: ''
        },
        isDisabled: {
          type: Boolean,
          required: false,
          default: false,
        },
        errorMessage: {
          type: String,
          required: false,
          default: null,
        },
        isHintText: {
          type: Boolean,
          required: false,
          default: false,
        },
        rows: {
          type: Number,
          required: false,
          default: '4',
        },
        cols: {
          type: Number,
          required: false,
          default: '',
        },
        hintText: {
            type: String,
            required: false,
            default: '',
        }
    },
    emits: ['update:modelValue'],
    data() {
      return {
        textareaStyle: null,
        tags: [],
        hintShow: false,
      };
    },
    methods: {
      focus() {
        this.$refs.textarea.focus()
      },
    },
};
</script>