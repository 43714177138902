<template>
  <div
    :class="currentStep !== 1 && !surveyCompleted ? 'justify-between' : 'justify-end'"
    class="bg-grayCust-50 px-6 py-5 flex items-center border-t border-grayCust-180"
  >
    <div v-if="currentStep !== 1 && !surveyCompleted">
      <button
        class="px-4 py-2 sm2:px-5 sm2:py-2.5 text-[10px] sm2:text-sm font-semibold text-grayCust-980 rounded-lg border border-grayCust-160 bg-white shadow-sm hover:bg-grayCust-75 focus:ring-2 focus:ring-offset-2 focus:ring-secondary-900"
        @click="backClick"
      >
        Back
      </button>
    </div>
    <div v-if="!surveyCompleted">
      <div
        v-if="!selectedLastTab"
        class="flex items-center gap-3"
      >
        <button
          v-if="currentStep === 1"
          class="px-4 py-2 sm2:px-5 sm2:py-2.5 text-[10px] sm2:text-sm font-semibold text-grayCust-980 rounded-lg border border-grayCust-160 bg-white shadow-sm hover:bg-grayCust-75 focus:ring-2 focus:ring-offset-2 focus:ring-secondary-900"
          :class="{ 'cursor-not-allowed disabled:opacity-50': isSubmitting }"
          :disabled="isSubmitting"
          @click="pauseSurvey"
        >
          Do it later
        </button>
        <button
          v-if="currentStep !== 1"
          class="px-4 py-2 sm2:px-5 sm2:py-2.5 text-[10px] sm2:text-sm font-semibold text-grayCust-980 rounded-lg border border-grayCust-160 bg-white shadow-sm hover:bg-grayCust-75 focus:ring-2 focus:ring-offset-2 focus:ring-secondary-900"
          :class="{ 'cursor-not-allowed disabled:opacity-50': isSubmitting }"
          :disabled="isSubmitting"
          @click="nextStep"
        >
          Skip
        </button>
        <button
          v-if="!hideSubmit"
          class="px-4 py-2 sm2:px-5 sm2:py-2.5 bg-secondary-800 text-[10px] sm2:text-sm font-semibold text-white rounded-lg focus:ring-2 focus:ring-offset-2 focus:ring-secondary-900"
          :class="{ 'cursor-not-allowed disabled:opacity-50': isSubmitting || disableSubmit }"
          :disabled="isSubmitting || disableSubmit"
          @click="saveAndContinue"
        >
          Continue
        </button>
      </div>
      <div class="flex items-center gap-3">
        <button
          v-if="selectedLastTab && !hideSubmit"
          class="px-4 py-2 sm2:px-5 sm2:py-2.5 bg-secondary-800 text-[10px] sm2:text-sm font-semibold text-white rounded-lg focus:ring-2 focus:ring-offset-2 focus:ring-secondary-900"
          :class="{ 'cursor-not-allowed disabled:opacity-50': isSubmitting || disableSubmit }"
          :disabled="isSubmitting || disableSubmit"
          @click="saveAndContinue"
        >
          Submit
        </button>
      </div>
    </div>
    <div
      v-else
      class="flex items-center gap-3"
    >
      <button
        class="flex items-center gap-2 px-4 py-2 sm2:px-5 sm2:py-2.5 text-[10px] sm2:text-sm font-semibold text-grayCust-980 rounded-lg border border-grayCust-160 bg-white shadow-sm hover:bg-grayCust-75 focus:ring-2 focus:ring-offset-2 focus:ring-secondary-900"
        @click="viewCredits"
      >
        <CurrencyDollarIcon class="h-5 w-5" />
        <span> View Credits</span>
      </button>
      <button
        class="px-4 py-2 sm2:px-5 sm2:py-2.5 bg-secondary-800 text-[10px] sm2:text-sm font-semibold text-white rounded-lg focus:ring-2 focus:ring-offset-2 focus:ring-secondary-900"
        @click="closeModal"
      >
        Done
      </button>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { CurrencyDollarIcon } from "@heroicons/vue/outline";
import { useSurveyStore } from '@/store/survey';
import { mapState } from 'pinia';
import { QUESTION_TYPES } from "../../store/survey";

export default {
    name: 'Footer',
    components:{
        CurrencyDollarIcon
    },
    setup() {
        const surveyStore = useSurveyStore()

        // Local ref to store count for the template
        const hideSubmit = ref(surveyStore.getCurrentQuestion.question_type === QUESTION_TYPES.RADIO);
        const disableSubmit = ref(true);

        // Watch the store state and react to changes
        watch(
            () => surveyStore.getCurrentQuestion,
            (newQuestion) => {
                const isOtherOption = newQuestion.options.find(option => option.id === surveyStore.selectedOptionId && option.title.match(/^(other|Other|others|Others|OTHER|OTHERS)$/));
                hideSubmit.value = newQuestion.question_type === QUESTION_TYPES.RADIO && !isOtherOption;
                if (newQuestion.question_type === QUESTION_TYPES.TEXT || newQuestion.question_type === QUESTION_TYPES.TEXTAREA) {
                    disableSubmit.value = !surveyStore.responseText;
                }
            }
        )

        watch(
            () => surveyStore.responseText,
            (newText) => {
                disableSubmit.value = !newText
            }
        )

        watch(
            () => surveyStore.selectedOptionId,
            (newId) => {
                const isOtherOption = surveyStore.getCurrentQuestion.options.find(option => option.id === newId && option.title.match(/^(other|Other|others|Others|OTHER|OTHERS)$/));
                if (isOtherOption) {
                    hideSubmit.value = false;
                    return;
                }
            }
        )

        watch(
            () => surveyStore.selectedOptionIds,
            (newIds) => {
                if (surveyStore.getCurrentQuestion.question_type === QUESTION_TYPES.CHECKBOX) {
                    disableSubmit.value = !newIds.length;
                }
            }
        )

        return {
            disableSubmit,
            hideSubmit
        }
    },
    computed: {
        ...mapState(useSurveyStore, ['isSubmitting']),
    },
    methods: {
        saveAndContinue() {
            this.$emit('saveAndContinue');
        },
        backClick() {
            this.$emit('backClick');
        },
        nextStep() {
            this.$emit('nextStep');
        },
        pauseSurvey() {
            this.$emit('pauseSurvey');
        },
        closeModal() {
            this.$emit('closeModal');
        },
        viewCredits() {
            this.closeModal();
            this.$inertia.visit('/credits');
        }
    },
    props: {
        selectedLastTab: {
            type: Boolean,
            required: false,
        },
        currentStep: {
            type: Number,
            required: false,
        },
        surveyCompleted: {
            type: Boolean,
            default: false,
        },
        totalSteps: {
            type: Number,
            required: false,
        }
    },
}
</script>
