<template>
  <DialogModal
    :show="show"
    max-width="xl"
    footer-color="bg-white"
    extra-flex="flex justify-center modal-custom-height"
    blur-class="opacity-class"
    header-class="modal-padding"
    main-class="content-padding"
    extra-class="upgrade-user-modal !rounded-lg relative upgrade-plan-modal"
    @close="close"
  >
    <template #content>
      <div class="custom-modal-height">
        <div class="flex flex-wrap lg:flex-nowrap">
          <div class="left-container">
            <Plan 
              v-if="show"
              :show="show"
              :feature-list="featureList"
              @on-plan-selection="onPlanSelection"
              @close="close"
            />
          </div>
          <div class="right-container p-6 pt-5">
            <FeaturesPlan
              :pro-plan-image="proPlanImage"
              :feature-list="featureList"
              :plan="selectedPlan"
              @close="close"
            />
          </div>
        </div>
      </div>
    </template>
  </DialogModal>
</template>
 
<script>
import FeaturesPlan from "@/Common/UpgradeUserModal/FeaturesPlan.vue";
import Plan from "@/components/UpgradeUserModal/FreeUser/Plan.vue";
import { features } from '@/features.js';
import JetConfirmationModal from '@/Jetstream/ConfirmationModal.vue';
import DialogModal from "@/Jetstream/DialogModal.vue";
import JetPrimaryButton from '@/Jetstream/PrimaryButton.vue';
import JetSecondaryButton from '@/Jetstream/SecondaryButton.vue';
import { useAppStore } from '@/store/index';
import { mapState, mapStores } from 'pinia';
export default {
    
    name: "UpgradeFreeToPaidUserModal",
    components: {
        DialogModal,
        Plan,
        FeaturesPlan,
        JetConfirmationModal,
        JetPrimaryButton,
        JetSecondaryButton
    },
    data() {
        return {
            selectedPlan: null,
            proPlanImage: true,
        }
    },
    computed: {
        show() {
            const obj = this.appStore.upgradeFreeToPaid;
            return obj && Object.keys(obj).length !== 0 && obj.constructor === Object;
        },
        ...mapStores(useAppStore),
        ...mapState(useAppStore, ["userBaseActivePlan", "plans"]),
        featureList() {
            const list = (features.filter(i => i.essential))
            const plan = this.selectedPlan
            list.push({
                id: 9999,
                upgradeModalText: '50 more services',
                icon: 'ServiceIcon'
            })
            if (plan && plan.description) {
                list.forEach(i => {
                    if (i.upgradeModalTextValues) {
                        i.upgradeModalTextValues.number = plan.description[i.key]
                    }
                });
            }
            return list
        },
    },
    methods: {
        close() {
            this.appStore.setUpgradeFreeToPaid({});
        },
        onPlanSelection(plan) {
            this.selectedPlan = plan
        },
    },
};
</script>
<style scoped>
:deep(.btn-height) {
  height: 91vh;
}

@media screen and (max-width: 991px) {
  :deep(.btn-height) {
    height: 100%;
  }
}
</style>