<template>
  <div>
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3701 4.32C10.3701 3.80746 10.3701 3.55119 10.4538 3.34905C10.5655 3.07952 10.7796 2.86537 11.0492 2.75373C11.2513 2.67 11.5076 2.67 12.0201 2.67C12.5327 2.67 12.7889 2.67 12.9911 2.75373C13.2606 2.86537 13.4747 3.07952 13.5864 3.34905C13.6701 3.55119 13.6701 3.80746 13.6701 4.32V6.52C13.6701 7.03253 13.6701 7.2888 13.5864 7.49095C13.4747 7.76048 13.2606 7.97462 12.9911 8.08627C12.7889 8.17 12.5327 8.17 12.0201 8.17C11.5076 8.17 11.2513 8.17 11.0492 8.08627C10.7796 7.97462 10.5655 7.76048 10.4538 7.49095C10.3701 7.2888 10.3701 7.03253 10.3701 6.52V4.32Z"
        stroke="currentColor"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.3701 12.02C10.3701 11.5075 10.3701 11.2512 10.4538 11.049C10.5655 10.7795 10.7796 10.5654 11.0492 10.4537C11.2513 10.37 11.5076 10.37 12.0201 10.37C12.5327 10.37 12.7889 10.37 12.9911 10.4537C13.2606 10.5654 13.4747 10.7795 13.5864 11.049C13.6701 11.2512 13.6701 11.5075 13.6701 12.02C13.6701 12.5325 13.6701 12.7888 13.5864 12.9909C13.4747 13.2605 13.2606 13.4746 12.9911 13.5863C12.7889 13.67 12.5327 13.67 12.0201 13.67C11.5076 13.67 11.2513 13.67 11.0492 13.5863C10.7796 13.4746 10.5655 13.2605 10.4538 12.9909C10.3701 12.7888 10.3701 12.5325 10.3701 12.02Z"
        stroke="currentColor"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.66992 10.37C2.66992 9.33291 2.66992 8.81437 2.9921 8.49218C3.31429 8.17 3.83283 8.17 4.86992 8.17H5.96992C7.00701 8.17 7.52556 8.17 7.84774 8.49218C8.16992 8.81437 8.16992 9.33291 8.16992 10.37V11.47C8.16992 12.5071 8.16992 13.0256 7.84774 13.3478C7.52556 13.67 7.00701 13.67 5.96992 13.67H4.86992C3.83283 13.67 3.31429 13.67 2.9921 13.3478C2.66992 13.0256 2.66992 12.5071 2.66992 11.47V10.37Z"
        stroke="currentColor"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.66992 4.32C2.66992 3.80746 2.66992 3.55119 2.75365 3.34905C2.8653 3.07952 3.07944 2.86537 3.34897 2.75373C3.55112 2.67 3.80739 2.67 4.31992 2.67H6.51992C7.03246 2.67 7.28873 2.67 7.49087 2.75373C7.7604 2.86537 7.97455 3.07952 8.08619 3.34905C8.16992 3.55119 8.16992 3.80746 8.16992 4.32C8.16992 4.83253 8.16992 5.0888 8.08619 5.29095C7.97455 5.56048 7.7604 5.77462 7.49087 5.88627C7.28873 5.97 7.03246 5.97 6.51992 5.97H4.31992C3.80739 5.97 3.55112 5.97 3.34897 5.88627C3.07944 5.77462 2.8653 5.56048 2.75365 5.29095C2.66992 5.0888 2.66992 4.83253 2.66992 4.32Z"
        stroke="currentColor"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "TemplateIcon",

}
</script>

<style></style>