export default {
  mounted(el, binding) {
    const tooltipText = binding.value?.text || binding.value; // Handle both object and string values
    const position = binding.value?.position || 'top'; // Default position to 'top'

    if (!tooltipText) return;

    // Create the tooltip container
    const tooltip = document.createElement('div');
    tooltip.className = 'custom-tooltip';
    tooltip.innerText = tooltipText;

    // Create the triangular pointer
    const tooltipArrow = document.createElement('div');
    tooltipArrow.className = 'custom-tooltip-arrow';

    // Append arrow to tooltip
    tooltip.appendChild(tooltipArrow);

    // Style the tooltip
    Object.assign(tooltip.style, {
      position: 'absolute',
      backgroundColor: 'black',
      color: 'white',
      padding: '6px 10px',
      borderRadius: '6px',
      fontSize: '12px',
      pointerEvents: 'none',
      opacity: '0',
      transition: 'opacity 0.2s',
      maxWidth: '250px',
      width: 'auto',
      // whiteSpace: 'normal',
      wordBreak: 'break-all',
      fontWeight: '600',
      zIndex: '99999999',

    });

    // Style the arrow
    Object.assign(tooltipArrow.style, {
      position: 'absolute',
    });

    document.body.appendChild(tooltip);

    const showTooltip = (event) => {
      const { top, left, width, height } = el.getBoundingClientRect();

      // Default to top
      let tooltipTop = top - tooltip.offsetHeight - 10;
      let tooltipLeft = left + width / 2 - tooltip.offsetWidth / 2;

      if (position === 'left') {
        tooltipTop = top + height / 2 - tooltip.offsetHeight / 2;
        tooltipLeft = left - tooltip.offsetWidth - 10;
        Object.assign(tooltipArrow.style, {
          top: '50%',
          left: '100%',
          transform: 'translateY(-50%)',
          borderLeft: '5px solid black',
          borderTop: '5px solid transparent',
          borderBottom: '5px solid transparent',
          borderRight: 'none',
        });
      } else {
        // Default Top Arrow
        Object.assign(tooltipArrow.style, {
          bottom: '-5px',
          left: '50%',
          transform: 'translateX(-50%)',
          width: '0',
          height: '0',
          borderLeft: '5px solid transparent',
          borderRight: '5px solid transparent',
          borderTop: '5px solid black',
        });
      }

      tooltip.style.top = `${tooltipTop}px`;
      tooltip.style.left = `${tooltipLeft}px`;
      tooltip.style.opacity = '1';
    };

    const hideTooltip = () => {
      tooltip.style.opacity = '0';
    };

    // Add event listeners
    el.addEventListener('mouseenter', showTooltip);
    el.addEventListener('mouseleave', hideTooltip);

    // Cleanup on unmount
    el._tooltipInstance = { tooltip, showTooltip, hideTooltip };
  },

  unmounted(el) {
    const { tooltip, showTooltip, hideTooltip } = el._tooltipInstance || {};
    if (tooltip) {
      tooltip.remove();
    }
    el.removeEventListener('mouseenter', showTooltip);
    el.removeEventListener('mouseleave', hideTooltip);
  },
};
