<template>
  <div>
    <div v-if="isGeneralSurveyModalOpened">
      <SurveyModal
        :is-survey-modal-opened="isGeneralSurveyModalOpened"
        @close-modal="closeGeneralSurveyModal"
      />
    </div>
    <div v-if="isFeedbackSurveyModalOpened">
      <FeedbackModal
        :is-open="isFeedbackSurveyModalOpened"
        @close-modal="closeFeedbackSurveyModal"
      />
    </div>
    <UpgradeWarningMessage v-if="!ui.popup_inside_modal" />
    <UpgradeFreeToPaidModal />
    <PaymentSuccessful />
    <!-- <AppNotifications /> -->
    <ModalMessage />
    <HostingManagerModal
      :is-modal-open="isModalOpen"
      @close_hosting_modal="toggleHostingerModal"
      @close_hosting_modal_outside="isModalOpen = false"
    />
  </div>
</template>
<script>
import FeedbackModal from "@/Common/FeedbackModal/Index.vue";
import ModalMessage from '@/Common/ModalMessage.vue';
import SurveyModal from "@/Common/SurveyModal/Index.vue";
import UpgradeWarningMessage from '@/Common/UpgradeWarningMessage.vue';
import HostingManagerModal from '@/components/UpdatedDesignVersion/HostingManager/HostingManagerModal.vue';
import UpgradeFreeToPaidModal from '@/components/UpgradeUserModal/FreeUser/Index.vue';
import PaymentSuccessful from '@/components/UpgradeUserModal/PaymentSuccessful.vue';
import { useAppStore } from '@/store/index';
import moment from 'moment';
import { mapState, mapStores } from 'pinia';
export default {
  components: {
    UpgradeWarningMessage,
    HostingManagerModal,
    UpgradeFreeToPaidModal,
    PaymentSuccessful,
    SurveyModal,
    FeedbackModal,
    ModalMessage
  },
  data() {
    return {
      isGeneralSurveyModalOpened: false,
      isFeedbackSurveyModalOpened: false,
      isModalOpen: false,
    }
  },
  computed: {
        ...mapStores(useAppStore),
        ...mapState(useAppStore, ["notification", "warning", "ui", "domain_influence_message",
            "user",
            "teamCan",
            "teamUsed",
            "teamAllow",
            "shouldCouponApply",
            "teamOwnerActivePlan",
            "getBlogs",
            "getNewVersion",
            "upgradeFreeToPaid",
        ]),
    },
  methods:{
    toggleHostingerModal() {
        this.isModalOpen = !this.isModalOpen;
    },
    async startGeneralSurveyScheduler(options = {}) {
      let surveys = this.appStore.generalSurveys.map(survey => {
        return {
          ...survey,
          start_at: moment.tz(survey.start_at, 'UTC').local()
        };
      }).sort((a, b) => a.local_start_at - b.local_start_at)

      if (surveys.length === 0) return;

      const now = moment();

      let nextSurvey = null;

      if (options.surveyId) {
        nextSurvey = surveys.find(survey => survey.id == Number(options.surveyId));
        if (nextSurvey) {
          this.startOrScheduleSurvey(nextSurvey, now, true);
          return;
        }
      }

      // Filter out only active surveys
      surveys = surveys.filter(survey => survey.status === 'active');

      for (const survey of surveys) {
        const pausedUntil = survey.user_survey?.paused_until ? moment.tz(survey.user_survey.paused_until, 'UTC').local() : null;

        // Check for any paused incomplete survey. If there're any paused incompleted survey, skip to the next survey
        if (pausedUntil && pausedUntil.isAfter(now) && !survey.user_survey?.completed) {
          continue;
        }

        if (pausedUntil && pausedUntil.isBefore(now) && !survey.user_survey?.completed) {
          await this.resetSurveyPause(survey);
        }

        // If survey.trigger_type is minimum_2_live_sites and user_live_sites_count is less than 2, skip to the next survey
        if (survey.trigger_type === 'minimum_2_live_sites' && this.appStore.user_live_sites_count < 2) {
          continue;
        }

        // If no paused incomplete survey found, check for any past incomplete survey
        if (survey.start_at.isBefore(now) && !survey.user_survey?.completed) {
          nextSurvey = survey;
          break;
        }

        // If no past incomplete survey found, check for any survey starting today or in the future
        if (survey.start_at.isSameOrAfter(now)) {
          nextSurvey = survey;
          break;
        }
      }

      if (nextSurvey) {
        this.startOrScheduleSurvey(nextSurvey, now);
      }
    },
    async startFeedbackSurveyScheduler(options = {}) {
      let surveys = this.appStore.feedbackSurveys;

      let nextSurvey = null;

      if (options.surveyId) {
        nextSurvey = surveys.find(survey => survey.id == Number(options.surveyId));
        if (nextSurvey) {
          this.startFeedbackSurvey(nextSurvey);
          return;
        }
      }

      // Filter out only active surveys
      surveys = surveys.filter(survey => survey.status === 'active');

      // If feedback survey is disabled for current user, do nothing
      if (!this.appStore.feedbackSurveyEnabled) {
        return;
      }

      const now = moment();

      for (const survey of surveys) {
        const pausedUntil = survey.feedback_survey?.paused_until ? moment.tz(survey.feedback_survey.paused_until, 'UTC').local() : null;

        // Check for any paused incomplete survey. If there're any paused incompleted survey, skip to the next survey
        if (!options.skipPauseSurveys && pausedUntil && pausedUntil.isAfter(now) && !survey.feedback_survey?.completed) {
          continue;
        }

        if (!options.skipPauseSurveys && pausedUntil && pausedUntil.isBefore(now) && !survey.feedback_survey?.completed) {
          await this.resetSurveyPause(survey);
        }

        // If no paused incomplete survey found and the survey is not completed, start the survey
        if (!survey.feedback_survey?.completed) {
          nextSurvey = survey;
          break;
        }
      }

      if (nextSurvey) {
        this.startFeedbackSurvey(nextSurvey);
      }
    },
    async startOrScheduleSurvey(survey, now, skipScheduler = false) {
      if (!survey) return;

      // Function to start the survey
      const startSurvey = async (newSurvey, surveyStore, appStore) => {
        await surveyStore.updateSurvey(newSurvey);

        if (!newSurvey.user_survey) {
          const requestResponse = await surveyStore.runStartSurveyApi(newSurvey.id);
          newSurvey.user_survey = requestResponse.data.data;
          await surveyStore.updateSurvey(newSurvey);
          await appStore.updateGeneralSurveys(newSurvey);
        }

        this.isGeneralSurveyModalOpened = true;
      };

      // Function to schedule an operation at a future datetime
      function scheduleOperation(surveyStartAt, callback, skipScheduler) {
        let currentDatetime = moment();
        let delay = surveyStartAt.diff(currentDatetime);

        function checkAndRun() {
          currentDatetime = moment();
          delay = surveyStartAt.diff(currentDatetime);

          if (delay <= 0) {
            callback();
          } else {
            const nextDelay = Math.min(delay, MAX_DELAY);
            setTimeout(checkAndRun, nextDelay);
          }
        }

        if (process.env.NODE_ENV === 'development') {
          let delayTimer = delay;
          const timer = setInterval(() => {
            if (delayTimer > 0) {
              delayTimer -= 1000;
              const formattedTime = moment.utc(delayTimer).format('HH:mm:ss');
            } else {
              clearInterval(timer);
            }
          }, 1000);
        }

        if (!skipScheduler && delay > 0) {
          const initialDelay = Math.min(delay, MAX_DELAY);
          setTimeout(checkAndRun, initialDelay);
        } else {
          callback();
        }
      }

      // setTimeout has a maximum delay of 2147483647 milliseconds (24.8 days). If the delay is greater than this, we need to split it into multiple setTimeout calls
      const MAX_DELAY = 2147483647; // Maximum delay for setTimeout in milliseconds

      const surveyStartAt = survey.start_at;
      const delay = surveyStartAt.diff(now);
      const newSurvey = { ...survey, start_at: survey.start_at.toISOString() };

      scheduleOperation(surveyStartAt, () => startSurvey(newSurvey, this.surveyStore, this.appStore), skipScheduler);
    },
    async startFeedbackSurvey(survey) {
      if (!survey) return;

      const newSurvey = { ...survey };
      await this.surveyStore.updateSurvey(newSurvey);

      if (!newSurvey.feedback_survey) {
        const requestResponse = await this.surveyStore.runStartSurveyApi(newSurvey.id);
        newSurvey.feedback_survey = requestResponse.data.data;
        await this.surveyStore.updateSurvey(newSurvey);
        await this.appStore.updateFeedbackSurveys(newSurvey);
      }

      this.isFeedbackSurveyModalOpened = true;
    },
    async resetSurveyPause(survey) {
      if (!survey || !survey.start_at) return;

      const newSurvey = { ...survey, start_at: survey.start_at.toISOString() };

      await this.surveyStore.updateSurvey(newSurvey);

      if (newSurvey.user_survey) {
        const requestResponse = await this.surveyStore.runResetSurveyPauseApi(newSurvey.user_survey.id);
        newSurvey.user_survey = requestResponse.data.data;
        await this.surveyStore.updateSurvey(newSurvey);
        await this.appStore.updateGeneralSurveys(newSurvey);
      }

      if (newSurvey.feedback_survey) {
        const requestResponse = await this.surveyStore.runResetSurveyPauseApi(newSurvey.feedback_survey.id);
        newSurvey.feedback_survey = requestResponse.data.data;
        await this.surveyStore.updateSurvey(newSurvey);
        await this.appStore.updateFeedbackSurveys(newSurvey);
      }
    },
    closeGeneralSurveyModal() {
      this.isGeneralSurveyModalOpened = false
    },
    closeFeedbackSurveyModal() {
      this.isFeedbackSurveyModalOpened = false;
      this.surveyStore.triggerFeedbackSurveyManually(false);
    },
  }
}
</script>