<template>
  <CPopover>
    <template #popoverButton>
      <div class="flex items-center">
        <button
          class="flex items-center justify-between px-3 py-[5px] text-sm font-medium text-grayCust-70 rounded-l-md border border-white/20 border-r-0">
          <span>{{ buttonText }}</span>
        </button>
        <div class="flex items-center justify-center border border-white/20 h-8 px-2 rounded-r-md">
          <OutlineChevronDownIcon class="w-4 h-4 transition-transform duration-200 text-white cursor-pointer" />
        </div>
      </div>
    </template>
    <template #popoverOptions>
      <div v-for="(item, index) in items" :key="index"
        class="flex items-center px-4 py-2 gap-3 hover:bg-grayCust-100 cursor-pointer" @click="selectItem(item)">
        <component :is="item.iconName" v-if="item.iconName" class="w-4 h-4 text-grayCust-550" />
        <a href="#" class="text-sm text-grayCust-640">
          {{ item.name }}
        </a>
      </div>
    </template>
  </CPopover>
</template>

<script>
import CPopover from '@/app/Common/CommonPopover.vue';
import Branch from '@/app/Icons/Branch.vue';
import SiteMapIcon from '@/app/Icons/SiteMapIcon.vue';
import TemplateIcon from '@/app/Icons/TemplateIcon.vue';

export default {
  name: "DropdownList",
  components: {
    Branch,
    SiteMapIcon,
    TemplateIcon,
    CPopover
  },
  props: {
    buttonText: {
      type: String,
      default: "Dropdown",
    },
    iconName: {
      type: String,
      default: "OutlineChevronDownIcon",
    },
    items: {
      type: Array,
      required: true,
    },
  },
  emits: ["select"],
  data() {
    return {
      isOpen: false,
    };
  },
  mounted() {
    document.addEventListener("click", this.handleClickInside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickInside);
  },
  methods: {
    toggleDropdown(event) {
      this.isOpen = !this.isOpen;
      event.stopPropagation();
    },
    closeDropdown() {
      this.isOpen = false;
    },
    selectItem(item) {
      this.$emit("select", item);
      this.closeDropdown();
    },
    handleClickInside(event) {
      if (this.$refs.dropdownMenu && this.$refs.dropdownMenu.contains(event.target)) return;
      this.closeDropdown();
    },
    beforeEnter(el) {
      el.style.opacity = 0;
      el.style.transform = 'translateY(-10px)';
    },
    enter(el, done) {
      el.offsetHeight;
      el.style.transition = 'opacity 0.3s, transform 0.3s';
      el.style.opacity = 1;
      el.style.transform = 'translateY(0)';
      done();
    },
    leave(el, done) {
      el.style.transition = 'opacity 0.3s, transform 0.3s';
      el.style.opacity = 0;
      el.style.transform = 'translateY(-10px)';
      done();
    },
  }
};
</script>

<style scoped>
.rotate-180 {
  transform: rotate(180deg);
}

.dropdown-transition-enter-active,
.dropdown-transition-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.dropdown-transition-enter,
.dropdown-transition-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
</style>
