<template>
  <DialogModal
    :show="isOpen"
    main-class="modal-margin"
    max-width="xl"
    extra-flex="site-model flex justify-center items-center"
    header-class="modal-padding"
    extra-class="md:min-w-[560px] min-w-full !rounded-2xl"
    @close="onHandleClose"
  >
    <template #content>
      <div class="box relative z-10 p-6">
        <div class="absolute bottom-0 left-24 -z-10 h-[150px] w-[300px] bg-[#eefdefc0] blur-[20px]" />

        <div class="space-y-6">
          <div>
            <div class="flex items-end gap-1.5">
              <img
                :src="cdn('images/instawp-logo.png')"
                alt="logo"
                class="w-10"
              >
              <span class="text-[20px] font-semibold text-grayCust-430">{{ modalTitle }}</span>
            </div>
            <site-new-common-tab
              :tab-list="tabList"
              :total-tab-array="tabList"
              :is-tab-title="false"
              :current-index="selectedTab"
            />
          </div>
          <div class="text-center">
            <span class="text-sm text-grayCust-910">{{ description }}</span>
          </div>
          <div v-if="selectedTab === 1">
            <feedback-box />
          </div>
          <div v-else-if="selectedTab === 2">
            <feedback-form />
          </div>
          <feedback-footer
            @pause-survey="pauseSurvey"
            @submit-feedback="handleFeedbackSubmit"
            @close-modal="closeModal"
          />
        </div>
      </div>
    </template>
  </DialogModal>
</template>

<script>
import DialogModal from "@/Jetstream/DialogModal.vue";

import SiteNewCommonTab from '@/components/SiteNewDesign/SiteNewCommonTab.vue';
import TemplateIcon from '@/Common/Icons/NavMenu/TemplateIcon.vue';
import { ExternalLinkIcon } from "@heroicons/vue/outline";
import FeedbackFooter from "@/Common/FeedbackModal/Footer.vue";
import FeedbackBox from "@/Common/FeedbackModal/Box.vue";
import FeedbackForm from "@/Common/FeedbackModal/Form.vue";
import { useAppStore } from '@/store/index';
import { useSurveyStore } from '@/store/survey';
import { mapState, mapStores } from 'pinia';

const TRUST_PILOT_URL = 'https://www.trustpilot.com/evaluate/instawp.com';

export default {
    name: "FeedbackModal",
    components: {
        DialogModal,
        SiteNewCommonTab,
        TemplateIcon,
        ExternalLinkIcon,
        FeedbackFooter,
        FeedbackBox,
        FeedbackForm
    },
    
    props: {
        isOpen: {
            type: Boolean,
            required: false,
        }
    },
    data() {
        return {
            selectedTab: 1,
            tabList: ["", ""],
        };
    },
    computed: {
        ...mapStores(useSurveyStore, useAppStore),
        ...mapState(useSurveyStore, ["survey", "responseText", "feedbackRating", "shareReviewToTP"]),
        modalTitle() {
            return this.surveyStore.survey.title;
        },
        description() {
            return this.surveyStore.survey.description;
        },
        feedbackSurvey() {
            return this.surveyStore.feedbackSurvey;
        },
    },
    methods: {
        onHandleClose() {
            this.selectedTab = 1,
            this.$emit('closeModal');
        },
        closeModal(){
            this.selectedTab = 1,
            this.$emit('closeModal');
        },
        redirectToTrustPilot(){
            window.open(TRUST_PILOT_URL, '_blank');
        },
        async pauseSurvey() {
            await this.surveyStore.runPauseSurveyApi(this.feedbackSurvey.id);
            this.$emit('closeModal');
        },
        async handleFeedbackSubmit() {
            try {
                await this.surveyStore.runSaveSurveyResponseApi(
                    this.feedbackSurvey.id,
                    {
                        rating: this.feedbackRating,
                        feedback_text: this.responseText,
                    });

                if (this.selectedTab === 1) {
                    this.selectedTab = 2;
                } else {
                    const feedbackSurveyResponse = await this.surveyStore.runCompleteSurveyApi(this.feedbackSurvey.id);

                    if (this.feedbackRating >= 4 && this.shareReviewToTP) {
                        await this.copyTextToClipboard(this.responseText);
                        this.redirectToTrustPilot();
                    }

                    this.appStore.updateFeedbackSurveys(feedbackSurveyResponse.data.data);
                    this.surveyStore.reset();
                    this.closeModal();
                }
            } catch (error) {
                console.error("Error while saving text survey response.", error);
            }
        },
        async copyTextToClipboard(text) {
            if (!navigator.clipboard) {
                this.fallbackCopyTextToClipboard(text);
                return;
            }

            await navigator.clipboard.writeText(text)
        },
        fallbackCopyTextToClipboard(text) {
            const textarea = document.createElement('textarea');
            textarea.value = text;
            // Avoid scrolling to bottom
            textarea.style.top = "0";
            textarea.style.left = "0";
            textarea.style.position = 'fixed';

            document.body.appendChild(textarea);
            textarea.focus();
            textarea.select();

            try {
                document.execCommand('copy');
            } catch (err) {
                console.error('Oops, unable to copy', err);
            }

            document.body.removeChild(textarea);
        },
    },
}
</script>

<style scoped>
.box{
    background: linear-gradient(200deg, #d8ebf6,#f4fcf5,#F5FCF7,#D3E0F4);
}
@media (max-height: 600px) {
    :deep(.site-model) {
        align-items: start !important;
    }
}
</style>
