<template>
  <div class="space-y-4 p-3 md:p-6 lg:h-[450px]">
    <div>
      <h6
        class="flex cursor-pointer items-center gap-1"
        @click="onHandelBack"
      >
        <img
          :src="cdn('images/PricingPage/back-Icon.svg')"
          class="size-3.5"
          alt=""
        >
        <span class="text-sm text-secondary-800">{{ $t('go_back_txt') }}</span>
      </h6>
    </div>
    <div class="relative">
      <div class="custom-scroll flex max-h-[19rem] flex-col gap-4 overflow-auto pb-4 pr-2">
        <button
          v-for="item in appStore.offers"
          :key="item.id"
          :class="selectedOffer && selectedOffer.id == item.id ? 'border-primary-260' : 'border-grayCust-770'"
          class="flex w-full cursor-pointer flex-wrap items-center justify-between gap-3 rounded-lg border px-3 py-2"
          @click="selectedOffer = item"
        >
          <div class="flex items-center gap-3">
            <input
              type="checkbox"
              :checked="selectedOffer && selectedOffer.id == item.id ? true : false"
              class="block cursor-pointer rounded border border-grayCust-350 text-primary-900 focus:outline-none focus:ring-2 focus:ring-primary-900 focus:ring-offset-2"
            >
            <div class="flex flex-col items-start gap-1">
              <h4 class="text-sm font-medium text-grayCust-620">
                {{ item.name }}
              </h4>
              <p class="text-left text-xs text-grayCust-460 lg:max-w-[256px]">
                {{ item.short_description }}
              </p>
            </div>
          </div>
          <div
            v-if="item.valid_to_text"
            class="flex justify-end lg:block"
          >
            <h6 class="text-xs text-grayCust-470">
              {{ item.valid_to_text }}
            </h6>
          </div>
        </button>
      </div>
      <div
        class="absolute bottom-0 left-0 h-10 w-full pr-3"
        style="background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 120.23%);"
      />
    </div>
    <div class="mt-6">
      <button
        :disabled="!selectedOffer || processing"
        class="flex h-10 w-full cursor-pointer items-center justify-center gap-2 rounded-lg bg-secondary-800 py-3 text-base font-semibold leading-6 text-white shadow-sm focus:ring-2 focus:ring-secondary-800 focus:ring-offset-2 disabled:opacity-60"
        @click="applyOffer"
      >
        {{ $t('apply_offer') }}
      </button>
    </div>
  </div>
</template>

<script>

import { useAppStore } from '@/store/index';
import { mapStores } from 'pinia';
export default {
    
    name: "ApplyOffer",
    data(){
        return{
            couponApplied: false,
            selectedOffer:null,
            processing: false,
            
        }
    },
    methods: {
        onHandelBack(){
            if(this.couponApplied){
                this.couponApplied = false
            }else{
                this.$emit('backClick')
            }
        },
        applyCoupon(){
            this.couponApplied = true
        },
        applyOffer() {
            const that = this
            that.processing = true
             axios
                .post("/api/v2/offers/"+that.selectedOffer.id+"/apply")
                 .then((response) => {
                    that.$emit('offerApplied',response.data.data.coupon_code)
                })
                
                .finally(() => {
                    that.processing = false;
                });
        }
    },
    computed: {
        ...mapStores(useAppStore),
    }
}
</script>
<style scoped>

</style>
