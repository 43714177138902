<template>
  <Listbox
    as="div"
    :v-model="selectedOption"
  >
    <div class="relative">
      <div
        ref="dropdownButton"
        @click="toggleDropdown"
      >
        <ListboxButton
          :disabled="disabled"
          :class="[buttonStyle, disabled ? '!cursor-not-allowed opacity-50' : '', $attrs['btn-extra-class'],buttonExtraClass, 'relative flex w-full cursor-pointer items-center justify-between gap-2 rounded-lg border border-warning-1020 bg-white px-3.5 py-2 text-base text-grayCust-700 shadow-sm transition-shadow focus:border-secondary-800 focus:outline-none focus:ring focus:ring-primary-275']"
        >
          <span :class="[alignment,'flex w-4/5 flex-auto items-center gap-2']">
            <img
              v-if="selectedOption?.iconName"
              :src="cdn(selectedOption?.iconName)"
              :class="[buttonImageType]"
              alt=""
            >
            <img
              v-if="!selectedOption?.iconName && !defaultSelectFirst"
              :src="cdn(btnImage)"
              :class="[buttonImageType]"
              alt=""
            >
            <span
              class="block max-w-full truncate"
              :class="selectedOption ? 'text-grayCust-1740' : 'text-grayCust-630'"
            >
              {{ selectedOption ? selectedOption?.name : btnPlaceholder }}
            </span>
          </span>

          <div>
            <component
              :is="iconComponent(btnIconType, btnIconName)"
              aria-hidden="true"
              class="size-4"
            />
          </div>
        </ListboxButton>
      </div>

      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          ref="dropdownList"
          :class="[dropdownBoxWidth,dropdownPosition, 'absolute z-10 my-1']"
        >
          <ListboxOptions :class="[maxHeight, 'custom-scroll w-full overflow-y-auto rounded-lg border border-grayCust-160 bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm2:text-base space-y-1']">
            <ListboxOption
              v-for="item in sortedOptionList"
              :key="item.id"
              v-slot="{ active }"
              :value="item"
              :disabled="item.disabled"
            >
              <li
                :class="[(selectedOption && selectedOption.id === item.id) ? cellColor : 'text-grayCust-640',active ? 'bg-grayCust-280 text-grayCust-640' : '', item.disabled ? '!cursor-not-allowed opacity-50' : '', [cellOptionSize, 'relative flex cursor-pointer select-none items-center truncate px-3 py-1.5']]"
                @click="onHandleChange(item)"
              >
                <div class="flex w-full items-center gap-2">
                  <img
                    v-if="item.iconName"
                    :class="cellImageStyle"
                    :src="cdn(item.iconName)"
                    alt=""
                    class="size-5"
                  >
                  <span :class="[textSize,(selectedOption && selectedOption.id === item.id) ? 'text-primary-900':'text-grayCust-640', 'block max-w-[90%] truncate']">
                    {{ item.name }}
                  </span>
                </div>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </div>
      </transition>
    </div>
  </Listbox>
</template>
<script>

import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue';
import * as OutlineIcons from "@heroicons/vue/outline";
import * as SolidIcons from "@heroicons/vue/solid";

export default {
    name: 'ListBox',
    
    components: {
        Listbox,
        ListboxButton,
        ListboxOption,
        ListboxOptions
    },
    props: {
        btnPlaceholder: {
            type: String,
            required: false,
            default: ''
        },
        btnImage: {
            type: String,
            required: false,
            default: ''
        },
        btnIconName: {
            type: String,
            required: false,
            default: 'ChevronDownIcon'
        },
        btnIconType: {
            type: String,
            required: false,
            default: "outline"
        },
        btnImageType: {
            type: String,
            required: false,
            default: 'default'
        },
        buttonSize: {
            type: String,
            default: 'btn-sm',
            required: false
        },
        defaultSelectFirst: {
            type: Boolean,
            required: false,
            default: true
        },
        optionList: {
            type: Array,
            required: false,
            default: []
        },
        dropdownBoxWidth: {
            type: String,
            default: 'w-full',
            required: false
        },
        cellHoverColor: {
            type: String,
            default: 'primary',
            required: false
        },
        cellImageShape: {
            type: String,
            default: 'default',
            required: false
        },
        cellImagePosition: {
            type: String,
            default: 'first',
            required: false
        },
        cellSize: {
            type: String,
            default: 'cell-lg',
            required: false
        },
        selectedOption: {
            type:Object,
            default: {},
            required:false
        },
        maxHeight: {
            type:String,
            default: 'max-h-56',
            required:false
        },
        iconSize: {
            type:String,
            default: 'w-5 h-5',
        },
        textSize: {
            type: String,
            required: false,
            default: null
        },
        buttonExtraClass: {
            type: String,
            required: false,
            default: null
        },
        alignment: {
            type: String,
            required: false,
            default: null
        },
        disabled:{
          type: Boolean,
          required : false,
          default: false
        }
    },
    data() {
        return {
            buttonType: '',
            buttonStyle: '',
            buttonImageType: '',
            cellOptionSize: '',
            cellColor: '',
            cellTextColor: '',
            cellImageStyle: '',
            dropdownPosition: ''
        };
    },
    computed: {
        sortedOptionList() {
            if (!this.selectedOption || !this.selectedOption.id || !this.optionList.length) {
                return this.optionList;
            }
            const sortedList = [...this.optionList];
            const selectedIndex = sortedList.findIndex(item => item.id === this.selectedOption.id);
            if (selectedIndex !== -1) {
                const selectedItem = sortedList.splice(selectedIndex, 1)[0];
                sortedList.unshift(selectedItem);
            }
            return sortedList;
        }
    },
    created() {
        switch (this.btnImageType) {
            case 'default':
                this.buttonImageType = 'min-w-[20px] max-w-[20px] w-5 h-5';
                break;
            case 'rounded':
                this.buttonImageType = 'min-w-[24px] max-w-[24px] h-6 rounded-full overflow-hidden';
                break;
            case 'status':
                this.buttonImageType = 'min-w-[12px] max-w-[12px] w-3 h-3 rounded-full overflow-hidden';
                break;
        }
        switch (this.buttonSize) {
            case 'btn-sm':
                this.buttonStyle = 'h-10';
                break;
            case 'btn-lg':
                this.buttonStyle = 'h-11';
                break;
        }
        switch (this.cellHoverColor) {
            case 'primary':
                this.cellColor = 'bg-primary-275 text-primary-900';
                this.cellTextColor = 'text-primary-900';
                break;
            case 'secondary':
                this.cellColor = 'bg-grayCust-280 text-grayCust-660';
                this.cellTextColor = 'text-grayCust-660';
                break;
        }
        switch (this.cellSize) {
            case 'cell-sm':
                this.cellOptionSize = 'text-sm h-9';
                break;
            case 'cell-lg':
                this.cellOptionSize = 'text-base h-10';
                break;
        }
        switch (this.cellImageShape) {
            case 'default':
                this.cellImageStyle += ' rounded-none';
                break;
            case 'rounded':
                this.cellImageStyle += ' rounded-full';
                break;
        }
        switch (this.cellImagePosition) {
            case 'first':
                this.cellImageStyle += ' order-first';
                break;
            case 'last':
                this.cellImageStyle += ' order-last';
                break;
        }
    },
    methods: {
        toggleDropdown() {
          this.updateDropdownPosition();
        },
        iconComponent(type, iconName) {
            switch (type) {
                case "solid":
                    if (Object.prototype.hasOwnProperty.call(SolidIcons, iconName)) {
                        return SolidIcons[iconName];
                    } else {
                        return null;
                    }
                case "outline":
                    if (Object.prototype.hasOwnProperty.call(OutlineIcons, iconName)) {
                        return OutlineIcons[iconName];
                    } else {
                        return null;
                    }
                default:
                return null;
            }
        },
        onHandleChange(optionValue){
            this.$emit("onHandleChange",optionValue)
        },
        updateDropdownPosition() {
          this.$nextTick(() => {
            const dropdownButton = this.$refs.dropdownButton;
            const dropdownList = this.$refs.dropdownList;
            const dropdownHeight = dropdownList.clientHeight;
            const dropdownButtonHeight = dropdownButton.clientHeight;
            const windowHeight = window.innerHeight;
            const dropdownTop = dropdownButton.getBoundingClientRect().top;

            if (windowHeight - dropdownTop - dropdownButtonHeight < dropdownHeight) {
              this.dropdownPosition = "bottom-full top-auto"
            } else {
              this.dropdownPosition = "top-full bottom-auto"
            }
          });
        }
    },
};
</script>
