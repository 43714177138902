<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.30664 4.40412C3.30664 3.79699 3.79882 3.30481 4.40595 3.30481H17.5977C18.2048 3.30481 18.697 3.79699 18.697 4.40412V6.60274C18.697 7.20987 18.2048 7.70205 17.5977 7.70205H4.40595C3.79882 7.70205 3.30664 7.20987 3.30664 6.60274V4.40412Z"
      fill="currentColor"
    />
    <path
      d="M3.30664 11C3.30664 10.3928 3.79882 9.90067 4.40595 9.90067H11.0018C11.6089 9.90067 12.1011 10.3928 12.1011 11V17.5958C12.1011 18.203 11.6089 18.6952 11.0018 18.6952H4.40595C3.79882 18.6952 3.30664 18.203 3.30664 17.5958V11Z"
      fill="currentColor"
    />
    <path
      d="M15.3991 9.90067C14.7919 9.90067 14.2997 10.3928 14.2997 11V17.5958C14.2997 18.203 14.7919 18.6952 15.3991 18.6952H17.5977C18.2048 18.6952 18.697 18.203 18.697 17.5958V11C18.697 10.3928 18.2048 9.90067 17.5977 9.90067H15.3991Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
}
</script>
