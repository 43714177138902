<template>
  <div
    v-if="feedbackOptions.length"
    class="flex flex-wrap justify-between gap-4 px-[10px] py-[50px]"
  >
    <div
      v-for="(option, index) in feedbackOptions"
      :key="option.label"
      class="relative flex cursor-pointer flex-col items-center justify-center gap-4"
      :class="{'z-10':!option.clicked && selectedFeedback !== option.rating}"
    >
      <div
        class="flex size-14 items-center justify-center rounded-full bg-warning-1200/10 transition-all duration-100"
        :class="{
          'scale-100 border border-warning-1200/10 grayscale': (!option.clicked && selectedFeedback !== option.rating),
          'scale-125 bg-color-gradient-active grayscale-0': option.clicked || selectedFeedback === option.rating,
        }"
        @click="handleOptionClick(index)"
      >
        <div
          class="relative"
          :class="{'z-10': (!option.clicked && selectedFeedback !== option.rating)}"
        >
          <img
            :src="cdn(option.image)"
            alt="feedback"
            :class="{
              'z-10 size-[32px] scale-100': (!option.clicked && selectedFeedback !== option.rating),
              'size-[52px] scale-75': option.clicked || selectedFeedback === option.rating,
            }"
          >
        </div>
        <div
          v-if="option.clicked || selectedFeedback === option.rating"
          class="absolute z-0 flex size-[154px] items-center justify-center"
        >
          <img
            :src="cdn('images/Feedback/feedback-bg.svg')"
            alt="circel-bg"
          >
        </div>
      </div>
      <div>
        <span
          class="text-xs text-grayCust-910"
          :class="{'text-secondary-800': option.clicked || selectedFeedback === option.rating}"
        >{{ option.label }}</span>
      </div>
    </div>
  </div>
</template>

<script>

import { useSurveyStore } from '@/store/survey';
import { mapStores, mapActions } from 'pinia';

export default {
    name:'Feedbackbox',
    
    data() {
        return {
            feedbackOptions: [
                {
                    label: "Bad (1/5)",
                    rating: 1,
                    image: 'images/Feedback/bad.png',
                    clicked: false
                },
                {
                    label: "So-so (2/5)",
                    rating: 2,
                    image: 'images/Feedback/so-so.png',
                    clicked: false
                },
                {
                    label: "Ok (3/5)",
                    rating: 3,
                    image: 'images/Feedback/ok.png',
                    clicked: false
                },
                {
                    label: "Good (4/5)",
                    rating: 4,
                    image: 'images/Feedback/good.png',
                    clicked: false
                },
                {
                    label: "Great (5/5)",
                    rating: 5,
                    image: 'images/Feedback/great.png',
                    clicked: false
                },
            ],
            selectedFeedback: null,
        };
    },
    computed: {
        ...mapStores(useSurveyStore),
    },
    mounted() {
        const rating = this.surveyStore.survey?.user_survey?.rating;
        if (rating) {
          this.selectedFeedback = this.feedbackOptions[rating - 1].rating;
          this.updateFeedbackRating(this.selectedFeedback);
        }
    },
    methods: {
      ...mapActions(useSurveyStore, ["updateFeedbackRating"]),
        onHandleClose() {
            this.$emit('closeModal');
        },
        handleOptionClick(index) {
            this.selectedFeedback = this.feedbackOptions[index].rating;
            this.feedbackOptions.forEach((option, i) => {
                option.clicked = (i === index);
            });
            this.updateFeedbackRating(this.selectedFeedback);
        },
    },
};
</script>

<style></style>