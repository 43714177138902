<template>
  <div :class="[width, containerStyle, 'flex gap-2']">
    <component
      :is="iconComponent(iconType, iconName)"
      v-if="iconName"
      :class="[iconColor]"
      class="w-5 h-5"
    />
    <button
      v-for="item in tabList"
      :key="item.id"
      class="flex flex-auto items-center justify-center gap-2 focus:outline-none"
      :class="[selectedTab == item.id ? buttonType : 'border-b-2 border-transparent bg-transparent text-grayCust-630 shadow-none', width, sizeStyle, textColor]"
      @click="onHandleSelectTab(item.id)"
    >
      <component
        :is="iconComponent(iconType, item.iconName)"
        v-if="item.iconName"
        :class="[item.iconColor]"
        class="w-5 h-5"
      />
      <span :class="[textStyle, 'text-nowrap']">{{ item.title }}</span>
      <slot :name="'badge-' + item.id" />
      <span
        v-if="item.badge"
        :class="[selectedTab == item.id ? badgeType : 'bg-grayCust-280 text-grayCust-980', badgeClass]"
        class=""
      >{{ item.badge }}</span>
    </button>
  </div>
</template>
<script>

import * as OutlineIcons from "@heroicons/vue/outline";
import * as SolidIcons from "@heroicons/vue/solid";
export default {
    
    props: {
        btnType: {
            type: String,
            default: 'primary',
            required: false
        },
        size: {
            type: String,
            default: 'btn-sm',
        },
        width: {
            type: String,
            default: 'w-fit',
        },
        badge: {
            type: [Number, String],
            default: null,
        },
        tabList: {
            type: Array,
            default: []
        },
        selectedTab: {
            type: Number,
            default: 0
        },
        textColor: {
            type: String,
            default: ''
        },
        iconName: {
            type: String,
            required: false
        },
        iconType: {
            type: String,
            required: false,
            default: 'outline'
        },
        iconColor: {
            type: String,
            required: false,
        },
        iconName: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            buttonType: '',
            badgeType: '',
            sizeStyle: '',
            containerStyle: '',
            textStyle: ''
        };

    },
    created() {
        switch (this.btnType) {
            case 'primary':
                this.buttonType = 'bg-warning-1170 text-primary-800 border-b-2 border-transparent rounded-md ';
                break;
            case 'gray':
                this.buttonType = 'bg-grayCust-380 text-grayCust-980 border-b-2 border-transparent rounded-md';
                break;
            case 'white':
                this.buttonType = 'bg-white text-grayCust-980 shadow border-b-2 border-transparent rounded-md';
                this.containerStyle = 'bg-grayCust-380 border border-grayCust-280 p-1 rounded-lg'
                break;
            case 'underline':
                this.buttonType = 'bg-white text-primary-800 border-b-2 border-primary-800';
                this.containerStyle = 'border-b border-grayCust-160'
                break;
            case 'underline-fill':
                this.buttonType = 'bg-warning-1170 text-primary-800 border-b-2 border-primary-800';
                this.containerStyle = 'border-b border-grayCust-160'
                break;
        }
        switch (this.size) {
            case 'btn-sm':
                this.sizeStyle = 'px-3 py-2 h-9';
                this.badgeClass = `px-[8px] py-[2px] rounded-full text-sm`;
                this.textStyle = 'text-sm font-medium'
                break;
            case 'btn-md':
                this.sizeStyle = 'px-[14px] py-[10px] h-11';
                this.badgeClass = `px-[10px] py-[2px] rounded-full text-sm`;
                this.textStyle = 'text-base font-semibold'
                break;
        }
        switch (this.btnType) {
            case 'primary':
                this.badgeType = 'bg-secondary-580 text-primary-800';
                break;
            case 'gray':
                this.badgeType = 'bg-grayCust-375 text-grayCust-980';
                break;
            case 'white':
                this.badgeType = 'bg-grayCust-375 text-grayCust-980';
                break;
            case 'underline':
                this.badgeType = 'bg-warning-1170 text-primary-800';
                break;
            case 'underline-fill':
                this.badgeType = 'bg-secondary-580 text-primary-800';
                break;
        }
    },
    methods: {
        onHandleSelectTab(id) {
            this.$emit('update:selectedTab', id);
        },
        iconComponent(type, iconName) {
            switch (type) {
                case "solid":
                    if (Object.prototype.hasOwnProperty.call(SolidIcons, iconName)) {
                        return SolidIcons[iconName];
                    } else {
                        return null;
                    }
                case "outline":
                    if (Object.prototype.hasOwnProperty.call(OutlineIcons, iconName)) {
                        return OutlineIcons[iconName];
                    } else {
                        return null;
                    }
                default:
                    return null;
            }
        },
    }
};
</script>
