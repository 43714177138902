<template>
  <div class="w-full cursor-pointer sm2:w-auto">
    <div
      :class="extraClass"
      class="flex items-center justify-start gap-3 rounded-lg border pl-3 pr-4"
    >
      <input
        type="radio"
        class="size-4 cursor-pointer rounded-full border-gray-300 text-primary-900 focus:ring-0 focus:ring-offset-0"
        :checked="isChecked"
      >
      <div
        class="w-px"
        :style="bgExtraClass"
      />
      <div class="flex items-center justify-center gap-2">
        <img
          v-if="!!imagePath"
          :src="cdn(imagePath)"
          class="size-4 sm2:size-5"
          alt=""
        >
        <span class="text-xs font-medium sm2:text-sm">{{ title }}</span>
      </div>
    </div>
  </div>
</template>

<script>


export default {
    
    name: 'SurvetRadioInputCard',
    props:{
        lastIndex: {
            type: Number,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        extraClass: {
            type: String,
            required: true,
            default: "bg-white border-grayCust-180 text-grayCust-620"
        },
        imagePath: {
            type: String,
            required: false,
        },
        isChecked: {
            type: Boolean,
            default: false
        },
        bgExtraClass: {
            type: String,
            required: true,
            default: "background: linear-gradient(300deg, rgba(234, 236, 240, 0.00) 0.36%, #EAECF0 51.93%, rgba(234, 236, 240, 0.00) 101.4%);"
        }
    },
    data(){
        return{
            selectedBox: 1,
            isInputBox: false
        }
    }
}
</script>
