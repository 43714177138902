<template>
  <div>
    <TransitionRoot
      as="template"
      :show="isModalOpen"
    >
      <Dialog
        as="div"
        class="relative z-10"
        @close="closeHostingModalOutside"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <div
          class="fixed z-10 inset-0
        overflow-y-auto"
        >
          <div
            class="
              flex
              items-end
              sm:items-center
              justify-center
              min-h-full
              p-4
              text-center
              sm:p-0
            "
            style="height:100vh"
          >
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leave-from="opacity-100 translate-y-0 sm:scale-100"
              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                class="
                  flex
                  relative
                  rounded-lg
                  text-left
                  bg-grayCust-100
                  overflow-hidden
                  shadow-xl
                  transform
                  transition-all
                  sm:my-8 sm:max-w-lg sm:w-full
                "
                style="min-width: 1290px; min-height: 720px"
              >
                <hosting-side-bar
                  :hosting-connections="hostingConnections"
                  :current-selected-provider="currentSelectedProvider"
                  :tab-name="tabName"
                  :process="process"
                  @change-tab-name="openTab($event)"
                  @change-current-selected-provider="updateCurrentSelectedProvider($event)"
                  @click-account-tab="openAccountTab($event)"
                />
                <!-- need to changed the isLoading state base on requirement -->
                <hosting-manager-table
                  v-if="isAccountTab"
                  :sites="sites"
                  :selected-account-details="selectedAccountDetails"
                  :is-loading="tabName == 'account1' ? false : false"
                  @update_sites_data="getSites"
                  @update_sites="updateSites($event)"
                  @new-site-form="openNewSiteForm()"
                  @close-modal="closeHostingModal($event)"
                />
                <hosting-provider
                  v-if="tabName == 'hostingProvider' && !process"
                  :preferred-hosting-providers="preferredHostingProviders"
                  :other-hosting-providers="otherHostingProviders"
                  @open-hosting-connect-form="openHostingForm($event)"
                  @close-modal="closeHostingModal($event)"
                />
                <connect-hosting
                  v-else-if="tabName == 'connectHosting'"
                  :connect-hosting-object="connectHostingObject"
                  @refresh-data-providers="refreshData($event)"
                  @close-modal="closeHostingModal($event)"
                />
                <hosting-new-site
                  v-else-if="!process && tabName == 'newSite'"
                  :users="users"
                  :php_versions="php_versions"
                  :selected-account-details="selectedAccountDetails"
                  :provider="provider"
                  @close-modal="closeHostingModal($event)"
                />
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
// import { ref } from 'vue'
import ConnectHosting from '@/components/UpdatedDesignVersion/HostingManager/ConnectHosting.vue';
import HostingManagerTable from "@/components/UpdatedDesignVersion/HostingManager/HostingManagerTable.vue";
import HostingNewSite from "@/components/UpdatedDesignVersion/HostingManager/HostingNewSite.vue";
import HostingProvider from "@/components/UpdatedDesignVersion/HostingManager/HostingProvider.vue";
import HostingSideBar from '@/components/UpdatedDesignVersion/HostingManager/HostingSideBar.vue';
import GodaddySvg from "@/components/UpdatedDesignVersion/ImageComponents/HostingManager/GodaddySvg.vue";
import HostingArrowDown from "@/components/UpdatedDesignVersion/ImageComponents/HostingManager/HostingArrowDown.vue";
import HostingArrowGrayRight from "@/components/UpdatedDesignVersion/ImageComponents/HostingManager/HostingArrowGrayRight.vue";
import HostingArrowRight from "@/components/UpdatedDesignVersion/ImageComponents/HostingManager/HostingArrowRight.vue";
import PlusPrimarySvg from "@/components/UpdatedDesignVersion/ImageComponents/HostingManager/PlusPrimarySvg.vue";
import RunCloud from "@/components/UpdatedDesignVersion/ImageComponents/HostingManager/RunCloud.vue";
import { useAppStore } from '@/store/index';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { wTrans } from 'laravel-vue-i18n';
import { mapState, mapStores } from 'pinia';

export default {
  name: "HostingManagerModal",
  components: {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    HostingArrowDown,
    GodaddySvg,
    HostingArrowRight,
    HostingArrowGrayRight,
    RunCloud,
    PlusPrimarySvg,
    HostingManagerTable,
    HostingProvider,
    ConnectHosting,
    HostingSideBar,
    HostingNewSite
  },
  props: ["isModalOpen"],
  computed: {
    ...mapStores(useAppStore),
    ...mapState(useAppStore, ['hostingConnections']),
  },
  mounted() {
    // this.getConnectedProviders();
  },
  methods: {
    closeHostingModal() {
      this.$emit("close_hosting_modal");
    },
    closeHostingModalOutside() {
      this.$emit("close_hosting_modal_outside");
    },
    refreshData() {
      this.getConnectedProviders();
    },
    openTab(tabName) {
      this.tabName = tabName;
      this.isAccountTab = false;
      this.sites = {data: []};
    },
    updateCurrentSelectedProvider(provider) {
      this.currentSelectedProvider = provider;
    },
    openAccountTab(data) {
      this.isAccountTab = true;
      this.tabName = data.tabName;
      this.selectedAccountDetails = data.selectedAccountDetails;
      this.getSites(data.selectedAccountDetails.id);
    },
    openHostingForm(data) {
      this.connectHostingObject = data;
      this.tabName = 'connectHosting';
    },
    openNewSiteForm() {
      this.getProviderAndConnection(this.selectedAccountDetails.id);
      this.isAccountTab = false;
      this.tabName = 'newSite';
    },
    getProviderAndConnection(accountId){
      let that = this;
      that.process = true;
      axios
        .get("/api/v1/get-provider-and-connection/"+ accountId )
        .then((response) => {
          if(response.data.status){
            that.provider = response.data.data.connection.provider;
            if(that.provider.required_fields_for_new_site.includes('phpVersion')){
              this.getPHPVersions(this.selectedAccountDetails.id);
            }
          }
          else{
            let message = {
              heading: "Failed!",
              subHeading: response.data.message,
              type: "error",
            };
            that.appStore.setNotification(message);
          }
        })
        .catch((error) => {
          let message = {
            heading: "Failed !",
            subHeading: 'Not able to fetch system users',
            type: "error",
          };
          that.appStore.setNotification(message);
        })
        .finally(() => {
          that.process = false;
        });
    },
    getPHPVersions(accountId){
      let that = this;
      that.process = true;
      axios
        .get("/api/v1/get-php-version/"+ accountId )
        .then((response) => {
          if(response.data.status){
            that.php_versions = response.data.data.php_versions.response;
            that.users = response.data.data.users.response.data;
          }
          else{
            let message = {
              heading: that.$t('failed') ,
              subHeading: response.data.message,
              type: "error",
            };
            that.appStore.setNotification(message);
          }
        })
        .catch(() => {
          let message = {
            heading: wTrans('failed') ,
            subHeading: wTrans('not_able_fetch_system_users'),
            type: "error",
          };
          that.appStore.setNotification(message);
        })
        .finally(() => {
          that.process = false;
        });
    },
    getSites(accountId, page = 1){
      let that = this;
      that.process = true;
      axios
        .get("/api/v1/get-sites-by-account/"+ accountId + "?page="+ page)
        .then((response) => {
          that.sites = response.data.data.sites;
        })
        .catch((error) => {
          let message = {
            heading: that.$t('failed') ,
            subHeading: error?.response?.data.message || error.message,
            type: "error",
          };
          that.appStore.setNotification(message);
        })
        .finally(() => {
          that.process = false;
        });
    },
    updateSites(data){
      this.sites = data;
    },
    getConnectedProviders() {
      this.appStore.getHostingConnections(true)
    },
  },
  data() {
    return {
      tabName: "hostingProvider",
      process: false,
      isGodaddyShow: false,
      isRunCloudShow: false,
      isAccountTab: false,
      currentSelectedProvider: '',
      currentSelectedProviderAccount: '',
      selectedAccountDetails: [],
      preferredHostingProviders: [],
      otherHostingProviders: [],
      hostingConnections: [],
      connectHostingObject: [],
      provider: [],
      sites: {data: []},
      php_versions: {data: []},
      users: {data: []},
    };
  },
};
</script>
<style scoped>
.site-slide-animation-enter-from,
.site-slide-animation-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}

.site-slide-animation-enter-to,
.site-slide-animation-leave-from {
  opacity: 1;
  transform: translateY(0px);
}

.site-slide-animation-enter-active,
.site-slide-animation-leave-active {
  transition: all 0.4s ease;
}

.site-slide-animation-move {
  transition: all 0.4s ease;
}

.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
