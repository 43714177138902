<template>
  <div>
    <svg
      :width="width"
      :height="height"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0005 11.9996C14.0005 13.1026 13.1035 13.9996 12.0004 13.9996C10.8974 13.9996 10.0003 13.1026 10.0003 11.9996C10.0003 11.0695 10.6389 10.2855 11.5004 10.063V8.99943C11.5004 8.72391 11.2759 8.4994 11.0004 8.4994H5.00012C4.82462 8.4994 4.65661 8.4689 4.5001 8.4139V10.063C5.36164 10.2855 6.00017 11.0695 6.00017 11.9996C6.00017 13.1026 5.10313 13.9996 4.00008 13.9996C2.89704 13.9996 2 13.1026 2 11.9996C2 11.0695 2.63853 10.2855 3.50006 10.063V5.9363C2.63853 5.71379 2 4.92976 2 3.99972C2 2.89667 2.89704 1.99963 4.00008 1.99963C5.10313 1.99963 6.00017 2.89667 6.00017 3.99972C6.00017 4.92976 5.36164 5.71379 4.5001 5.9363V6.99984C4.5001 7.27535 4.72461 7.49986 5.00012 7.49986H11.0004C11.8274 7.49986 12.5004 8.17289 12.5004 8.99993V10.0635C13.362 10.286 14.0005 11.0695 14.0005 11.9996ZM3.00004 11.9996C3.00004 12.5511 3.44856 12.9996 4.00008 12.9996C4.55161 12.9996 5.00012 12.5511 5.00012 11.9996C5.00012 11.448 4.55161 10.9995 4.00008 10.9995C3.44856 10.9995 3.00004 11.448 3.00004 11.9996ZM5.00012 3.99922C5.00012 3.44769 4.55161 2.99918 4.00008 2.99918C3.44856 2.99918 3.00004 3.44769 3.00004 3.99922C3.00004 4.55074 3.44856 4.99926 4.00008 4.99926C4.55161 4.99926 5.00012 4.55074 5.00012 3.99922ZM12.0004 10.9995C11.4489 10.9995 11.0004 11.448 11.0004 11.9996C11.0004 12.5511 11.4489 12.9996 12.0004 12.9996C12.5519 12.9996 13.0005 12.5511 13.0005 11.9996C13.0005 11.448 12.5519 10.9995 12.0004 10.9995Z"
        fill="currentColor"
      />
    </svg>
  </div>
</template>

<script>
export default {
    props: {
        width: {
            type: Number,
            default: 16,
        },
        height: {
            type: Number,
            default: 16,
        },
    }
}
</script>

<style></style>