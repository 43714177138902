<template>
  <inertia-link
    v-if="props.item.path && !props.item.children"
    :id="`${props.item.title.replace(/\s+/g, '')}`"
    :href="props.href"
    class="flex items-center gap-2 px-[6px] py-[5px] text-sm text-grayCust-600 rounded-md border border-transparent hover:bg-white transition-colors duration-200"
    :class="{ 'bg-white !border-grayCust-160 shadow-sm font-medium text-grayCust-800': props.isSelected, '!bg-grayCust-50': props.isSelected && props.bgColor === 'bg-white' }"
    @click="emit('click')"
  >
    <div class="flex items-center justify-center h-6 min-w-6">
      <!-- Render icon if available -->
      <component
        :is="props.item.icon"
        v-if="props.item.icon"
        class="min-w-4 h-4"
        :class="[props.isSelected ? 'text-grayCust-800' : 'text-grayCust-500', props.item.iconClass]"
        aria-hidden="true"
      />
      <!-- Render image if available and no icon -->
      <img
        v-else-if="props.item.image"
        :src="cdn(props.item.image)"
        class="min-w-4 h-4 object-contain"
        :alt="props.item.title"
        aria-hidden="true"
      >
    </div>
    <span
      v-if="!props.isCollapsed"
      class="truncate"
    >{{ props.item.title }}</span>
  </inertia-link>

  <div
    v-else
    :id="`${props.item.title.replace(/\s+/g, '')}`"
    class="flex items-center gap-2 px-[6px] py-[5px] text-sm text-grayCust-600 rounded-md border border-transparent hover:bg-white transition-colors duration-200 cursor-pointer"
    :class="{ 'bg-white !border-grayCust-160 shadow-sm font-medium text-grayCust-800': props.isSelected, '!bg-grayCust-50': props.isSelected && props.bgColor === 'bg-white' }"
    @click="emit('click')"
  >
    <div class="flex items-center justify-center h-6 min-w-6">
      <!-- Render icon if available -->
      <component
        :is="props.item.icon"
        v-if="props.item.icon"
        class="min-w-4 h-4"
        :class="[props.isSelected ? 'text-grayCust-800' : 'text-grayCust-500', props.item.iconClass]"
        aria-hidden="true"
      />
      <!-- Render image if available and no icon -->
      <img
        v-else-if="props.item.image"
        :src="cdn(props.item.image)"
        class="min-w-4 h-4 object-contain"
        :alt="props.item.title"
        aria-hidden="true"
      >
    </div>
    <span
      v-if="!props.isCollapsed"
      class="truncate"
    >{{ props.item.title }}</span>
    <OutlineChevronDownIcon
      v-if="props.item.children && props.item.children.length > 0 && !props.isCollapsed"
      class="w-4 h-4 ml-auto text-gray-400 transition-transform duration-200"
      :class="[props.item.isOpen ? 'transform rotate-180' : '']"
    />
  </div>
</template>
<script setup>
const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  isSelected: {
    type: Boolean,
    required: true
  },
  isCollapsed: {
    type: Boolean,
    required: true
  },
  href: {
    type: String,
    required: true
  },
  bgColor: {
    type: String,
    required: true
  },
  image: {
    type: String,
    default: '',
    required: false
  }
});

const emit = defineEmits(['click']);
</script>

