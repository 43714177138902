<template>
  <button
    class="flex items-center justify-center gap-2 rounded-md px-3 py-2 focus:outline-none md:w-auto"
    :class="isChecked ? 'bg-white text-grayCust-980 shadow' : 'bg-transparent text-grayCust-630 shadow-none'"
  >
    <component
      :is="iconName"
      v-if="iconName"
      aria-hidden="true"
      :class="[isChecked ? 'text-warning-450' : 'text-grayCust-630','size-5']"
    />
    <h4 class="text-sm font-medium">
      {{ title }}
    </h4>
  </button>
</template>

<script>

import StarsIcon from "@/components/UpdatedDesignVersion/ImageComponents/SiteNewDesign/StarsIcon.vue";

export default {
    
    name: 'StagingSiteTab',
    components: {
        StarsIcon
    },
    props: {
        title: {
            type: String,
            required: false,
            default: ''
        },
        iconName: {
            type: String,
            required: false,
            default: ''
        },
        isChecked:{
            type: Boolean,
            required: false,
            default: false
        },
    },
}
</script>