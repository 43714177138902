<template>
  <div>
    <svg
      width="13"
      height="11"
      viewBox="0 0 13 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9986 1.5215e-05L1.6386 1.47622e-05C0.848596 1.47276e-05 0.368595 0.870015 0.798595 1.54001L5.97859 9.68002C6.36859 10.3 7.2686 10.3 7.6686 9.68002L12.8386 1.54002C13.2686 0.870015 12.7886 1.52495e-05 11.9986 1.5215e-05Z"
        fill="#1F2937"
      />
    </svg>
  </div>
</template>

<script>
export default {
    name: 'DownArrow'
}
</script>