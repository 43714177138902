<template>
  <svg
    class="animate-spin"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.4999 5.12222C12.0307 5.12222 11.6504 4.74192 11.6504 4.2727V0.849516C11.6504 0.380297 12.0307 0 12.4999 0C12.9691 0 13.3494 0.380297 13.3494 0.849516V4.27275C13.3494 4.74192 12.9691 5.12222 12.4999 5.12222Z"
      :fill="color"
    />
    <path
      opacity="0.52"
      d="M12.4999 24.0002C12.0307 24.0002 11.6504 23.6199 11.6504 23.1507V19.7274C11.6504 19.2582 12.0307 18.8779 12.4999 18.8779C12.9691 18.8779 13.3494 19.2582 13.3494 19.7274V23.1507C13.3494 23.6199 12.9691 24.0002 12.4999 24.0002Z"
      :fill="color"
    />
    <path
      opacity="0.58"
      d="M16.7139 23.173C16.379 23.173 16.0615 22.9735 15.9271 22.6444L14.6335 19.475C14.4562 19.0406 14.6647 18.5448 15.099 18.3675C15.5331 18.19 16.0292 18.3986 16.2065 18.8329L17.5001 22.0023C17.6775 22.4366 17.469 22.9325 17.0347 23.1098C16.9296 23.1528 16.8209 23.173 16.7139 23.173Z"
      :fill="color"
    />
    <path
      opacity="0.94"
      d="M15.4197 5.69522C15.3128 5.69522 15.2041 5.67497 15.099 5.63198C14.6646 5.4548 14.4562 4.95886 14.6335 4.52447L15.9272 1.35511C16.1044 0.920718 16.6003 0.711702 17.0347 0.889686C17.4691 1.06687 17.6775 1.56281 17.5001 1.9972L16.2065 5.16656C16.0721 5.49581 15.7547 5.69522 15.4197 5.69522Z"
      :fill="color"
    />
    <path
      opacity="0.46"
      d="M8.28594 23.173C8.17902 23.173 8.07032 23.1528 7.96523 23.1098C7.53084 22.9326 7.32243 22.4367 7.49976 22.0023L8.79337 18.8329C8.97069 18.3985 9.46649 18.19 9.90088 18.3675C10.3353 18.5447 10.5437 19.0407 10.3664 19.4751L9.07274 22.6444C8.93835 22.9736 8.62091 23.173 8.28594 23.173Z"
      :fill="color"
    />
    <path
      opacity="0.36"
      d="M2.17669 17.0636C1.84172 17.0636 1.52429 16.8643 1.38989 16.535C1.21257 16.1006 1.42097 15.6047 1.85532 15.4274L5.02463 14.1338C5.45897 13.956 5.95486 14.1646 6.13219 14.5992C6.30952 15.0336 6.10111 15.5295 5.66677 15.7067L2.49746 17.0003C2.39236 17.0433 2.28361 17.0636 2.17669 17.0636Z"
      :fill="color"
    />
    <path
      opacity="0.82"
      d="M19.6547 9.92973C19.3198 9.92973 19.0023 9.73046 18.8679 9.40112C18.6906 8.96673 18.899 8.47084 19.3333 8.2936L22.5027 6.99995C22.9369 6.82224 23.4329 7.03079 23.6102 7.46537C23.7875 7.89976 23.5791 8.39565 23.1448 8.57288L19.9755 9.86654C19.8704 9.90948 19.7616 9.92973 19.6547 9.92973Z"
      :fill="color"
    />
    <path
      opacity="0.24"
      d="M5.3454 9.92959C5.23848 9.92959 5.12978 9.90934 5.02464 9.86636L1.85532 8.5727C1.42093 8.39551 1.21257 7.89958 1.3899 7.46519C1.56718 7.0308 2.06293 6.82244 2.49746 6.99976L5.66678 8.29342C6.10117 8.47061 6.30953 8.96655 6.1322 9.40094C5.99781 9.73014 5.68032 9.92959 5.3454 9.92959Z"
      :fill="color"
    />
    <path
      opacity="0.7"
      d="M22.8234 17.0633C22.7165 17.0633 22.6078 17.043 22.5027 17L19.3333 15.7064C18.899 15.5292 18.6906 15.0332 18.8679 14.5989C19.0453 14.1648 19.541 13.9562 19.9755 14.1335L23.1448 15.4271C23.5792 15.6043 23.7876 16.1003 23.6102 16.5346C23.4758 16.8638 23.1584 17.0633 22.8234 17.0633Z"
      :fill="color"
    />
    <path
      opacity="0.88"
      d="M17.9637 7.38569C17.7463 7.38569 17.529 7.30272 17.3631 7.13683C17.0313 6.80514 17.0313 6.26725 17.363 5.93556L19.7836 3.51498C20.1153 3.18316 20.6532 3.18316 20.9849 3.51498C21.3167 3.84667 21.3167 4.38456 20.985 4.71625L18.5644 7.13683C18.3986 7.30272 18.1811 7.38569 17.9637 7.38569Z"
      :fill="color"
    />
    <path
      opacity="0.4"
      d="M4.61559 20.7343C4.39819 20.7343 4.18083 20.6514 4.01494 20.4855C3.6832 20.1538 3.68316 19.6159 4.01489 19.2842L6.43542 16.8636C6.76716 16.5318 7.30505 16.5318 7.63678 16.8636C7.96852 17.1953 7.96856 17.7332 7.63683 18.0649L5.2163 20.4855C5.05041 20.6514 4.83295 20.7343 4.61559 20.7343Z"
      :fill="color"
    />
    <path
      opacity="0.18"
      d="M7.03614 7.38558C6.81869 7.38558 6.60138 7.30261 6.43544 7.13672L4.01491 4.71614C3.68317 4.38446 3.68317 3.84657 4.01496 3.51488C4.34664 3.18319 4.88453 3.18319 5.21631 3.51488L7.63685 5.93546C7.96858 6.26714 7.96858 6.80504 7.6368 7.13672C7.47095 7.30261 7.2535 7.38558 7.03614 7.38558Z"
      :fill="color"
    />
    <path
      opacity="0.64"
      d="M20.3843 20.7342C20.1668 20.7342 19.9495 20.6512 19.7836 20.4854L17.363 18.0648C17.0313 17.7331 17.0313 17.1952 17.3631 16.8635C17.6948 16.5318 18.2327 16.5318 18.5644 16.8635L20.985 19.2841C21.3167 19.6158 21.3167 20.1537 20.9849 20.4854C20.8191 20.6512 20.6017 20.7342 20.3843 20.7342Z"
      :fill="color"
    />
    <path
      opacity="0.3"
      d="M4.77261 12.8494H1.34952C0.880344 12.8494 0.5 12.4691 0.5 11.9999C0.5 11.5307 0.880344 11.1504 1.34952 11.1504H4.77261C5.24178 11.1504 5.62213 11.5307 5.62213 11.9999C5.62213 12.4691 5.24178 12.8494 4.77261 12.8494Z"
      :fill="color"
    />
    <path
      opacity="0.76"
      d="M23.6506 12.8494H20.2274C19.7583 12.8494 19.3779 12.4691 19.3779 11.9999C19.3779 11.5307 19.7583 11.1504 20.2274 11.1504H23.6506C24.1198 11.1504 24.5001 11.5307 24.5001 11.9999C24.5001 12.4691 24.1199 12.8494 23.6506 12.8494Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
    name: "ThemeInstallingIcon",
    props: {
        color: {
            default: "#005E54",
            type: String,
            required: false
        }
    }
}
</script>