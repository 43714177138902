<template>
  <div style="max-width: 240px; max-height: 720px ">
    <div
      class="pt-8 bg-white border-r border-grayCust-350 overflow-y-scroll"
      style="min-width: 240px ; height: 720px"
    >
      <h3 class="text-center text-black mt-0.5 font-semibold text-lg">
        {{ $t('connected_providers') }}
      </h3>
      <div
        v-for="item in hostingConnections"
        :key="item.id"
        class=" flex flex-wrap w-full bg-white "
      >
        <div
          class="cursor-pointer mt-2.5 flex items-center justify-between w-full sticky top-0 bg-white py-3 px-5"
          @click="updateCurrentSelectedProvider(item.id)"
        >
          <div class="flex items-center text-sm font-medium text-grayCust-600">
            <component
              class="mr-3.5"
              v-html="item.provider.icon_svg"
            />
            <!-- <component :is="item.provider.icon_svg" class="mr-3.5"></component> -->
            {{ item.alias }}
          </div>
          <Hosting-arrow-down
            class="ease-in duration-300"
            :style="currentSelectedProvider == item.id && toggle ? 'transform: rotate(180deg)' : 'transform: rotate(0deg)'"
          />
        </div>
        <transition name="site-slide-animation">
          <div
            v-show="currentSelectedProvider == item.id && toggle"
            class="w-full child"
          >
            <div
              v-for="account in item.accounts"
              :key="account.id"
              :class="tabName == account.name ? 'bg-primary-175 border-l-4 border-primary-900 text-primary-900 ' : 'text-grayCust-600'"
              class="cursor-pointer flex items-center justify-between py-2.5 w-full pr-4"
              @click="clickAccountTab(account.name, account)"
            >
              <div class="flex items-center">
                <Hosting-arrow-right
                  v-if="tabName == account.name"
                  class="ml-6"
                />
                <Hosting-arrow-gray-right
                  v-else
                  class="ml-6"
                />
                <p class="text-sm font-medium ml-3">
                  {{ account.name }}
                </p>
              </div>
              <div>
                <theme-installing-icon
                  v-if="process && tabName == account.name"
                  style="width: 18px; height: 18px ;"
                />
              </div>
            </div>
          </div>
        </transition>
      </div>
      <div
        class="cursor-pointer flex items-center justify-center border-t border-grayCust-900 mt-2.5 py-2.5"
        @click="openTab('hostingProvider')"
      >
        <Plus-primary-svg class="mr-2.5 mt-2.5" />
        <p class="text-sm font-medium text-primary-900 mt-2.5">
          {{ $t('connect_hosting_provider') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>

import ThemeInstallingIcon from '@/Common/Icons/ThemeInstallingIcon.vue';
import ConnectHosting from '@/components/UpdatedDesignVersion/HostingManager/ConnectHosting.vue';
import HostingManagerTable from '@/components/UpdatedDesignVersion/HostingManager/HostingManagerTable.vue';
import HostingProvider from '@/components/UpdatedDesignVersion/HostingManager/HostingProvider.vue';
import GodaddySvg from '@/components/UpdatedDesignVersion/ImageComponents/HostingManager/GodaddySvg.vue';
import HostingArrowDown from '@/components/UpdatedDesignVersion/ImageComponents/HostingManager/HostingArrowDown.vue';
import HostingArrowGrayRight from '@/components/UpdatedDesignVersion/ImageComponents/HostingManager/HostingArrowGrayRight.vue';
import HostingArrowRight from '@/components/UpdatedDesignVersion/ImageComponents/HostingManager/HostingArrowRight.vue';
import PlusPrimarySvg from '@/components/UpdatedDesignVersion/ImageComponents/HostingManager/PlusPrimarySvg.vue';
import RunCloud from '@/components/UpdatedDesignVersion/ImageComponents/HostingManager/RunCloud.vue';
export default {
    components: {
        HostingArrowDown,
        GodaddySvg,
        HostingArrowRight,
        HostingArrowGrayRight,
        RunCloud,
        PlusPrimarySvg,
        HostingManagerTable,
        HostingProvider,
        ConnectHosting,
        ThemeInstallingIcon
    },
    props: {
        process: {
            type: Boolean
        },
        hostingConnections: {
            type: Array
        },
        currentSelectedProvider: {
            type: [Number, String]
        },
        tabName: {
            type: String
        },
    },
    data() {
        return {
            isGoDaddyShow: true,
            isRunCloudShow: false,
            isLoading: true,
            toggle:false
        }
    },
    methods: {
        openTab(tabName) {
            this.$emit('changeTabName', tabName);
        },
        updateCurrentSelectedProvider(provider) {
            if(this.toggle){
                this.toggle = false;
            }else{
                this.toggle = true;
            }
            this.$emit('changeCurrentSelectedProvider', provider)
        },
        clickAccountTab(tabName, selectedAccountDetails) {
            this.$emit('clickAccountTab', {tabName, selectedAccountDetails})
        }
    },
}
</script>


<style scoped>
.site-slide-animation-enter-from,
.site-slide-animation-leave-to {
    opacity: 0;
    transform: translateY(-10px);
}

.site-slide-animation-enter-to,
.site-slide-animation-leave-from {
    opacity: 1;
    transform: translateY(0px);
}

.site-slide-animation-enter-active,
.site-slide-animation-leave-active {
    transition: all 0.4s ease;
}

.site-slide-animation-move {
    transition: all 0.4s ease;
}
::-webkit-scrollbar {
    width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #005E54;
    border-radius: 8px;
}
</style>
