<template>
  <div class="w-full">
    <modal-hosting-header
      heading="Connect Provider"
      btn-text="Connect"
      :has-plus="false"
      extra-class="disabled:opacity-40 bg-primary-900 hover:bg-primary-200 text-white text-base leading-6 font-medium py-2 pr-3.5 pl-3 rounded inline-flex items-center"
      @handle-btn-click="$emit('openHostingConnectForm', selectedProvideBox)"
      @btn-close-modal="closeModalPopup"
    />

    <RadioGroup
      v-model="selectedProvideBox"
      value="{selectedProvideBox}"
    >
      <div
        class="grid gap-y-6 gap-x-4 overflow-y-scroll"
        style="max-height: 628px"
      >
        <div class="flex flex-wrap">
          <RadioGroupOption
            v-for="provider in preferredHostingProviders"
            :key="provider.id"
            v-slot="{ checked, active }"
            as="template"
            :value="provider"
          >
            <div
              :class="[checked ? 'border-transparent' : 'border-gray-300', active ? 'border-primary-900 ring-2 ring-primary-900' : '', 'cust-card cursor-pointer relative provider-box mt-8 bg-white rounded-xl ml-8 p-6']"
              style="width: 307px;"
            >
              <RadioGroupLabel
                as="span"
                class="block text-sm font-medium text-gray-900"
              >
                <component
                  :is="provider.logo_svg"
                  v-if="provider.logo_svg"
                />
                <img
                  v-if="!provider.logo_svg"
                  :src="cdn('images/selected_connected_provider.png')"
                  alt=""
                >
                <!-- <component v-if="!provider.logo_svg" v-bind:is="'ProviderWpEngine'" /> -->
              </RadioGroupLabel>
              <RadioGroupDescription
                as="h4"
                class="mt-4 text-black text-sm font-semibold cursor-pointer"
              >
                {{ provider.name }}
              </RadioGroupDescription>
              <RadioGroupDescription
                as="div"
                class="flex mt-3 items-center justify-between"
              >
                <p class="text-grayCust-600 text-sm font-semibold">
                  {{ $t('price_starts_from') }} {{ provider.pricing }}
                </p>
                <a
                  :href="provider.pricing_link"
                  target="_blank"
                >
                  <span class="cursor-pointer text-primary-900 text-sm font-semibold">{{ $t('view_pricing') }}</span>
                </a>
              </RadioGroupDescription>
              <RadioGroupDescription
                as="p"
                class="mt-3 text-sm text-black"
              >
                {{ provider.description }}
              </RadioGroupDescription>
              <RadioGroupDescription
                as="div"
                class="mt-4 flex items-center"
              >
                <button
                  v-for="type in provider.type"
                  :key="type"
                  class="text-primary-900 mt-4 bg-grayCust-100 rounded-full py-0.5 px-2.5 text-xs font-medium justify-center items-center"
                >
                  {{ type }}
                </button>
              </RadioGroupDescription>
              <span
                :class="[active ? 'border-2' : 'border-0', checked ? 'border-primary-900' : 'border-transparent', 'absolute -inset-px rounded-lg pointer-events-none']"
                aria-hidden="true"
              />
            </div>
          </RadioGroupOption>
        </div>
        <div
          class="w-20"
          @click="providerBoxOpen = !providerBoxOpen"
        >
          <p class="text-grayCust-1600 ml-8 text-xl font-bold cursor-pointer flex items-center">
            {{ $t('others') }}
            <provider-down-arrow
              class="ease-in duration-300 ml-3"
              :style="providerBoxOpen ? 'transform: rotate(180deg)' : 'transform: rotate(0deg)'"
            />
          </p>
        </div>
        <div
          v-show="providerBoxOpen"
          class="flex flex-wrap mb-8"
        >
          <RadioGroupOption
            v-for="provider in otherHostingProviders"
            :key="provider.id"
            v-slot="{ checked, active }"
            as="template"
            :value="provider"
          >
            <div
              :class="[checked ? 'border-transparent' : 'border-gray-300', active ? 'border-primary-900 ring-2 ring-primary-900' : '', 'cust-card cursor-pointer relative provider-box mt-8 bg-white rounded-xl ml-8 p-6']"
              style="width: 307px;"
            >
              <RadioGroupLabel
                as="span"
                class="block text-sm font-medium text-gray-900"
              >
                <component
                  :is="provider.logo_svg"
                  v-if="provider.logo_svg"
                />
                <img
                  v-if="!provider.logo_svg"
                  :src="cdn('images/selected_connected_provider.png')"
                  alt=""
                >
                <!-- <component v-if="!provider.logo_svg" v-bind:is="'ProviderWpEngine'" /> -->
              </RadioGroupLabel>
              <RadioGroupDescription
                as="h4"
                class="mt-4 text-black text-sm font-semibold cursor-pointer"
              >
                {{ provider.name }}
              </RadioGroupDescription>
              <RadioGroupDescription
                as="div"
                class="flex mt-3 items-center justify-between"
              >
                <p class="text-grayCust-600 text-sm font-semibold">
                  {{ $t('price_starts_from') }} {{ provider.pricing }}
                </p>
                <a
                  :href="provider.pricing_link"
                  target="_blank"
                >
                  <span class="cursor-pointer text-primary-900 text-sm font-semibold">{{ $t('view_pricing') }}</span>
                </a>
              </RadioGroupDescription>
              <RadioGroupDescription
                as="p"
                class="mt-3 text-sm text-black"
              >
                {{ provider.description }}
              </RadioGroupDescription>
              <RadioGroupDescription
                as="div"
                class="mt-4 flex items-center"
              >
                <button
                  v-for="type in provider.type"
                  :key="type"
                  class="text-primary-900 mt-4 bg-grayCust-100 rounded-full py-0.5 px-2.5 text-xs font-medium justify-center items-center"
                >
                  {{ type }}
                </button>
              </RadioGroupDescription>
              <span
                :class="[active ? 'border-2' : 'border-0', checked ? 'border-primary-900' : 'border-transparent', 'absolute -inset-px rounded-lg pointer-events-none']"
                aria-hidden="true"
              />
            </div>
          </RadioGroupOption>
        </div>
      </div>
    </RadioGroup>
  </div>
</template>

<script>
import ModalHostingHeader from '@/components/UpdatedDesignVersion/HostingManager/ModalHostingHeader.vue';
import GodaddySvg from "@/components/UpdatedDesignVersion/ImageComponents/HostingManager/GodaddySvg.vue";
import ProviderDownArrow from '@/components/UpdatedDesignVersion/ImageComponents/HostingManager/ProviderDownArrow.vue';
import RunCloud from "@/components/UpdatedDesignVersion/ImageComponents/HostingManager/RunCloud.vue";
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue';

export default {
    name: 'HostingProvider',
    components: {
        GodaddySvg,
        RunCloud,
        ProviderDownArrow,
        RadioGroup,
        RadioGroupDescription,
        RadioGroupLabel,
        RadioGroupOption,
        ModalHostingHeader
    },
    props: {
        preferredHostingProviders: {
            type: Array
        },
        otherHostingProviders: {
            type: Array
        }
    },
    data() {
        return {
            selectedProvideBox: '',
            providerBoxOpen: false,
            isHostingProviderConnectForm: false
        }
    },
    created() {
        this.selectedProvideBox = this.preferredHostingProviders[0]
    },
    methods: {
        closeModalPopup() {
            this.$emit("closeModal");
        }
    }
}
</script>

<style scoped>
.hosting-site-url {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
}

.provider-box {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
}


::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #005E54;
    border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #005E54;
}

.connect-btn {
    display: none;
}

.cust-card:hover .connect-btn {
    display: block;
}
</style>
