<template>
  <div class="flex flex-wrap justify-end items-center gap-3">
    <CButton
      v-if="!feedbackRatingSubmitted"
      btn-title="Remind me later"
      btn-type="gray-outline-btn"
      size="btn-md"
      @click="pauseSurvey"
    />
    <CButton
      v-if="!feedbackRatingSubmitted"
      btn-title="Continue"
      btn-type="secondary"
      size="btn-md"
      :class="{ 'cursor-not-allowed disabled:opacity-50': disableFeedbackSubmit }"
      :disabled="disableFeedbackSubmit"
      @click="handleSubmit"
    />
    <CButton
      v-if="feedbackRatingSubmitted"
      :btn-title="submitButtonText"
      btn-type="secondary"
      @click="handleSubmit"
    />
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import CButton from '@/components/New/Blocks/Buttons/CommonButton.vue';
import { useSurveyStore } from '@/store/survey';

export default {
    name: 'FeedbackFooter',
    components: {
        CButton
    },
    setup() {
        const surveyStore = useSurveyStore()

        // Local ref to store count for the template
        const disableFeedbackSubmit = ref(true);
        const disableReviewSubmit = ref(true);

        const getSubmitButtonText = (feedbackRating) => feedbackRating && feedbackRating <= 3 ? 'Submit Review' : 'Paste Review to TrustPilot'

        const submitButtonText = ref(getSubmitButtonText(surveyStore.feedbackRating));

        watch(
            () => surveyStore.feedbackRating,
            (newRating) => {
                disableFeedbackSubmit.value = !newRating;
                submitButtonText.value = getSubmitButtonText(newRating);
            }
        )

        watch(
            () => surveyStore.responseText,
            (newText) => {
                disableReviewSubmit.value = !newText
            }
        )

        watch(
            () => surveyStore.shareReviewToTP,
            (newVal) => {
                submitButtonText.value = newVal ? 'Paste Review to TrustPilot' : 'Submit Review';
            }
        )

        return {
            disableFeedbackSubmit,
            disableReviewSubmit,
            submitButtonText
        }
    },
    data() {
        return {
            feedbackRatingSubmitted: false
        };
    },
    methods: {
        handleSubmit() {
            this.feedbackRatingSubmitted = true;
            this.$emit('submitFeedback');
        },
        onHandleClose(){
            this.$emit('closeModal');
        },
        pauseSurvey() {
            this.$emit('pauseSurvey');
        },
    }
}
</script>

<style></style>
