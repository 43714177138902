

<script setup>
const props = defineProps({
  progress: {
    type: Number,
    required: true,
    validator: value => value >= 0 && value <= 100
  },
  progressBarClass: {
    type: String,
    default: 'bg-primary-900'
  },
  progressHeight: {
    type: String,
    default: 'h-1'
  }
});
</script>

<template>
  <div
    class="w-full bg-grayCust-160 rounded-full h-1 overflow-hidden"
    :class="props.progressHeight"
  >
    <div 
      class="h-full transition-all duration-300 ease-in-out rounded-full"
      :class="props.progressBarClass"
      :style="{ width: `${props.progress}%` }"
      role="progressbar"
      :aria-valuenow="props.progress"
      aria-valuemin="0"
      aria-valuemax="100"
    />
  </div>
</template>


