<template>
  <div class="cursor-pointer">
    <div
      :class="extraClass"
      class="flex items-center justify-center rounded-lg border-[1.5px] px-4 py-2 text-sm font-medium"
    >
      {{ title }}
    </div>
  </div>
</template>

<script>


export default {
    
    name: 'ProductUseCard',
    props: {
        title: {
            type: String,
            required: true
        },
        extraClass: {
            type: String,
            required: true,
            default: "bg-white border-grayCust-180 text-grayCust-620"
        }
    },
}
</script>
