<template>
  <label
    :class="[pillType, pillSize, borderColor, bgColor, textColor, extraClass]"
    class="inline-flex items-center justify-center gap-1.5 rounded-full font-medium"
  >
    <img
      v-if="imagePath && !pillTypeStatus"
      :src="cdn(imagePath)"
      :class="[pillImageSize, pillImagePosition, pillImageShape, 'cursor-pointer object-cover']"
      alt=""
      @click="onHandleClick"
    >
    <component
      :is="iconComponent(iconType, iconName)"
      v-if="iconName && !pillTypeStatus"
      :class="[pillImageSize, pillImagePosition, pillImageShape]"
      class="cursor-pointer"
      @click="onHandleClick"
    />
    <div
      v-if="pillTypeStatus"
      :class="[statusColor ? statusColor : [transformColorClass(textColor), pillStatusColor]]"
      class="size-1.5 rounded-full"
    />
    <span v-if="title">{{ title }}</span>
  </label>
</template>
<script>

import * as OutlineIcons from "@heroicons/vue/outline";
import * as SolidIcons from "@heroicons/vue/solid";
export default {
    
    props: {
        title: {
          type: String,
          required: false
        },
        textColor:{
          type: String,
          required: false
        },
        bgColor: {
          type: String,
          required: false,
        },
        borderColor: {
          type: String,
          required: false,
        },
        extraClass: {
          type: String,
          required: false,
        },
        size: {
          type: String,
          default: 'pill-sm',
          required: false
        },
        type:{
          type: String,
          default: 'default',
          required: false
        },
        shape:{
          type: String,
          default: 'default',
          required: false
        },
        iconName: {
          type: String,
          required: false,
          default: ''
        },
        iconType:{
          type: String,
          required: true,
          default: 'outline'
        },
        imagePath:{
          type: String,
          required: true,
          default: ''
        },
        iconShape: {
          type: String,
          default: 'default',
          required: false
        },
        iconPosition: {
          type: String,
          required: false
        },
        pillColor: {
          type: String,
          required: false
        },
        statusColor: {
          type: String,
          required: false
        },
        pillTypeStatus:{
          type: Boolean,
          required: false
        }
    },
    data() {
      return {
        pillType: null,
        pillSize: null,
        pillImageSize: null,
        pillImagePosition: null,
        pillImageShape: null,
        pillStatusColor: null,
      };
    },
    created() {
      switch (this.shape) {
        case 'default':
          switch (this.size) {
            case 'pill-sm':
              this.pillSize = 'py-0.5 px-2 text-xs';
              this.pillImageSize = 'w-3 h-3';
              break;
            case 'pill-md':
              this.pillSize = 'py-0.5 px-2.5 text-sm';
              this.pillImageSize = 'w-3.5 h-3.5';
              break;
            case 'pill-lg':
              this.pillSize = 'py-1 px-3 text-sm';
              this.pillImageSize = 'w-4 h-4';
              break;
          }
        break;
        case 'rounded':
          switch (this.size) {
            case 'pill-sm':
              this.pillSize = 'p-1 !h-auto';
              this.pillImageSize = 'w-3 h-3';
              break;
            case 'pill-md':
              this.pillSize = 'p-1.5 !h-auto';
              this.pillImageSize = 'w-3 h-3';
              break;
            case 'pill-lg':
              this.pillSize = 'p-2 !h-auto';
              this.pillImageSize = 'w-3.5 h-3.5';
              break;
          }
        break;
      }
      switch (this.type) {
        case 'default':
          switch (this.pillColor) {
            case 'primary':
              this.pillType = 'bg-primary-275 text-secondary-800';
              this.pillStatusColor = 'bg-secondary-800';
              break;
            case 'secondary':
              this.pillType = 'bg-grayCust-280 text-grayCust-660';
              this.pillStatusColor = 'bg-grayCust-660';
              break;
            case 'danger':
              this.pillType = 'bg-redCust-110 text-pinkCust-150';
              this.pillStatusColor = 'bg-pinkCust-150';
              break;
            case 'warning':
              this.pillType = 'bg-warning-350 text-warning-650';
              this.pillStatusColor = 'bg-warning-650';
              break;
            case 'progress':
              this.pillType = 'bg-blueCust-200 text-blueCust-780';
              this.pillStatusColor = 'bg-blueCust-780';
              break;
            case 'success':
              this.pillType = 'bg-primary-350 text-primary-400';
              this.pillStatusColor = 'bg-primary-400';
              break;
          }
        break;
        case 'outline':
          switch (this.pillColor) {
            case 'primary':
              this.pillType = 'border border-secondary-800 text-secondary-800';
              this.pillStatusColor = 'bg-secondary-800';
              break;
            case 'secondary':
              this.pillType = 'border border-grayCust-660 text-grayCust-660';
              this.pillStatusColor = 'bg-grayCust-660';
              break;
            case 'danger':
              this.pillType = 'border border-pinkCust-150 text-pinkCust-150';
              this.pillStatusColor = 'bg-pinkCust-150';
              break;
            case 'warning':
              this.pillType = 'border border-warning-900 text-warning-900';
              this.pillStatusColor = 'bg-warning-900';
              break;
          }
          switch (this.size) {
            case 'pill-sm':
              this.pillSize += ' h-5';
              break;
            case 'pill-md':
              this.pillSize += ' h-6';
              break;
            case 'pill-lg':
              this.pillSize += ' h-7';
              break;
          }
        break;
      }
      switch (this.iconPosition) {
        case 'first':
          this.pillImagePosition = 'order-first'
          break;
        case 'last':
          this.pillImagePosition = 'order-last'
          break;
      }
      switch (this.iconShape) {
        case 'default':
          this.pillImageShape = 'rounded-none'
          break;
        case 'rounded':
          this.pillImageShape = 'rounded-full'
          break;
      }
    },
    methods: {
        onHandleClick(){
            this.$emit('onHandleClick');
        },
        transformColorClass(classString) {
          if (classString && classString.startsWith('text')) {
            const parts = classString.split('-');
            parts[0] = 'bg';
            return parts.join('-');
          }
          return classString;
        },
        iconComponent(type, iconName) {
          switch (type) {
            case "solid":
                if (Object.prototype.hasOwnProperty.call(SolidIcons, iconName)) {
                    return SolidIcons[iconName];
                } else {
                    return null;
                }
            case "outline":
                if (Object.prototype.hasOwnProperty.call(OutlineIcons, iconName)) {
                    return OutlineIcons[iconName];
                } else {
                    return null;
                }
            default:
            return null;
          }
        },
    }
};
</script>