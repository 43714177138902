<template>
  <div class="flex cursor-pointer flex-col items-center justify-center gap-3">
    <div
      v-if="!!imagePath"
      :class="extraClass"
      class="flex items-center  justify-center rounded-full border-[1.5px] p-4 sm2:p-6"
    >
      <img
        :src="cdn(imagePath)"
        class="size-[70px]"
        alt=""
      >
    </div>
    <div class="text-sm font-medium text-grayCust-620">
      {{ title }}
    </div>
  </div>
</template>

<script>


export default {
    
    name: 'BusinessTypeCard',
    props:{
        extraClass: {
            type: String,
            required: true,
            default: "bg-white border-grayCust-180 text-grayCust-620"
        },
        imagePath: {
            type: String,
            required: false,
        },
        title:{
            type: String,
            required: true,
        },
    },
}
</script>
