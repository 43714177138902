<template>
  <input
    :id="id"
    v-model="proxyChecked"
    type="checkbox"
    :disabled="disabled" 
    :value="value"
    :class="[checkboxSize, checkboxShape]"
    class="border border-warning-1020 text-primary-900 shadow-sm focus:outline-none focus:border-primary-900 focus:ring-4 focus:ring-offset-0 focus:ring-primary-275 transition-shadow cursor-pointer disabled:border-grayCust-160 disabled:bg-grayCust-50 disabled:cursor-not-allowed"
  >
</template>

<script>
export default {
    props: {
        checked: {
            type: [Array, Boolean],
            default: false,
        },
        value: {
            default: null,
        },
        id: {
            type: String,
            required: false
        },
        size: {
            type: String,
            default: 'checkbox-sm',
            required: false
        },
        shape: {
            type: String,
            default: 'default',
            required: false
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    emits: ['update:checked'],
    data(){
        return {
            checkboxSize: null,
            checkboxShape: null
        }
    },
computed: {
    proxyChecked: {
        get() {
            return this.checked;
        },

        set(val) {
            this.$emit("update:checked", val);
        },
    },
},
    created(){
        switch (this.size) {
            case 'checkbox-sm':
                this.checkboxSize = 'w-4 h-4'
                break;
            case 'checkbox-lg':
                this.checkboxSize = 'w-5 h-5 rounded-md'
                break;
        }
        switch (this.shape) {
            case 'default':
                this.checkboxShape = 'rounded'
                break;
            case 'rounded':
                this.checkboxShape = '!rounded-full'
                break;
        }
    }
}
</script>
<style scoped>
.check-border,.check-border:focus{
    border-radius: 1.75px;
}
</style>
