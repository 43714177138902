<template>
  <div
    class="rewardbox-bg-gradient-color mx-auto mt-6 flex w-fit items-center gap-2 rounded-lg border border-warning-1170 px-[14px] py-[11px]"
  >
    <div class="h-full">
      <SpeakerphoneIcon class="size-[18px] text-secondary-900" />
    </div>
    <span class="rewardbox-text-gradient-color text-sm font-medium">Complete this survey and get ${{ creditedAmount }} as InstaWP credits.</span>
  </div>
</template>

<script>

import { SpeakerphoneIcon } from '@heroicons/vue/solid';
import { useSurveyStore } from '@/store/survey';
import { mapState, mapStores } from 'pinia';

export default {
    
    name: 'RewardBox',
    components: {
        SpeakerphoneIcon
    },
    computed: {
      ...mapStores(useSurveyStore),
      ...mapState(useSurveyStore, ['survey']),
      creditedAmount() {
        return this.survey.credit_amount
      }
    },
    props: {
        title: {
            type: String,
            required: true
        },
    }
}
</script>

<style scoped>
</style>
